import menuProvider from "./menu/menu.provider"
import triMenuDirective from "./menu/menu.directive"
import triMenuItemDirective from "./menu/menu-item.directive"
import FooterController from "./footer/footer.controller"

export default angular
    .module("triangular.components", [])
    .controller('FooterController', FooterController)
    .provider("triMenu", menuProvider)
    .directive("triMenu", triMenuDirective)
    .directive("triMenuItem", triMenuItemDirective).name
