(function () {
    'use strict'

    angular
        .module('ssml.services')
        .service('RoomService', RoomService)

    /* @ngInject */
    function RoomService($http) {

        this.list = list
        this.show = show
        this.create = create
        this.update = update
        this.remove = remove

        ////////////////

        function list() {
            return $http.get('/api/rooms')
        }

        function show (id) {
            return $http.get('/api/rooms/' + id)
        }

        function create (room) {
            return $http.post('/api/rooms', room)
        }

        function update (room) {
            return $http.put('/api/rooms/' + room._id, room)
        }

        function remove (id) {
            return $http.delete('/api/rooms/' + id)
        }

    }

})()
