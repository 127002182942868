(function() {
    'use strict'

    angular
        .module('secretary.constraints')
        .controller('ConstraintsFabController', ConstraintFabController)

    /* @ngInject */
    function ConstraintFabController($rootScope) {
        var vm = this
        vm.addConstraint = addConstraint

        ////////////////

        function addConstraint($event) {
            $rootScope.$broadcast('addConstraint', $event)
        }
    }
})()
