import board from "./board/board.module"

export default angular.module("secretary", [
  "secretary.address",
  "secretary.teaching",
  "secretary.calendar",
  "secretary.constraints",
  "secretary.user",
  "secretary.session",
  "secretary.study-plan",
  "secretary.exam",
  "secretary.admission",
  "secretary.room",
  "secretary.event",
  board
]).name
