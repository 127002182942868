(function() {
    'use strict'

    angular
        .module('student.teaching')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
        // .state('triangular.student', {
        //     url: '/student',
        //     views: {
        //         '@triangular': {
        //             templateUrl: 'app/ssml/student/student.tmpl.html',
        //             // set the controller to load for this page
        //             controller: 'StudentController',
        //             controllerAs: 'vm',
        //         },
        //         // 'belowContent@triangular': {
        //         //     templateUrl: 'app/teacher/teacher-fabs.tmpl.html',
        //         //     controller: 'TeacherFabController',
        //         //     controllerAs: 'vm'
        //         // }
        //     },
        //     // layout-column class added to make footer move to
        //     // bottom of the page on short pages
        //     data: {
        //         layout: {
        //             contentClass: 'layout-column',
        //             footer: false
        //         },
        //         permissions: {
        //             only: ['STUDENT'],
        //             redirect: 'authentication.login'
        //         }
        //     }
        // })
            .state('triangular.student-teaching', {
                url: '/student/teaching/:codice',
                templateUrl: 'app/ssml/student/teaching/teaching.tmpl.html',
                controller: 'StudentTeachingController',
                controllerAs: 'vm',
                params: {
                    _id: { value: null },
                    codice: { value: null },
                    cfu: { value: null }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['STUDENT'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.student-teaching-program', {
                url: '/student/programs/:codice',
                templateUrl: 'app/ssml/student/teaching/program.tmpl.html',
                controller: 'StudentProgramController',
                controllerAs: 'vm',
                params: {
                    codice: { value: null }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['STUDENT'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.student-teaching-material', {
                url: '/student/material/:codice',
                templateUrl: 'app/ssml/student/teaching/material.tmpl.html',
                controller: 'StudentMaterialController',
                controllerAs: 'vm',
                params: {
                    codice: { value: null }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['STUDENT'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.student-teaching-exam', {
                url: '/student/exam/:codice',
                templateUrl: 'app/ssml/student/teaching/exam.tmpl.html',
                controller: 'StudentExamController',
                controllerAs: 'vm',
                params: {
                    codice: { value: null },
                    _id: null
                },
                resolve: {
                    sessions: ['SessionFactory', function(SessionFactory) {
                        return SessionFactory.query(true)
                            .then(function(res) {
                                return res.data
                            })
                    }]
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['STUDENT'],
                        redirect: 'authentication.login'
                    }
                }
            })


        // triMenuProvider.addMenu({
        //     name: 'Insegnamenti',
        //     icon: 'zmdi zmdi-graduation-cap',
        //     state: 'triangular.teacher',
        //     type: 'link',
        //     permission: 'STUDENT',
        //     priority: 1.1
        // });

        // triMenuProvider.addMenu({
        //     name: 'Calendario',
        //     icon: 'zmdi zmdi-calendar',
        //     state: 'triangular.teacher-calendar',
        //     type: 'link',
        //     permission: 'STUDENT',
        //     priority: 1.1
        // });
    }
})()