import "./import-jquery"
import "./import-moment"
import "angular"
import "angular-animate"
import "angular-cookies"
import "angular-sanitize"
import "angular-messages"
import "angular-material"
import "angular-ui-router"
import "angular-ui-grid"
import "./import-tinymce"
import "angular-ui-tinymce"
import "angular-moment"
import "fullcalendar"
import "fullcalendar/dist/locale/it"
import "angular-ui-calendar"
import "angular-dynamic-locale"
import "ng-file-upload"
import "angular-local-storage"
import "angular-translate"
import "angular-translate-loader-partial"
import "angular-translate-storage-local"
import "angular-translate-storage-cookie"
import "angular-permission"
import "angular-storage"
import "./import-pdfmake"
import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"

// Make sure to call Sentry.init after importing AngularJS.
// You can also pass {angular: AngularInstance} to the Integrations.Angular() constructor.
Sentry.init({
  dsn: "https://f5f41944677046448f002a2d62707efc@sentry.daton.it/8",
  integrations: [new Integrations.Angular()]
})

import "./index.scss"

import "./triangular/index"
import "./layouts/index"
import "./translate/index"
import "./permission/index"
import authentication from "./authentication/index"
import "./ssml/index"

// import './app.module'
import configRoute from "./config.route"
import configTriangularLayout from "./config.triangular.layout"
import configTriangularSettings from "./config.triangular.settings"
import configTriangularThemes from "./config.triangular.themes"

import ErrorPageController from "./error-page.controller"
import appRun from "./app.run"
import httpInterceptor from "./unauthorized.factory"

angular
  .module("app", [
    "ngSentry",
    "ui.router",
    "ui.grid",
    "ui.grid.selection",
    "ui.grid.exporter",
    "ui.grid.edit",
    "ui.grid.saveState",
    "ui.grid.resizeColumns",
    "ui.grid.moveColumns",
    "ui.grid.grouping",
    "ui.grid.pagination",
    "ui.tinymce",
    "tmh.dynamicLocale",
    "triangular",
    "ngAnimate",
    "ngCookies",
    "ngSanitize",
    "ngMessages",
    "ngMaterial",
    // 'googlechart', 'chart.js', 'linkify', 'textAngular', 'uiGmapgoogle-maps', 'hljs', 'md.data.table', angularDragula(angular),
    "angularMoment",
    "ui.calendar",
    "ngFileUpload",
    // 'seed-module',
    // uncomment above to activate the example seed module
    "app.translate",
    // only need one language?  if you want to turn off translations
    // comment out or remove the 'app.translate', line above
    "app.permission",
    // dont need permissions?  if you want to turn off permissions
    // comment out or remove the 'app.permission', line above
    // also remove 'permission' from the first line of dependencies
    // https://github.com/Narzerus/angular-permission see here for why
    // 'app.examples',
    authentication,
    "ssml"
  ])
  .config(configRoute)
  .config(configTriangularLayout)
  .config(configTriangularSettings)
  .config(configTriangularThemes)
  .run(appRun)
  .controller("ErrorPageController", ErrorPageController)
  .factory("httpInterceptor", httpInterceptor)

// import './config.chartjs'

import "./translate.filter"
// import "./unauthorized.factory";
// import './value.googlechart'

// eslint-disable-next-line no-undef
if (module.hot) {
  // eslint-disable-next-line no-undef
  module.hot.dispose(function() {
    // module is about to be replaced
    console.log("module has been replaced... Reloading Browser")
    window.location.reload()
  })

  // eslint-disable-next-line no-undef
  module.hot.accept(function() {
    // module or one of its dependencies was just updated
    // console.log("module has been updated =>", module);
  })
}
