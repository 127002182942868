/* @ngInject */
export default function boardConfig($stateProvider, triMenuProvider) {
  $stateProvider.state("triangular.secretary-board", {
    url: "/secretary/board",
    templateUrl: "app/ssml/secretary/board/board.tmpl.html",
    // set the controller to load for this page
    controller: "SecretaryBoardController",
    controllerAs: "vm",
    // layout-column class added to make footer move to
    // bottom of the page on short pages
    data: {
      layout: {
        contentClass: "layout-column"
      },
      permissions: {
        only: ["SECRETARY"],
        redirect: "authentication.login"
      }
    }
  })

  triMenuProvider.addMenu({
    name: "Bacheca",
    icon: "fas fa-chalkboard",
    // type: "dropdown",
    permission: "SECRETARY",
    type: "link",
    state: "triangular.secretary-board",
    priority: 1.1
    // children: [
    //   {
    //     name: "Bacheca",
    //   }
    // ]
  })
}
