/* @ngInject */
class BoardService {
  endpoint = "/api/posts/"
  constructor($http) {
    this.$http = $http
  }
  getBySecretary(params) {
    return this.$http({
      method: "GET",
      url: this.endpoint + "secretary/",
      params
    })
  }
  getByStudents(params) {
    return this.$http({
      method: "GET",
      url: this.endpoint + "students/",
      params
    })
  }
  getByTeachers(params) {
    return this.$http({
      method: "GET",
      url: this.endpoint + "teachers/",
      params
    })
  }
  getByTeachings(params) {
    return this.$http({
      method: "GET",
      url: this.endpoint + "teachings/",
      params
    })
  }
  create(data) {
    return this.$http({
      method: "POST",
      url: this.endpoint,
      data
    })
  }
  remove(id) {
    return this.$http.delete(`${this.endpoint}/${id}`)
  }
}

export default angular
  .module("ssml.services")
  .service("BoardService", BoardService)
