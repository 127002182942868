/**
 * Created by daton on 17/12/16.
 */

(function () {
    'use strict'

    angular.module('teacher.exam')
        .controller('TeacherVerbaleDialogController', TeacherVerbaleDialogController)

    /* @ngInject */
    function TeacherVerbaleDialogController($log, $state, $q, $mdDialog, storage, ExamService, ExamRegistrationService, dialogData, exam, examRegistration) {

        var vm = this

        /////////////////

        vm.exam = exam
        vm.examRegistration = examRegistration

        vm.dialogData = dialogData

        vm.okClick = okClick
        vm.cancelClick = cancelClick

        function init() {

            return $q.resolve()

        }

        function createSelectOptions() {
            vm.votes = []
            for (var index = 18; index <= 30; index++) {
                vm.votes.push(index)
            }
        }

        function okClick() {

            // storage.setObj(vm.verbale);
            if (!vm.examRegistration.orale && !vm.examRegistration.scritto) return alert('Devi indicare se l\'esame è scritto/orale!')
            vm.examRegistration.verbalizzatoIl = Date.now()
            ExamRegistrationService.update(vm.examRegistration)
                .then(function (res) {
                    $mdDialog.hide(res.data)
                })
                .catch(function (err) {
                    $log.error(err)
                })

        }

        function cancelClick() {
            $mdDialog.cancel()
        }

        ////////////////

        init()
            .then(function () {

                createSelectOptions()

            })
            .catch(function () {

            })
    }
})()
