/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 21/10/16 11:08.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: mail.module
 * Created with: WebStorm
 */

(function () {
    'use strict'

    angular.module('mail', [])

})()
