/* @ngInject */
export default function routeConfig(
    $stateProvider,
    $urlRouterProvider,
    $httpProvider
) {
    $httpProvider.interceptors.push("httpInterceptor")

    // Setup the apps routes

    // 404 & 500 pages
    $stateProvider
        .state("404", {
            url: "/404",
            views: {
                root: {
                    templateUrl: "404.tmpl.html",
                    controller: "ErrorPageController",
                    controllerAs: "vm"
                }
            }
        })

        .state("401", {
            url: "/401",
            views: {
                root: {
                    templateUrl: "401.tmpl.html",
                    controller: "ErrorPageController",
                    controllerAs: "vm"
                }
            }
        })

        .state("500", {
            url: "/500",
            views: {
                root: {
                    templateUrl: "500.tmpl.html",
                    controller: "ErrorPageController",
                    controllerAs: "vm"
                }
            }
        })

    // set default routes when no path specified
    function defaultRoutes($injector) {
        var $cookies = $injector.get("$cookies")
        var $state = $injector.get("$state")

        var user = $cookies.getObject("user")

        if (!user || !user.tipo) {
            console.log("user is not logged")
            return null
            // return $state.go("authentication.login");
        }
        switch (user.tipo) {
            case "Studente":
                $state.go("triangular.student-calendar")
                break

            case "Docente":
                $state.go("triangular.teacher-calendar")
                break

            case "Segreteria":
                $state.go("triangular.secretary-calendar")
                break

            case "Amministratore":
                $state.go("triangular.teacher-calendar")
                break

            default:
                $state.go("authentication.login")
                break
        }
    }

    $urlRouterProvider.when("", defaultRoutes)
    $urlRouterProvider.when("/", defaultRoutes)

    // always goto 404 if route not found
    $urlRouterProvider.otherwise(function($injector) {
        var $state = $injector.get("$state")
        $state.go("404")
    })
}
