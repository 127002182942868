import moment from "moment"
;(function() {
  "use strict"

  angular
    .module("teacher.teaching")
    .controller("TeacherTeachingController", EditTeachingController)

  /* @ngInject */
  function EditTeachingController(
    $state,
    $stateParams,
    $log,
    $teaching,
    $lesson,
    triBreadcrumbsService
  ) {
    var vm = this
    vm.back = back

    vm.teaching = {}

    vm.gridOptions = {
      enableRowSelection: false,
      enableGridMenu: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      enableFiltering: false,
      showGridFooter: true,
      enableColumnResize: true,
      // multiSelect: false,
      // minRowsToShow: 17,
      data: $lesson.collection,
      exporterFieldCallback: function(grid, row, col, value) {
        if (col.name === "start") {
          value = moment(value).format("L")
        }
        return value
      },
      exporterCsvFilename: "lezioni " + vm.teaching.nome + ".csv"
    }

    vm.gridOptions.columnDefs = [
      {
        name: "idInsegnamento.nome",
        displayName: "Insegnamento",
        width: "40%"
      },
      {
        name: "idInsegnamento.nomeDocente",
        displayName: "Docente",
        width: "*"
      },
      {
        name: "start",
        displayName: "Giorno",
        width: 120,
        type: "date",
        cellFilter: "date:'dd-MM-yyyy'",
        sort: { direction: "asc", priority: 0 }
      },
      // { name: 'inizio', width: 90 },
      {
        field: "start",
        displayName: "Inizio",
        width: 90,
        type: "date",
        cellFilter: "date:'HH:mm'"
      },
      // { name: 'fine', width: 90 },
      {
        field: "end",
        displayName: "Fine",
        width: 90,
        type: "date",
        cellFilter: "date:'HH:mm'"
      },
      // { name: 'ore', width: 60 } // TODO: show
      { name: "ore", width: 60 }
    ]

    triBreadcrumbsService.reset()

    $teaching.getById($stateParams._id).then(
      function(res) {
        vm.teaching = res.data

        triBreadcrumbsService.addCrumb({
          name: vm.teaching.nome
        })

        $lesson
          .query(
            {
              teacherId: vm.teaching.idDocente,
              teachingId: vm.teaching._id
            },
            true
          )
          .then(function(res) {
            $log.log("res => ", res)
          })
      },
      function(xhr) {
        $log.error("xhr => ", xhr)
        if (xhr.status === 401) return $state.go("authentication.login")
        $state.go("triangular.teacher-teachings")
      }
    )

    vm.program = program
    vm.material = material
    vm.exam = exam

    function exam() {
      $state.go("triangular.teacher-exam-creation", $stateParams)
    }

    function material() {
      $state.go("triangular.teacher-material", $stateParams)
    }

    function program() {
      $state.go("triangular.teacher-program", $stateParams)
    }

    function back() {
      $state.go("triangular.teacher-teachings")
    }
  }
})()
