/* @ngInject */
export default function LoginController(
    $state,
    triSettings,
    $user,
    $log,
    $scope
) {
    console.log('login controller')
    var vm = this
    vm.error = ""
    vm.loginClick = loginClick
    vm.passwordClick = passwordClick
    vm.confirmPassword = false
    vm.passwordNotEqual = false
    vm.triSettings = triSettings
    // create blank user variable for login form
    vm.user = {
        username: "",
        password: ""
    }

    ////////////////

    function redirect(user) {
        if (!user) return

        switch (user.tipo) {
            case "Segreteria":
                $state.go("triangular.secretary-calendar")
                break

            case "Studente":
                $state.go("triangular.student-calendar")
                break

            case "Docente":
                $state.go("triangular.teacher-calendar")
                break

            case "Amministratore":
                $state.go("triangular.secretary-calendar")
                break
        }
    }

    function loginClick(user) {
        $user.login(user).then(function(response) {
            // console.log('login =>',response);

            var status = response.status.toString()[0]

            if (status == 4 || status == 5) {
                vm.error =
                    response.data || "Problema sconosciuto. Riprova più tardi."
                return
            }

            var user = response.data
            if (user.hasOwnProperty("tipo")) {
                if (user.isConfirmed) {
                    redirect(user)
                } else {
                    vm.confirmPassword = true
                    vm.user.password = ""
                }
            }
        })
    }

    function passwordClick() {
        $user.confirmPassword(vm.user).then(
            function(user) {
                redirect(user.data)
            },
            function(res) {
                vm.error = "Operazione fallita. Riprova più tardi"
            }
        )
    }

    // TODO: destroy this watcher on destroy this controller
    $scope.$watch(
        "vm.user",
        function(val) {
            if (val) {
                if (val.password !== val.confirmPassword) {
                    $scope.changePassword.$invalid = vm.passwordNotEqual = true
                }
            }
        },
        true
    )
}
