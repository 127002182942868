import _ from "lodash"
/**
 * Created by daton on 10/10/16.
 */
;(function() {
  "use strict"

  angular
    .module("teacher")
    .controller("TeacherProgramController", TeacherProgramController)

  function TeacherProgramController(
    $log,
    $timeout,
    $mdToast,
    $stateParams,
    $teaching,
    $scope,
    $state,
    Upload,
    util
  ) {
    var vm = this
    vm.download = download
    vm.save = save
    vm.back = back
    vm.sendToSecretary = sendToSecretary

    vm.edit = false
    vm.teaching = {}
    // vm.error = {};

    // toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
    // plugins: 'link image code',
    vm.tinymceOptions = {
      height: 300,
      plugins: [
        "advlist autolink lists link image charmap print preview",
        "wordcount code fullscreen table paste textcolor colorpicker "
      ],
      toolbar1:
        "undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      toolbar2: "print preview media | forecolor backcolor | codesample",
      content_css: [
        "//fonts.googleapis.com/css?family=Roboto:300,300i,400,400i"
      ]
    }

    $teaching.getById($stateParams._id).then(
      function(res) {
        vm.teaching = res.data
        if (vm.teaching.programma) {
          vm.edit =
            vm.teaching.programma.stato == "Da inserire" ||
            vm.teaching.programma.stato == "Non approvato"
        }
        // $log.log('res=>', res);
      },
      function(xhr) {
        // $log.error('xhr => ', xhr);
        // vm.error = {
        //     message: util.findMessage(xhr)
        // };
        // util.errorMessage = '';
        if (xhr.status === 401) return $state.go("authentication.login")
        $state.go("triangular.teacher-teachings")
      }
    )

    function save() {
      // if (vm.method == 'manual') {
      var deferredProgram = $teaching.updateProgram(vm.teaching)
      // } else {
      // deferredProgram = $teaching.uploadProgram(vm.teaching);
      // }
      return deferredProgram.then(
        function(res) {
          vm.teaching = res.data
          vm.edit =
            vm.teaching.programma.stato == "Da inserire" ||
            vm.teaching.programma.stato == "Non approvato"
          util.toast("Programma salvato...")
          return res
        },
        function(xhr) {
          $log.error(xhr)
          vm.error = {
            message: util.findMessage(xhr)
          }
          util.toast(vm.error.message)
          util.errorMessage = ""
          if (xhr.status === 401) $state.go("authentication.login")
          return xhr
        }
      )
    }

    vm.status = "idle" // idle | uploading | complete
    vm.upload = upload

    var fileList
    ////////////////

    function upload($files) {
      if ($files !== null && $files.length > 0) {
        fileList = $files

        delete vm.error
        Upload.upload({
          url: "/api/teachings/program-upload/" + $stateParams.codice,
          data: {
            file: $files[0]
          }
        }).then(
          function(res) {
            $log.log("succ res =>", res)
            vm.teaching = res.data
            vm.edit =
              vm.teaching.programma.stato == "Da inserire" ||
              vm.teaching.programma.stato == "Non approvato"
            uploadComplete(200)
          },
          function(res) {
            $log.error("err res =>", res)
            vm.error = {
              message: util.findMessage(res.data)
            }
            util.errorMessage = ""
            uploadComplete(500)
          }
        )
        uploadStarted()
      }
    }

    function uploadStarted() {
      vm.status = "uploading"
    }

    function uploadComplete(status) {
      vm.status = "complete"
      if (status == 500) {
        return $mdToast.show({
          template:
            "<md-toast><span flex>A causa di un problema non è possibile soddisfare la tua richiesta. Riprova più tardi.</span></md-toast>",
          position: "bottom right",
          hideDelay: 10000
        })
      }
      var message = "Grazie per aver caricato il file "
      for (var file in fileList) {
        message += fileList[file].name + " "
      }
      $mdToast.show({
        template: "<md-toast><span flex>" + message + "</span></md-toast>",
        position: "bottom right",
        hideDelay: 5000
      })

      $timeout(uploadReset, 3000)
    }

    function uploadReset() {
      vm.status = "idle"
    }

    function sendToSecretary() {
      if (
        !confirm(
          "Il programma è completo? Inviando il programma alla segreteria passerà in attesa di approvazione e non potrai più modificarlo..."
        )
      )
        return util.toast("Invio annullato")

      vm.teaching.programma.stato = "In attesa di approvazione"

      save(vm.teaching)
    }

    function download() {
      window.location.assign(
        "/api/teachings/program/" + vm.teaching.programma.file._id
      )
    }

    function back() {
      $state.go("triangular.teacher-teaching", vm.teaching)
    }

    var deb = _.debounce(function() {
      save()
    }, 1500)

    // if (vm.edit) {

    $scope.$watch(
      "vm.teaching.programma",
      function prova(programma, old) {
        for (var key in programma) {
          if (
            old &&
            old.hasOwnProperty(key) &&
            programma &&
            programma.hasOwnProperty(key) &&
            key !== "ultimoAggiornamento" &&
            key !== "creatoIl" &&
            old[key] !== programma[key]
          ) {
            deb()
            console.log(
              "old[key] !== programma[key] =>",
              old[key] !== programma[key],
              key
            )
          }
        }
      },
      true
    )

    // }

    // $scope.$watch('vm.method', function (val, oldVal) {
    //     // if ('string' == typeof val) {
    //     //     if (angular.isDefined(vm.teaching.programma.tipo)) {
    //     //         vm.teaching.prorgamma.tipo = val
    //     //     } else {
    //     //         vm.teaching.programma = {
    //     //             tipo: val
    //     //         }
    //     //     }
    //     // }
    // });
  }
})()
