import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.study-plan")
    .controller("StudyPlanDialogController", StudyPlanDialogController)

  /* @ngInject */
  function StudyPlanDialogController(
    $mdDialog,
    $log,
    $mdToast,
    dialogData,
    $teaching,
    $language,
    studyPlan,
    $studyPlan,
    $user,
    util,
    edit
  ) {
    var vm = this
    vm.cancelClick = cancelClick
    vm.deleteClick = deleteClick
    vm.addTeaching = addTeaching
    vm.okClick = okClick

    vm.dialogData = dialogData
    vm.edit = edit
    vm.error = ""
    vm.studyPlan = studyPlan || {}
    vm.teachings = []
    vm.students = []
    vm.readyToBeAdd = []

    var rowTmpl =
      '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'

    vm.gridOptionsTeachings = {
      enableRowSelection: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: true,
      rowTemplate: rowTmpl,
      data: []
    }

    vm.gridOptionsStudyPlan = {
      enableFiltering: true,
      rowHeight: 35,
      showGridFooter: true,
      multiSelect: false,
      rowTemplate: rowTmpl,
      data: []
    }

    vm.gridOptionsTeachings.columnDefs = [
      {
        name: "nome",
        width: "*"
      },
      {
        name: "codice",
        width: 160
      },
      {
        name: "cfu",
        width: 50
      },
      {
        name: "annoCorso",
        width: 50
      },
      {
        name: "aa",
        width: 100
      },
      {
        name: "stato",
        width: 100
      }
    ]

    vm.gridOptionsStudyPlan.columnDefs = [
      {
        name: "nome",
        displayName: "Insegnamento"
      },
      {
        name: "cfu"
      }
    ]

    vm.gridOptionsTeachings.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi

      gridApi.selection.on.rowSelectionChanged(null, function(row) {
        // if (vm.readyToBeAdd._id === row.entity._id) return angular.copy({}, vm.readyToBeAdd);

        // angular.copy(row.entity, vm.readyToBeAdd);

        if (_.find(vm.readyToBeAdd, { _id: row.entity._id })) {
          _.remove(vm.readyToBeAdd, { _id: row.entity._id })
        } else {
          vm.readyToBeAdd.push({
            _id: row.entity._id,
            nome: row.entity.nome,
            cfu: row.entity.cfu
          })
        }

        // $log.log(vm.readyToBeAdd);
      })
    }

    vm.gridOptionsStudyPlan.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      // vm.gridApi = gridApi;

      gridApi.selection.on.rowSelectionChanged(null, function(row) {
        if (
          !confirm("Ilario sei sicuro di voler eliminare questo insegnamento?")
        )
          return

        _.remove(vm.gridOptionsStudyPlan.data, row.entity)
      })
    }

    queryTeachings()

    queryStudyPlan()

    if (!edit) {
      queryStudents()
    }

    ////////////////

    function queryStudents() {
      $user
        .query(true, {
          type: "student"
        })
        .then(function(res) {
          angular.copy(res.data, vm.students)
        })
    }

    function queryStudyPlan() {
      $studyPlan.show(vm.studyPlan._id).then(function(res) {
        angular.copy(res.data, vm.studyPlan)
        angular.copy(vm.studyPlan.teachings, vm.gridOptionsStudyPlan.data)
        // angular.copy(vm.studyPlan.firstYear, vm.gridOptionsFirstYear.data);
        // angular.copy(vm.studyPlan.secondYear, vm.gridOptionsSecondYear.data);
        // angular.copy(vm.studyPlan.thirdYear, vm.gridOptionsThirdYear.data);
        // $log.log('vm.studyPlan =>', vm.studyPlan);
      })
    }

    function addTeaching() {
      // switch (vm.readyToBeAdd.annoCorso) {
      //     case 1:
      //         vm.studyPlan.firstYear.push(vm.readyToBeAdd);
      //         angular.copy(vm.studyPlan.firstYear, vm.gridOptionsFirstYear.data);
      //         break;
      //     case 2:
      //         vm.studyPlan.secondYear.push(vm.readyToBeAdd);
      //         angular.copy(vm.studyPlan.secondYear, vm.gridOptionsSecondYear.data);
      //         break;
      //     case 3:
      //         vm.studyPlan.thirdYear.push(vm.readyToBeAdd);
      //         angular.copy(vm.studyPlan.thirdYear, vm.gridOptionsThirdYear.data);
      //         break;
      // }

      vm.readyToBeAdd.forEach(function(teaching) {
        if (_.find(vm.gridOptionsStudyPlan.data, { _id: teaching._id })) return
        vm.gridOptionsStudyPlan.data.push(teaching)
      })

      // angular.copy(vm.studyPlan.teachings, vm.gridOptionsStudyPlan.data);

      vm.readyToBeAdd = []

      vm.gridApi.selection.clearSelectedRows()
    }

    function queryTeachings() {
      $teaching.query(true, {}).then(function(res) {
        if (res.status === 200) {
          // $log.log('teachings =>', res);
          vm.teachings = res.data

          // vm.teachings.forEach(function (teaching) {
          //     if (teaching.annoCorso === 1) vm.studyPlan.firstYear.push(teaching);
          //     if (teaching.annoCorso === 2) vm.studyPlan.secondYear.push(teaching);
          //     if (teaching.annoCorso === 3) vm.studyPlan.thirdYear.push(teaching);
          // });

          angular.copy(vm.teachings, vm.gridOptionsTeachings.data)
        } else {
          $log.error("teachings =>", res)
        }
      })
    }

    function okClick() {
      // $log.log('studyPlan =>', vm.studyPlan.teachings);
      // $log.log('gridData  =>', vm.gridOptionsStudyPlan.data);

      angular.copy(vm.gridOptionsStudyPlan.data, vm.studyPlan.teachings)

      // update studyPlan
      var deferred = edit
        ? $studyPlan.update(vm.studyPlan)
        : $studyPlan.create(vm.studyPlan)

      deferred.then(
        function(studyPlan) {
          angular.copy(studyPlan.data, vm.studyPlan)

          vm.studyPlan.saved = true

          $mdDialog.hide(vm.studyPlan)
        },
        function(xhr) {
          vm.error = util.findMessage(xhr)
          util.errorMessage = ""
          $log.error("update || create plan =>", xhr)
          util.toast("Impossibile salvare Piano di studi!")
        }
      )
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function deleteClick() {
      if (
        confirm("Vuoi eliminare questo Piano di studi? Azione irreversibile!")
      )
        // delete studyPlan
        $studyPlan.destroy(vm.studyPlan).then(
          function() {
            vm.studyPlan.deleted = true

            $mdDialog.hide(vm.studyPlan)
          },
          function(xhr) {
            vm.error = util.findMessage(xhr)
            util.errorMessage = ""
            $log.error("err =>", xhr)
            util.toast("Impossibile Eliminare Piano di studi")
          }
        )
    }
  }
})()
