/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 12/09/16 16:05.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: teacher.factory
 * Created with: WebStorm
 */

(function () {

    'use strict'

    angular.module('ssml.services')
        .service('$teacher', TeacherService)


    function TeacherService($http) {

        this.model = {}
        this.collection = []

        this.query = function query(exec) {

            var dfdTeachers = $http.get('/api/users?type=teacher')

            if (!exec) return dfdTeachers

            return dfdTeachers
                .then(function success(res) {

                    if (res && res.data) angular.copy(res.data, this.collection)

                    return res

                }.bind(this),
                function error(err) {
                    return err
                })

        }

        this.create = function create(model) {
            return $http.post('/api/users/teachers', model)
        }

        this.getById = function getById(id) {
            return $http.get('/api/users/' + id)
        }

        this.save = function save(model) {
            return $http.put('/api/users/teachers/' + model._id, model)
        }

        this.remove = function remove(model) {
            return $http.delete('/api/users/teachers/' + model._id)
        }

    }

})()
