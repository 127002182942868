import logo from "./logoBase64"

;(function() {
  "use strict"

  angular
    .module("teacher.exam")
    .controller("TeacherExamController", TeacherExamController)

  /* @ngInject */
  function TeacherExamController(
    $log,
    $state,
    $q,
    $mdDialog,
    util,
    $user,
    ExamService,
    ExamRegistrationService,
    uiGridExporterService,
    uiGridExporterConstants
  ) {
    var vm = this

    vm.exam = {}
    vm.examRegistrations = []
    vm.otherExams = []
    vm.examFinished = false
    vm.rimanenti = []

    vm.gridOptions = {
      columnDefs: [
        {
          field: "studente.cognome",
          displayName: "Cognome"
        },
        {
          field: "studente.nome",
          displayName: "Nome"
        },
        {
          field: "studente.matricola",
          displayName: "Matricola"
        },
        {
          field: "esito"
        },
        {
          field: "voto"
        }
      ],
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      showGridFooter: true,
      minRowsToShow: 17,
      onRegisterApi: onRegisterApi,
      // exporterPdfDefaultStyle: { fontSize: 9 },
      // exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
      // exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'black' },
      // exporterPdfHeader: {
      //     text: 'Esame',
      //     style: 'headerStyle'
      // },
      // exporterPdfFooter: function exporterPdfFooter(currentPage, pageCount) {
      //     return {
      //         text: currentPage.toString() + ' of ' + pageCount.toString()
      //         // style: 'footerStyle'
      //     };
      // },
      // exporterPdfOrientation: 'portrait',
      // exporterPdfPageSize: 'LETTER',
      exporterPdfMaxGridWidth: 470,
      exporterFieldCallback: function exporterFieldCallback(
        grid,
        row,
        col,
        value
      ) {
        if (col.field === "voto") {
          if (row.entity.lode) value = value + " e lode"
        }
        return value
      },
      data: []
    }

    vm.createVerbale = createVerbale
    vm.closeExam = closeExam
    vm.downloadList = downloadList

    ////////////////////

    function onRegisterApi(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
    }

    function init() {
      if (!$state.params._id) return $q.reject()

      // GET current Exam
      return ExamService.show($state.params._id)
        .then(function(res) {
          angular.copy(res.data, vm.exam)

          vm.gridOptions.exporterPdfCustomFormatter = function exporterPdfCustomFormatter(
            docDefinition
          ) {
            console.log("docDefinition =>", docDefinition)
            docDefinition.pageSize = "A4"

            docDefinition.pageOrientation = "portrait"

            docDefinition.styles = {
              header: {
                bold: true,
                fontSize: 32,
                margin: [0, 10, 0, 40]
              },
              tableHeader: {
                bold: true,
                fontSize: 12
              },
              big: {
                fontSize: 12
              },
              bigger: {
                fontSize: 12,
                bold: true
              },
              marginBottom: {
                margin: [0, 0, 0, 20]
              },
              marginTop: {
                margin: [0, 20, 0, 0]
              }
            }

            docDefinition.defaultStyle = {
              fontSize: 9
            }

            // docDefinition.footer = function(currentPage, pageCount) {
            //   return {
            //     text: currentPage.toString() + " of " + pageCount.toString(),
            //     style: { margin: [40, 40, 40, 40] }
            //   }
            // }

            docDefinition.content.unshift(
              {
                columns: [
                  {
                    image: logo
                  },
                  {
                    text: "Verbale di esame",
                    style: "header"
                  }
                ]
              },
              {
                table: {
                  widths: [150, "*"],
                  body: [
                    [
                      { text: "Insegnamento", style: "bigger" },
                      { text: vm.exam.insegnamento.nome, style: "big" }
                    ],
                    [
                      { text: "Codice", style: "bigger" },
                      { text: vm.exam.insegnamento.codice, style: "big" }
                    ],
                    [
                      { text: "SSD", style: "bigger" },
                      { text: vm.exam.insegnamento.ssd, style: "big" }
                    ],
                    [
                      { text: "Docente", style: "bigger" },
                      { text: vm.exam.insegnamento.nomeDocente, style: "big" }
                    ],
                    [
                      { text: "Anno Accademico", style: "bigger" },
                      { text: vm.exam.insegnamento.aa, style: "big" }
                    ],
                    [
                      { text: "Sessione", style: "bigger" },
                      { text: vm.exam.sessione.nome, style: "big" }
                    ]
                  ]
                },
                style: "marginBottom"
              }
            )

            // TODO: insert commissione
            var commissione = [[{ text: "Commissione", style: "bigger" }]]

            vm.exam.commissione.forEach(function(docente) {
              commissione.push([docente.nomeCompleto])
            })

            docDefinition.content.push(
              {
                table: {
                  body: commissione
                },
                style: "marginTop"
              },
              {
                columns: [
                  {
                    text: "Data \n" + vm.exam.data
                  },
                  {
                    text: "Firma Docente \n\n ____________________",
                    style: { alignment: "right" }
                  }
                ],
                style: "marginTop"
              }
            )

            return docDefinition
          }

          // GET Registrations
          return ExamRegistrationService.list({
            esame: vm.exam._id
          })
        })
        .then(function(examRegistrations) {
          angular.copy(examRegistrations.data, vm.examRegistrations)
          // $log.log('vm.examRegistrations =>', vm.examRegistrations);

          vm.rimanenti = vm.examRegistrations.filter(function(er) {
            return angular.isUndefined(er.esito)
          })

          if (vm.rimanenti.length === 0) {
            vm.examFinished = true
            vm.gridOptions.data = vm.examRegistrations
          }

          // GET other Exams of same Teaching
          return ExamService.index({
            insegnamento: vm.exam.insegnamento._id
          })
        })
        .then(function(otherExams) {
          angular.copy(otherExams.data, vm.otherExams)
          $log.log("vm.otherExams =>", vm.otherExams)
        })
        .catch(function(err) {
          throw err
        })
    }

    function createVerbale(event, examRegistration) {
      $mdDialog
        .show({
          controller: "TeacherVerbaleDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/teacher/exam/exam-dialog.tmpl.html",
          targetEvent: event,
          clickOutsideToClose: false,
          locals: {
            dialogData: {
              title: "Verbale",
              confirmButtonText: "Salva"
            },
            examRegistration: examRegistration,
            exam: vm.exam
          }
        })
        .then(function(data) {
          vm.rimanenti = vm.examRegistrations.filter(function(er) {
            return angular.isUndefined(er.esito)
          })

          if (vm.rimanenti.length === 0) {
            vm.examFinished = true
            vm.gridOptions.data = vm.examRegistrations
          }
        })
        .catch(function(data) {})
    }

    function downloadList() {
      var grid = vm.gridApi.grid
      var rowTypes = uiGridExporterConstants.ALL
      var colTypes = uiGridExporterConstants.ALL

      if (vm.exam.stato === "In corso") {
        ExamService.end(vm.exam)
          .then(function(res) {
            uiGridExporterService.pdfExport(grid, rowTypes, colTypes)
            angular.copy(res.data, vm.exam)
            // if ($user.current.tipo === 'Segreteria') return $state.go('triangular.secretary-exams-list');
            // $state.go('triangular.teacher-calendar');
            util.toast("La sessione di esame è stata completata!")
          })
          .catch(function(err) {
            util.toast(
              "Si è verificato un problema... Contatta l'amministratore!"
            )
            $log.error(err)
          })
      } else {
        uiGridExporterService.pdfExport(grid, rowTypes, colTypes)
      }

      // closeExam();
    }

    function closeExam() {
      // if (vm.examFinished) {
      // } else {
      //     util.toast('Devi inserire l\'esito di tutti gli iscritti per concludere l\'esame!');
      // }
    }

    ////////////////

    init()
      .then(function() {})
      .catch(function() {})
  }
})()
