(function() {
    'use strict'

    angular
        .module('teacher.calendar')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.teacher-calendar', {
                url: '/teacher/calendar/:codice',
                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/teacher/calendar/calendar.tmpl.html',
                        controller: 'TeacherCalendarController',
                        controllerAs: 'vm'
                    },
                    'toolbar@triangular': {
                        templateUrl: 'app/ssml/teacher/calendar/layouts/toolbar/toolbar.tmpl.html',
                        controller: 'TeacherCalendarToolbarController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    codice: { value: null },
                    _id: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            name: 'Calendario',
            icon: 'zmdi zmdi-calendar',
            state: 'triangular.teacher-calendar',
            type: 'link',
            permission: 'manageTeaching',
            priority: 1.1
        })

    }
})()