/* @ngInject */
export default function boardConfig($stateProvider, triMenuProvider) {
  $stateProvider.state("triangular.teacher-board", {
    url: "/teacher/board",
    templateUrl: "app/ssml/teacher/board/board.tmpl.html",
    // set the controller to load for this page
    controller: "TeacherBoardController",
    controllerAs: "vm",
    // layout-column class added to make footer move to
    // bottom of the page on short pages
    data: {
      layout: {
        contentClass: "layout-column"
      },
      permissions: {
        only: ["TEACHER"],
        redirect: "authentication.login"
      }
    }
  })

  $stateProvider.state("triangular.teacher-teachings-board", {
    url: "/teacher/teaching-board",
    templateUrl: "app/ssml/teacher/board/teachings-board.tmpl.html",
    // set the controller to load for this page
    controller: "TeacherTeachingsBoardController",
    controllerAs: "vm",
    // layout-column class added to make footer move to
    // bottom of the page on short pages
    data: {
      layout: {
        contentClass: "layout-column"
      },
      permissions: {
        only: ["TEACHER"],
        redirect: "authentication.login"
      }
    }
  })

  triMenuProvider.addMenu({
    name: "Bacheca Avvisi",
    icon: "fas fa-chalkboard",
    // type: "dropdown",
    permission: "TEACHER",
    type: "link",
    state: "triangular.teacher-board",
    priority: 1.1
    // children: [
    //   {
    //     name: "Bacheca",
    //   }
    // ]
  })

  triMenuProvider.addMenu({
    name: "Bacheca Insegnamenti",
    icon: "fas fa-chalkboard",
    // type: "dropdown",
    permission: "TEACHER",
    type: "link",
    state: "triangular.teacher-teachings-board",
    priority: 1.2
    // children: [
    //   {
    //     name: "Bacheca",
    //   }
    // ]
  })
}
