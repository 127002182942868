import _ from 'lodash'
import moment from 'moment'
(function () {

    'use strict'

    angular
        .module('secretary.teaching')
        .controller('SecretaryTeachingController', SecretaryTeachingController)

    /* @ngInject */
    function SecretaryTeachingController($lesson, $log, $scope, $state, $teaching, ExamService, $window, $teacher, $address, $language, util, triBreadcrumbsService) {
        var vm = this

        vm.teachers = $teacher.collection
        vm.addresses = $address.collection

        vm.teachings = $teaching.collection
        vm.languages = $language.collection
        vm.teaching = $teaching.model

        vm.tinymceOptions = {
            plugins: 'link image code',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
        }

        vm.lessonsGridOptions = {
            enableRowSelection: false,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: false,
            showGridFooter: true,
            enableColumnResize: true,
            // multiSelect: false,
            // minRowsToShow: 17,
            data: $lesson.collection,
            exporterFieldCallback: function (grid, row, col, value) {
                if (col.name === 'start') {
                    value = moment(value).format('L')
                }
                return value
            },
            exporterCsvFilename: 'lezioni ' + vm.teaching.nome + '.csv'
        }

        vm.lessonsGridOptions.columnDefs = [
            { name: 'idInsegnamento.nome', displayName: 'Insegnamento', width: '40%' },
            { name: 'idInsegnamento.nomeDocente', displayName: 'Docente', width: '*' },
            { name: 'start', displayName: 'Giorno', width: 120, type: 'date', cellFilter: 'date:\'dd-MM-yyyy\'', sort: { direction: 'asc', priority: 0 } },
            // { name: 'inizio', width: 90 },
            { field: 'start', displayName: 'Inizio', width: 90, type: 'date', cellFilter: 'date:\'HH:mm\'' },
            // { name: 'fine', width: 90 },
            { field: 'end', displayName: 'Fine', width: 90, type: 'date', cellFilter: 'date:\'HH:mm\'' }
            // { name: 'ore', width: 60 } // TODO: show
        ]

        vm.gridOptionsExams = {
            enableRowSelection: false,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: true,
            showGridFooter: true,
            enableColumnResize: true,
            // multiSelect: false,
            // minRowsToShow: 17,
            data: [],
            exporterFieldCallback: function (grid, row, col, value) {
                if (col.name === 'start') {
                    value = moment(value).format('L')
                }
                return value
            },
            exporterCsvFilename: ('esami ' + vm.teaching.nome + '.csv').replace(' ', '_')
        }

        vm.gridOptionsExams.columnDefs = [
            { name: 'insegnamento.nome', displayName: 'Insegnamento' },
            { name: 'insegnamento.nomeDocente', displayName: 'Docente' },
            { name: 'stato' },
            { name: 'tipo' },
            { name: 'numero', displayName: 'Apello' },
            { name: 'start', displayName: 'Giorno', width: 120, type: 'date', cellFilter: 'date:\'dd-MM-yyyy\'', sort: { direction: 'asc', priority: 0 } },
            { name: 'inizio', width: 90 }
        ]

        triBreadcrumbsService.reset()

        vm.downloadProgram = downloadProgram
        vm.downloadMaterial = downloadMaterial
        vm.getHoursAssignedToTeaching = getHoursAssignedToTeaching

        init()

        ////////////////

        function init() {

            // $log.log('params =>', $state.params);

            if ($state.params._id) {

                $teaching.getById($state.params._id)
                .then(function (res) {
                    if (res.status === 200) {
                        vm.teaching = res.data
                        triBreadcrumbsService.addCrumb({ name: vm.teaching.nome })
                        triBreadcrumbsService.addCrumb({ name: 'Insegnamento' })

                        $lesson.query({ teachingId: vm.teaching._id }, true)

                        return ExamService.showByTeaching(vm.teaching._id)
                    }
                    throw new Error('Teaching not found')
                })
                .then(function (exams) {
                    if (exams.status === 200) {
                        vm.gridOptionsExams.data = exams.data
                        return null
                    }
                    throw new Error('Exams not found')
                })
                .catch($log.error)
            }

            $teaching.query(true, {})
            $language.query(true)
            $address.query(true)
            $teacher.query(true)

        }

        ////////////////////////

        function getHoursAssignedToTeaching() {

            $teaching.getHoursAssignedToTeaching({
                teachingId: vm.teaching._id
            })
                .then(function (res) {

                    $log.log('res.id =>', res.data[0])
                    $log.log('teaching.id =>', vm.teaching._id)

                    if (res.data[0].oreTotaliAssegnate) {

                        var oreTotaliAssegnate = res.data[0].oreTotaliAssegnate

                        if (confirm('Nel calendario sono state assegnate ' + oreTotaliAssegnate + '\n Vuoi aggiornare le ore assegnate e da ultimare?')) {

                            $teaching.updateHours({
                                _id: vm.teaching._id,
                                oreDaUltimare: vm.teaching.oreLezione - oreTotaliAssegnate,
                                oreAssegnate: oreTotaliAssegnate
                            })
                                .then(function (res) {
                                    alert('Ore aggiornate correttamente!')
                                    vm.teaching = res.data
                                    vm.teaching.saved = true
                                }, function () {
                                    alert('Problema di comunicazione... Contattare l\'amministratore')
                                })

                        }

                    } else {
                        alert('Problema di comunicazione... Contattare l\'amministratore')
                    }

                }, function (res) {

                    if (res.status === 404) {

                        if (confirm('Nessuna lezione trovata per questo insegnamento!\n Vuoi resettare le ore assegnate e da ultimare?')) {
                            $teaching.updateHours({
                                _id: vm.teaching._id,
                                oreDaUltimare: vm.teaching.oreLezione,
                                oreAssegnate: 0
                            })
                                .then(function (res) {
                                    alert('Ore aggiornate correttamente!')
                                    vm.teaching = res.data
                                    vm.teaching.saved = true
                                }, function () {
                                    alert('Problema di comunicazione... Contattare l\'amministratore')
                                })
                        }
                    }

                })

        }

        function save() {
            vm.error = ''

            if (vm.teaching.diIndirizzo) vm.teaching.linguaPOV = ''

            if (
                _.isEqual(vm.teaching.lingua, 'Seleziona lingua') ||
                _.isEmpty(vm.teaching.lingua)
            ) vm.teaching.lingua = {}

            if (vm.teaching.idDocente) {
                vm.teaching.nomeDocente = vm.teachers
                    .filter(function (t) {
                        return t._id === vm.teaching.idDocente
                    })
                    .map(function (t) {
                        return t.nomeCompleto
                    })
            } else {
                vm.teaching.nomeDocente = ''
            }
            // $log.log('vm.teaching => ', vm.teaching);
            // save teaching
            var deferred = vm.teaching._id ? $teaching.save(vm.teaching) : $teaching.create(vm.teaching)

            deferred
                .then(function (teaching) {
                    teaching.saved = true
                    util.toast('Insegnamento salvato!')
                })
                .catch(function (err) {
                    $log.error(err)
                    vm.error = util.findMessage(err)
                    util.errorMessage = ''
                    util.toast('Impossibile salvare insegnamento! ' + vm.error)
                })
        }

        function downloadProgram() {
            if (vm.teaching.programma.tipo === 'Manuale') {

                // pdfMake.createPdf({ content: 'This is an sample PDF printed with pdfMake' }).open();

                $window.open('/api/teachings/program-manual/' + vm.teaching._id + '?v=' + Date.now(), '_blank').focus()

            } else {

                if (!vm.teaching.programma._id) return util.toast('Impossibile trovare file')
                $window.open('/api/teachings/program/' + vm.teaching.programma.file._id + '?v=' + Date.now(), '_blank').focus()

            }
        }

        function downloadMaterial (material) {
            $window.location.assign('/api/teachings/material/' + material._id)
        }

        $scope.$on('saveTeaching', save)

    }
})()
