(function() {
    'use strict'

    angular.module('teacher.teaching')
        .config(moduleConfig)

    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.teacher-teachings', {
                url: '/teacher/teachings',
                templateUrl: 'app/ssml/teacher/teaching/teachings.tmpl.html',
                // set the controller to load for this page
                controller: 'TeacherTeachingsController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.teacher-teaching', {
                url: '/teacher/teaching/:_id',
                templateUrl: 'app/ssml/teacher/teaching/teaching.tmpl.html',
                controller: 'TeacherTeachingController',
                controllerAs: 'vm',
                params: {
                    _id: { value: null },
                    codice: { value: null }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            name: 'Insegnamenti',
            icon: 'zmdi zmdi-graduation-cap',
            state: 'triangular.teacher-teachings',
            type: 'link',
            permission: 'manageTeaching',
            priority: 1.1
        })

    }
})()