import moment from "moment"
;(function() {
  "use strict"

  angular
    .module("student.calendar")
    .controller("StudentCalendarToolbarController", CalendarToolbarController)

  /* @ngInject */
  function CalendarToolbarController(
    $scope,
    $rootScope,
    $state,
    $log,
    $window,
    $element,
    $mdUtil,
    $mdSidenav,
    triBreadcrumbsService,
    uiCalendarConfig,
    $timeout,
    $user
  ) {
    var vm = this
    vm.breadcrumbs = triBreadcrumbsService.breadcrumbs
    vm.changeMonth = changeMonth
    vm.changeView = changeView
    vm.openSideNav = openSideNav
    vm.views = [
      {
        name: "Mese",
        icon: "zmdi zmdi-view-module",
        viewName: "month"
      },
      {
        name: "Settimana",
        icon: "zmdi zmdi-view-week",
        viewName: "agendaWeek"
      },
      {
        name: "Giorno",
        icon: "zmdi zmdi-view-day",
        viewName: "agendaDay"
      }
    ]
    vm.currentView = vm.views[0]
    vm.currentDate = moment()

    vm.currentUser = $user.current
    vm.logout = logout
    vm.returnToSecretary = returnToSecretary

    //////////////
    function returnToSecretary() {
      if (vm.currentUser.isAdmin) {
        $user.returnToSecretary().then(function(res) {
          if (res.status === 200) {
            $window.location.href = "/"
          }
        })
      } else {
        $log.log("user !admin")
      }
    }

    function logout() {
      $user.logout()
    }

    function changeMonth(direction) {
      uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(direction)
    }

    function changeView(view) {
      vm.currentView = view

      uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
        "changeView",
        view.viewName
      )

      if (vm.currentDate)
        uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
          "gotoDate",
          vm.currentDate
        )
    }

    function openSideNav(navID) {
      $mdUtil.debounce(function() {
        $mdSidenav(navID).toggle()
      }, 300)()
    }

    function setDay(event, date) {
      vm.currentDate = date

      vm.daySelected = true

      $timeout(function() {
        vm.daySelected = false
      }, 2000)
    }

    $scope.$on("dayClicked", setDay)
  }
})()
