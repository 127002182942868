/* @ngInject */
class FabController {
  constructor($rootScope) {
    this.$rootScope = $rootScope
  }
  add($event) {
    this.$rootScope.$broadcast("add", $event)
  }
}

export default angular
  .module("common")
  .controller("FabController", FabController)
