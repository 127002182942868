(function () {
    'use strict'

    angular
        .module('student.teaching')
        .controller('StudentMaterialController', StudentMaterialController)

    function StudentMaterialController($mdDialog, $scope, $teaching, $stateParams, $state, util, $log, $window) {

        var vm = this
        var q = undefined

        vm.back = back
        vm.materials = []
        vm.teaching = {}

        vm.download = download

        if ($stateParams.codice) {
            q = $teaching.getByCode($stateParams.codice)
        }
        if ($stateParams._id) {
            q = $teaching.getById($stateParams._id)
        }

        if (!q) return back()

        q.then(function (res) {
            vm.teaching = res.data
            vm.materials = res.data.materiale
            $log.log('log: res => ', res)
        }, function (xhr) {
            $log.error('xhr => ', xhr)
        })

        function download(materiale) {
            // TODO: download via ajax
            $window.location.assign('/api/teachings/material/' + materiale._id)
        }

        function back() {
            $state.go('triangular.student-study-plan')
        }

    }
})()
