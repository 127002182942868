/* @ngInject */
class ExamService {
  constructor($http) {
    this.$http = $http
  }
  ////////////////
  index(params) {
    return this.$http({
      method: "GET",
      url: "/api/exams/",
      params: params
    })
  }
  secretary(params) {
    return this.$http({
      method: "GET",
      url: "/api/exams/secretary",
      params: params
    })
  }
  saveCsv(params) {
    return this.$http({ url: "/api/exams/csv", params: params, method: "GET" })
  }
  show(id) {
    return this.$http.get("/api/exams/" + id)
  }
  showByTeaching(teachingId) {
    return this.$http.get("/api/exams/teaching/" + teachingId)
  }
  showByTeachingRaw(teachingId) {
    return this.$http.get("/api/exams/raw/teaching/" + teachingId)
  }
  showByStudent(studentId) {
    return this.$http.get("/api/exams/student/" + studentId)
  }
  create(esame) {
    delete esame.source
    return this.$http.post("/api/exams", esame)
  }
  update(esame) {
    delete esame.source
    return this.$http.put("/api/exams/" + esame._id, esame)
  }
  approve(esame) {
    delete esame.source
    return this.$http.put("/api/exams/approve/" + esame._id, esame)
  }
  start(esame) {
    delete esame.source
    return this.$http({
      method: "PUT",
      url: "/api/exams/start/" + esame._id,
      data: esame
    })
  }
  end(esame) {
    return this.$http.put("/api/exams/end/" + esame._id)
  }
  remove(id) {
    return this.$http.delete("/api/exams/" + id)
  }
}

export default angular
  .module("ssml.services")
  .service("ExamService", ExamService)
