import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.room")
    .controller("RoomsController", RoomsController)

  /* @ngInject */
  function RoomsController($scope, $log, $mdDialog, $mdToast, RoomService) {
    var vm = this

    vm.add = add

    var rowTmpl =
      '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'

    vm.gridOptions = {
      enableRowSelection: true,
      enableGridMenu: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: false,
      minRowsToShow: 17,
      rowTemplate: rowTmpl,
      data: []
    }

    vm.gridOptions.columnDefs = [
      {
        name: "nome"
      },
      {
        name: "posti"
      },
      {
        name: "color"
      },
      {
        name: "textColor"
      }
    ]

    vm.gridOptions.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
      gridApi.selection.on.rowSelectionChanged(null, function(row, event) {
        editRoom(row.entity, event, true)
      })
    }

    function editRoom(entity, event, isEdit) {
      $mdDialog
        .show({
          controller: "RoomDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/secretary/room/room-dialog.tmpl.html",
          targetEvent: event,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: (isEdit ? "Modifica" : "Inserisci") + " Aula",
              confirmButtonText: isEdit ? "Salva" : "Crea"
            },
            room: _.clone(entity), // bind model to dialog
            edit: isEdit
          }
        })
        .then(function(room) {
          if (angular.isDefined(room.deleted) && room.deleted === true) {
            _.remove(vm.gridOptions.data, {
              _id: room._id
            })
          }
          if (angular.isDefined(room.saved) && room.saved === true) {
            var index = _.findIndex(vm.gridOptions.data, entity)
            if (!_.isEmpty(entity)) _.remove(vm.gridOptions.data, entity)
            index
              ? vm.gridOptions.data.splice(index, 0, room)
              : vm.gridOptions.data.unshift(room)
          }
        })
    }

    RoomService.list().then(function(res) {
      if (res.status == 200) {
        vm.gridOptions.data = res.data
      }
    })

    function add(event, $event) {
      editRoom({}, $event)
    }

    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }

    $scope.$on("addRoom", add)
  }
})()
