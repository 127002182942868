/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 12/09/16 16:16.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: teaching.factory
 * Created with: WebStorm
 */
import _ from "lodash"

;(function() {
  "use strict"

  angular.module("ssml.services").service("$teaching", TeachingService)

  function TeachingService($http) {
    this.collection = []

    this.model = {}

    this.years = [
      "2014-2015",
      "2015-2016",
      "2016-2017",
      "2017-2018",
      "2018-2019"
    ]

    this.query = function query(exec, params) {
      var dfd = $http({
        url: "/api/teachings",
        method: "GET",
        params: params
      })

      if (!exec) return dfd

      return dfd.then(
        function success(res) {
          if (res && !_.isEmpty(res.data))
            angular.copy(res.data, this.collection)

          return res
        }.bind(this),

        function error(err) {
          return err
        }
      )
    }

    this.create = function(model, exec) {
      var dfdTeaching = $http.post("/api/teachings", model)

      if (!exec) return dfdTeaching

      return this.resolve$Q(dfdTeaching, model)
    }.bind(this)

    this.getById = function getById(id) {
      return $http.get("/api/teachings/" + id)
    }

    this.getByCode = function getByCode(code) {
      return $http.get("/api/teachings/code/" + code)
    }

    this.getHoursAssignedToTeaching = function getHoursAssignedToTeaching(
      params
    ) {
      return $http({
        method: "GET",
        url: "/api/lessons/hours-assigned-teaching",
        params: params
      })
    }

    this.updateHours = function updateHours(update) {
      return $http.put("/api/teachings/update-hours/" + update._id, update)
    }

    this.updateProgram = function updateProgram(model) {
      return $http.put(
        "/api/teachings/program-manual/" + model._id,
        model.programma
      )
    }

    this.uploadProgram = function uploadProgram(model) {
      return $http.put(
        "/api/teachings/program-upload/" + model._id,
        model.programma
      )
    }

    this.save = function save(model) {
      return $http.put("/api/teachings/" + model._id, model)
    }

    this.remove = function remove(model) {
      return $http.delete("/api/teachings/" + model._id)
    }

    this.destroyMaterial = function destroyMaterial(material) {
      return $http({
        url: "/api/teachings/material/" + material._id,
        method: "DELETE"
      })
    }

    this.addExamsDate = function addExamsDate(model) {
      return $http({
        method: "PUT",
        url: "/api/teachings/add-exams-date/" + model._id,
        data: {
          esami: model.esami
        }
      })
    }

    this.resolve$Q = function resolve$Q(dfd, model) {
      return dfd.then(
        function success(response) {
          angular.copy(response.data, model)
          return response
        }.bind(this),
        function error(err) {
          return err
        }
      )
    }
  }
})()
