import boardConfig from "./board.config"
import StudentBoardController from "./board.controller"
import StudentTeachingsBoardController from "./teachings-board.controller"

export default angular
  .module("student.board", [])
  .config(boardConfig)
  .controller("StudentBoardController", StudentBoardController)
  .controller(
    "StudentTeachingsBoardController",
    StudentTeachingsBoardController
  ).name
