(function() {
    'use strict'

    angular
        .module('secretary.teaching')
        .controller('TeachingFabController', TeachingFabController)

    /* @ngInject */
    function TeachingFabController($rootScope) {
        var vm = this
        vm.addTeaching = addTeaching

        ////////////////

        function addTeaching($event) {
            $rootScope.$broadcast('addTeaching', $event)
        }
    }
})()
