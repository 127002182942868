/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 21/10/16 11:00.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: calendar.config
 * Created with: WebStorm
 */

(function() {
    'use strict'

    angular
        .module('student.calendar')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.student-calendar', {
                // set the url of this page
                url: '/student/calendar',
                views: {
                    '@triangular': {
                        // set the html template to show on this page
                        templateUrl: 'app/ssml/student/calendar/calendar.tmpl.html',
                        // set the controller to load for this page
                        controller: 'StudentCalendarController',
                        controllerAs: 'vm'
                    },
                    'toolbar@triangular': {
                        templateUrl: 'app/ssml/student/calendar/layouts/toolbar/toolbar.tmpl.html',
                        controller: 'StudentCalendarToolbarController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        contentClass: 'triangular-non-scrolling layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['viewStudentCalendar'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            // give the menu a name to show (should be translatable and in the il8n folder json)
            name: 'Calendario',
            // point this menu to the state we created in the $stateProvider above
            state: 'triangular.student-calendar',
            // set the menu type to a link
            type: 'link',
            // set an icon for this menu
            icon: 'zmdi zmdi-calendar-alt',
            // set a proirity for this menu item, menu is sorted by priority
            priority: 2.3,
            permission: 'viewStudentCalendar'
        })
    }
})()
