export default class SecretaryBoardController {
  author = "SECRETARY"
  boardTypes = [
    { name: "Bacheca Docenti", id: "secretary-teacher" },
    { name: "Bacheca Studenti", id: "secretary-student" },
    {
      name: "Bacheca Docenti-Studenti",
      id: "secretary-teacher-student"
    }
  ]
  boardType = ""
  message = ""
  posts = []
  user = {}
  tinymceOptions = {
    plugins: "link image code",
    toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code"
  }
  /* @ngInject */
  constructor(BoardService, $user, $cookies, $sce) {
    this.BoardService = BoardService
    this.$cookies = $cookies
    this.$sce = $sce
    this.user = $user.current
    this.boardTypes = this.boardTypes.filter(board =>
      this.user.roles.some(role => board.id.includes(role.toLowerCase()))
    )
    const lastBoard = this.boardTypes.find(
      ({ id }) => id === $cookies.get("boardType")
    )
    this.boardType = (lastBoard && lastBoard.id) || this.boardTypes[0].id
    this.fetchData()
  }
  fetchData() {
    this.BoardService
      .getBySecretary({ type: this.boardType })
      .then(({ data }) => {
        this.posts = data
        // this.posts = data.reduce((prev, current, index, array) => {
        //   // se prev index -1 userId è uguale a current.userId
        //   // mergia i messaggi in prev index-1 e non aggiungere current
        //   // altrimenti aggiungi current e basta
        //   if (index === 0) return [current]
        //   const previous = array[index - 1]
        //   if (previous && previous.userId === current.userId) {
        //     previous.messages = [...previous.messages, ...current.messages]
        //     return [...prev.filter(post => post._id !== previous._id), previous]
        //   } else {
        //     return [...prev, current]
        //   }
        // }, [])
      })
      .catch(err => {
        console.error(err)
      })
  }
  submit(message) {
    if (!confirm("Confermi di voler pubblicare l'avviso?")) return null
    this.BoardService
      .create({
        messages: [message],
        author: this.author,
        userId: this.user._id,
        username: this.user.username,
        type: this.boardType
      })
      .then(({ data }) => {
        this.message = ""
        this.posts.push(data)
      })
      .catch(err => {
        console.error(err)
      })
  }
  userPosition(post) {
    return post.userId === this.user._id
      ? "user-right current-user"
      : "user-left"
  }
  userColor(post) {
    return post.userId === this.user._id ? "light-green" : "cyan"
  }
  userAvatar(post) {
    return post.userId === this.user._id
      ? "assets/images/avatars/avatar-3.png"
      : "assets/images/avatars/avatar-6.png"
  }
  trustMessage(message) {
    return this.$sce.trustAsHtml(message)
  }
  selectType(boardType) {
    this.boardType = boardType
    this.fetchData()
    this.$cookies.put("boardType", boardType)
  }
}
