import moment from "moment"
;(function() {
  "use strict"

  angular
    .module("student.calendar")
    .controller("StudentCalendarController", CalendarController)

  /* @ngInject */
  function CalendarController(
    $scope,
    $rootScope,
    $cookies,
    $log,
    $mdDialog,
    triLayout,
    uiCalendarConfig,
    $user
  ) {
    var vm = this

    vm.user = $user.current

    var defaultDate = angular.isDefined($cookies.get("current-day"))
      ? moment($cookies.get("current-day"), "YYYY-MM-DD")
      : moment()

    vm.viewFormats = {
      month: "MMMM YYYY",
      agendaWeek: "w - d MMMM YYYY",
      agendaDay: "Do MMMM YYYY"
    }

    vm.eventSources = [
      {
        url: "/api/exams/student",
        data: {
          studentId: vm.user.tipo == "Studente" ? vm.user._id : ""
        }
      },
      {
        // events: events
        url: "/api/lessons",
        data: {
          studentId: vm.user.tipo == "Studente" ? vm.user._id : ""
        }
      },
      {
        url: "/api/events"
      }
    ]

    vm.calendarOptions = {
      contentHeight: "auto",
      selectable: true,
      editable: false,
      header: false,
      lang: "it",
      timezone: "local",
      minTime: "07:30:00",
      maxTime: "20:00:00",
      hiddenDays: [0],
      defaultDate: defaultDate,
      defaultView: $cookies.get("calViewType") || "agendaWeek",
      timeFormat: "HH:mm",
      dayClick: dayClick,
      viewRender: viewRender,
      eventRender: eventRender,
      eventClick: eventClick
    }

    /////////////// functions

    function eventClick(calEvent, jsEvent, view) {
      //eslint-disable-line

      if (calEvent.tipo === "Lezione") return
      if (calEvent.tipo === "Evento") return

      $mdDialog
        .show({
          controller: "StudentEventDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/student/calendar/event-dialog.tmpl.html",
          targetEvent: jsEvent,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: calEvent.title,
              confirmButtonText: "Ok"
            },
            event: calEvent,
            edit: true
          }
        })
        .then(function(event) {
          $log.log("event =>", event)
        })
    }

    function eventRender(event, element) {
      // TODO: remove this code (update lesson model idInsegnamento => insegnamento e idAula => aula) and add type to event enum ['lesson', 'exam']
      if (event.tipo === "Lezione") {
        element
          .find(".fc-title")
          .append(
            "<br/>" +
              event.idInsegnamento.nome +
              "<br/>" +
              event.idInsegnamento.nomeDocente +
              (event.idAula && event.idAula.nome
                ? "<br/>" + event.idAula.nome
                : "")
          )
      }

      if (event.tipo === "Esame") {
        element
          .find(".fc-title")
          .append(
            "<br/>" +
              event.insegnamento.nome +
              "<br/>" +
              event.insegnamento.nomeDocente +
              (event.aula && event.aula.nome ? "<br/>" + event.aula.nome : "")
          )
      }
    }

    function dayClick(date, jsEvent, view) {
      //eslint-disable-line

      vm.currentDay = view.calendar.getDate()
      $cookies.put("current-day", vm.currentDay.format())

      $rootScope.$broadcast("dayClicked", date)
    }

    function viewRender(view) {
      vm.view = view

      $cookies.put("calViewType", view.type)
      // $cookies.putObject('calViewStart', view.start);
      // $cookies.putObject('calViewEnd', view.end);

      vm.currentDay = view.calendar.getDate()
      $cookies.put("current-day", vm.currentDay.format())

      vm.currentView = view.name
      // update toolbar with new day for month name
      $rootScope.$broadcast("calendar-changeday", vm.currentDay)
      // update background image for month
      triLayout.layout.contentClass =
        "calendar-background-image background-overlay-static overlay-gradient-10 calendar-background-month-" +
        vm.currentDay.month()
    }

    function refetchLessons(/* event, $event, params */) {
      uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
        "refetchEvents"
      )
    }

    ///////////////

    ////// listeners

    // $scope.$on('addEvent', addEvent);
    $scope.$on("fetchLessons", refetchLessons)

    //////
  }
})()
