(function() {
    'use strict'

    angular.module('teacher.program')
        .config(moduleConfig)

    function moduleConfig($stateProvider) {

        $stateProvider
            .state('triangular.teacher-program', {
                url: '/teacher/programs/:_id',
                templateUrl: 'app/ssml/teacher/program/program.tmpl.html',
                controller: 'TeacherProgramController',
                controllerAs: 'vm',
                params: {
                    codice: { value: null },
                    _id: { value: null }
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })

    }
})()