import "./secretary.module"

import "./address/address.module"
import "./address/address-dialog.controller"
import "./address/address.config"
import "./address/addresses.controller"

import "./admission/admission.module"
import "./admission/admission-dialog.controller"
import "./admission/admission.config"
import "./admission/admissions.controller"

import "./calendar/calendar.module"
import "./calendar/calendar-fabs.controller"
import "./calendar/calendar.config"
import "./calendar/calendar.controller"
import "./calendar/event-dialog.controller"
import "./calendar/padding.filter"
import "./calendar/layouts/toolbar/toolbar.controller"

import "./constraints/constraints.module"
import "./constraints/constraints.config"
import "./constraints/constraints.controller"
import "./constraints/constraints-fabs.controller"
import "./constraints/constraint-dialog.controller"

import "./events/event.module"
import "./events/event.config"
import "./events/events.controller"
import "./events/layouts/toolbar/toolbar.controller"

import "./exams/exam.module"
import "./exams/exam.config"
import "./exams/layouts/toolbar/toolbar.controller"
import "./exams/exams.controller"
import "./exams/exams-list.controller"
import "./exams/exam-dialog.controller"

import "./room/room.module"
import "./room/room.config"
import "./room/room-dialog.controller"
import "./room/room-fabs.controller"
import "./room/rooms.controller"

import "./session/session.module"
import "./session/session.config"
import "./session/sessions.controller"
import "./session/session-dialog.controller"
import "./session/session-fabs.controller"

import "./study-plan/study-plan.module"
import "./study-plan/study-plan.config"
import "./study-plan/study-plan.factory"
import "./study-plan/study-plans.controller"
import "./study-plan/study-plan-dialog.controller"
import "./study-plan/study-plan-fabs.controller"

import "./teaching/teaching.module"
import "./teaching/teaching.config"
import "./teaching/teaching.controller"
import "./teaching/teachings.controller"
import "./teaching/teaching-save-fab.controller"
import "./teaching/teaching-fabs.controller"
import "./teaching/teaching-dialog.controller"

import "./user/user.module"
import "./user/user.config"
import "./user/user-fabs.controller"
import "./user/user-dialog.controller"
import "./user/users.controller"
