import "./services.module"
import "./address.service"
import "./admission.service"
import "./class-room.service"
import "./constraints.service"
import "./error.service"
import "./event.service"
import "./exam-registration.service"
import "./exam.service"
import "./language.service"
import "./lesson.service"
import "./room.service"
import "./services.module"
import "./session.service"
import "./storage.service"
import "./teacher.service"
import "./teaching.service"
import "./util.service"
import "./board.service"
