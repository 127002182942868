import _ from 'lodash'

(function () {
    'use strict'

    angular
        .module('secretary.user')
        .controller('UsersController', UsersController)

    /* @ngInject */
    function UsersController($scope, $log, $mdDialog, $mdToast, $address, $user, $language) {
        var vm = this
        vm.add = add
        vm.stats = {
            students: 0,
            teachers: 0,
            admissions: 0,
            logins: 0
        }

        var addresses = []
        var users = []

        var languages = $language.collection

        var admissions = $user.queryAdmission()
            .then(function (res) {
                if (res.data) vm.stats.admissions = res.data.length
                if (res.data) return res.data
                return res
            }, function (err) {
                $log.error(err)
                return err
            })

        var rowTmpl = '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'

        vm.gridOptions = {
            enableRowSelection: true,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: true,
            showGridFooter: true,
            multiSelect: false,
            minRowsToShow: 17,
            rowTemplate: rowTmpl,
            data: users
        }

        vm.gridOptions.columnDefs = [
            { name: 'nomeCompleto' },
            { name: 'tipo' },
            { name: 'username' },
            { name: 'matricola' },
            { name: 'dataNascita' }
        ]

        vm.gridOptions.onRegisterApi = function (gridApi) {
            //set gridApi on scope
            vm.gridApi = gridApi
            gridApi.selection.on.rowSelectionChanged(null, function (row, event) {

                editUser(row.entity, event, true)

            })
        }

        function editUser(entity, event, isEdit) {
            $mdDialog.show({
                controller: 'UserDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/ssml/secretary/user/user-dialog.tmpl.html',
                targetEvent: event,
                focusOnOpen: false,
                locals: {
                    dialogData: {
                        title: (isEdit ? 'Modifica' : 'Inserisci') + ' Utente',
                        confirmButtonText: (isEdit ? 'Salva' : 'Crea')
                    },
                    languages: languages,
                    admissions: admissions,
                    addresses: addresses,
                    user: _.clone(entity), // bind model to dialog
                    edit: isEdit
                }
            })
                .then(function (user) {

                    if (angular.isDefined(user.deleted) && user.deleted === true) {

                        _.remove(vm.gridOptions.data, user)

                    }
                    if (angular.isDefined(user.saved) && user.saved === true) {
                        var index = _.findIndex(vm.gridOptions.data, entity)
                        if (!_.isEmpty(entity)) _.remove(vm.gridOptions.data, entity)
                        index ? vm.gridOptions.data.splice(index, 0, user) : vm.gridOptions.data.unshift(user)
                    }

                })
        }

        $language.query(true)

        $address.query()
            .then(function (res) {
                addresses = res.data
                return res
            })

        $user.query()
            .then(function (res) {
                users = res.data
                vm.gridOptions.data = users
                users.forEach(function countStats(user) {

                    if (user.tipo === 'Studente')++vm.stats.students
                    if (user.tipo === 'Docente')++vm.stats.teachers

                    // TODO add logins count with ultimoLogin.length

                })
                return res.data
            })

        function add(event, $event) {
            editUser({}, $event)
        }

        $scope.$on('addUser', add)

    }
})()
