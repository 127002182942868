export default class StudentTeachingsBoardController {
  author = "TEACHER"
  teachings = [] // fetch teachings by piano di studi
  teaching = "tutti"
  message = ""
  posts = []
  user = {}
  /* @ngInject */
  constructor(BoardService, $teaching, $studyPlan, $user, $sce) {
    this.BoardService = BoardService
    this.$teaching = $teaching
    this.$studyPlan = $studyPlan
    this.$sce = $sce
    this.user = $user.current
    this.fetchTeachings()
  }
  async fetchTeachings() {
    const studyPlan = await this.$studyPlan.showByUser(this.user._id)

    // const teachings = await this.$teaching.query(false, {
    //   _id: { $in: [...studyPlan.data.teachings.map(({ _id }) => _id)] },
    //   stato: "Attivo"
    // })

    this.teachings = studyPlan.data.teachings
    this.fetchData()
  }
  fetchData() {
    const params = {}
    if (this.teaching === "tutti") {
      params.teachings = [...this.teachings.map(({ _id }) => _id)]
    } else {
      params.teachings = [this.teaching]
    }
    this.BoardService
      .getByTeachings(params)
      .then(({ data }) => {
        this.posts = data.map(post => ({
          ...post,
          teachings: [
            ...post.teachings.filter(id =>
              this.teachings.map(({ _id }) => _id).includes(id)
            )
          ]
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }
  userPosition(post) {
    return post.userId === this.user._id
      ? "user-right current-user"
      : "user-left"
  }
  userColor(post) {
    return post.userId === this.user._id ? "light-green" : "cyan"
  }
  userAvatar(post) {
    return post.userId === this.user._id
      ? "assets/images/avatars/avatar-3.png"
      : "assets/images/avatars/avatar-6.png"
  }
  trustMessage(message) {
    return this.$sce.trustAsHtml(message)
  }
  selectType(teaching) {
    this.teaching = teaching
    this.fetchData()
  }
  getTeachingName(id) {
    const { nome, codice } = this.teachings.find(({ _id }) => _id === id)
    return `${nome} - ${codice}`
  }
}
