/* @ngInject */
export default class UserService {
  constructor(
    $q,
    $http,
    $window,
    PermRoleStore,
    PermPermissionStore,
    $log,
    $state,
    $cookies
  ) {
    var guestUser = {
      displayName: "guest",
      username: "guest",
      avatar: "assets/images/avatars/avatar-5.png",
      roles: ["USER"]
    }

    var current = $cookies.getObject("user") || {
      ...guestUser
    }

    this.query = query
    this.getById = getById
    this.create = create
    this.save = save
    this.remove = remove
    this.confirmPassword = confirmPassword
    this.forgotPassword = forgotPassword
    this.resetPassword = resetPassword
    this.loginAs = loginAs
    this.returnToSecretary = returnToSecretary
    this.check = check
    this.getCurrent = getCurrent
    this.getUsers = getUsers
    this.hasPermission = hasPermission
    this.login = login
    this.logout = logout
    this.queryAdmission = queryAdmission
    this.current = current

    ///////////////

    function query(exec, params) {
      var dfd

      dfd = $http({
        url: "/api/users",
        method: "GET",
        params: params
      })

      if (!exec) return dfd

      return dfd.then(
        function(res) {
          return res
        },
        function(res) {
          return res
        }
      )
    }

    function getById(id) {
      return $http({
        url: "/api/users/" + id,
        method: "GET"
      })
    }

    function confirmPassword(password) {
      return $http.post("/api/v1/auth/confirm-password", password)
    }

    function resetPassword(user) {
      return $http.post("/api/users/reset-password", user)
    }

    function forgotPassword(user) {
      return $http.post("/api/v1/auth/forgot-password", user)
    }

    function create(user, exec) {
      var dfd = $http.post("/api/users/", user)
      if (!exec) return dfd

      return dfd.then(
        function(res) {
          return res
        },
        function(res) {
          return res
        }
      )
    }

    function save(user, exec) {
      var dfd = $http.put("/api/users/" + user._id, user)
      if (!exec) return dfd

      return dfd.then(
        function(res) {
          return res
        },
        function(res) {
          return res
        }
      )
    }

    function remove(user, exec) {
      var dfd = $http.delete("/api/users/" + user._id)
      if (!exec) return dfd

      return dfd.then(
        function(res) {
          return res
        },
        function(res) {
          return res
        }
      )
    }

    function queryAdmission() {
      return $http.get("/api/v1/admissions")
    }

    function setCurrent(user) {
      angular.copy(user, current)
      current.displayName = current.username
      current.avatar = "assets/images/avatars/avatar-5.png"
      $cookies.putObject("user", current)
      // $log.log("setCurrent =>", current)
    }

    function loginAs(idUser) {
      return $http.get("/api/v1/auth/secretary-login/" + idUser).then(
        function(res) {
          // console.log('res =>', res);

          if (res.status === 200) {
            if (res.data.username) setCurrent(res.data)

            // $window.location.href = '/';
          }

          return res
        },
        function(res) {
          $log.error(res)
          return res
        }
      )
    }

    function returnToSecretary() {
      return $http.get("/api/v1/auth/return-to-secretary").then(
        function(res) {
          if (res.status === 200) {
            if (res.data.username) setCurrent(res.data)

            // $window.location.href = '/';
          }

          return res
        },
        function(res) {
          $log.error(res)
          return res
        }
      )
    }

    function check() {
      return $http.get("/api/v1/auth/currentuser").then(
        function success(res) {
          var status = res.status.toString()[0]
          if (res.data && status == 2) setCurrent(res.data)
          return res.data
        },
        function error(xhr) {
          $log.error("/api/v1/auth/currentuser => ", xhr.status, xhr.data)
          $cookies.remove("user")
          // if ($window.location.hash.match(/#\/reset-password\/{20,}/) &&
          //     $window.location.hash.match(/#\/reset-password\/{20,}/).length == 0)
          //     if (xhr.status === 401) $state.go('authentication.login');
          var roles = PermRoleStore.getStore()

          console.log("roles =>", roles)
          console.log("current user =>", current)
          return current
        }
      )
    }
    // TODO :remove this
    function getCurrent() {
      return current
    }
    // TODO: remove this
    function getUsers() {
      return $http.get("app/permission/data/users.json")
    }

    function hasPermission(permission, rolesMap) {
      var deferred = $q.defer()
      var hasPermission = false

      // check if user has permission via its roles
      angular.forEach(current.roles, function(role) {
        // check role exists
        if (PermRoleStore.hasRoleDefinition(role)) {
          // get the role
          // var roles = PermRoleStore.getStore();

          // if (angular.isDefined(roles[role])) {
          // console.log("rolesMap =>", rolesMap);
          // console.log("permissions =>", rolesMap[role]);
          if (angular.isDefined(rolesMap[role])) {
            // check if the permission we are validating is in this role's permissions
            // console.log('roles[role] =>', roles[role].validationFunction());
            // console.log("permission =>", permission);
            if (rolesMap[role].includes(permission)) {
              hasPermission = true
            }
            // if (
            //     -1 !==
            //     roles[role]/* .validationFunction */.indexOf(permission)
            // ) {
            //     hasPermission = true;
            // }
          }
        }
      })
      // console.log("hasPermission =>", hasPermission);
      // if we have permission resolve otherwise reject the promise
      if (hasPermission) {
        deferred.resolve()
      } else {
        deferred.reject()
      }

      // return promise
      return deferred.promise
    }

    function login(user) {
      return $http.post("/api/v1/auth/login", user).then(
        function(user) {
          if (user.data) angular.copy(user.data, current)
          setCurrent(user.data)
          return user
        },
        function(err) {
          $log.log("err => ", err)
          return err
        }
      )
    }

    function logout() {
      if (current.username !== "guest") {
        return $http.get("/api/v1/auth/logout").then(
          function(res) {
            setCurrent(guestUser)
            $state.go("authentication.login")
            return res
          },
          function(xhr) {
            $log.error("xhr err logout => ", xhr)
            return xhr
          }
        )
      } else {
        return null
      }
    }
  }
}
