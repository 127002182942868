import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.admission")
    .controller("AdmissionsController", AdmissionsController)

  /* @ngInject */
  function AdmissionsController(admissionService, $mdDialog, $log) {
    var vm = this

    var rowTmpl =
      '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'

    vm.gridOptions = {
      enableRowSelection: true,
      enableGridMenu: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: false,
      minRowsToShow: 17,
      rowTemplate: rowTmpl,
      data: admissionService.collection
    }

    vm.gridOptions.columnDefs = [
      { name: "nome" },
      { name: "cognome" },
      { name: "annoAccademico" },
      { name: "createdOn" },
      { name: "codiceIndirizzoStudi" },
      { name: "descrizioneIndirizzoStudi" }
    ]

    vm.gridOptions.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
      gridApi.selection.on.rowSelectionChanged(null, function(row, event) {
        showAdmission(row.entity, event)
      })
    }

    admissionService.list()

    ////////////////

    function showAdmission(model, event) {
      $mdDialog
        .show({
          controller: "AdmissionDialogController",
          controllerAs: "vm",
          templateUrl:
            "app/ssml/secretary/admission/admission-dialog.tmpl.html",
          targetEvent: event,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: "Domanda ammissione (solo Visualizzazione)"
            },
            user: {
              ...model
            } // bind model to dialog
          }
        })
        .then(() => {})
        .catch(() => {})
    }
  }
})()
