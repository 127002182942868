/**
 * Created by daton on 16/10/16.
 */

(function () {
    'use strict'

    angular.module('secretary.constraints', [])

})()
