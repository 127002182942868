import moduleConfig from "./authentication.config"
import UserService from "./user.service"
// import ForgotController from "./forgot/forgot.controller";
// import LoginController from "./login/login.controller";
// import ResetController from "./reset/reset-password.controller";

export default angular
  .module("authentication", [])
  .config(moduleConfig)
  // .controller("ForgotController", ForgotController)
  // .controller("LoginController", LoginController)
  // .controller("ResetPasswordController", ResetController)
  .service("$user", UserService).name
