(function() {
    'use strict'

    angular.module('secretary.study-plan')
        .config(StudyPlan)

    function StudyPlan($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.study-plans', {
                url: '/secretary/study-plans',
                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/study-plan/study-plans.tmpl.html',
                        // set the controller to load for this page
                        controller: 'StudyPlansController',
                        controllerAs: 'vm',
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/study-plan/study-plan-fabs.tmpl.html',
                        controller: 'StudyPlanFabController',
                        controllerAs: 'vm'
                    }
                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            id: 'secretary-study-plans',
            name: 'Piani di studi',
            icon: 'fa fa-book',
            permission: 'SECRETARY',
            type: 'link',
            state: 'triangular.study-plans',
            priority: 1.1
        })

    }

})()