import './ssml.module'
import './services/index'
import './secretary/index'
import './teacher/index'
import './student/index'
import './common/common.module'
import './common/mail/mail.module'
import './common/mail/mail.config'
import './common/mail/mail.controller'
import './common/fabs/fab.controller'
