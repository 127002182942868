import moment from 'moment'

(function () {
    'use strict'

    angular
        .module('secretary.event')
        .controller('SecretaryEventsToolbarController', SecretaryEventsToolbarController)

    /* @ngInject */
    function SecretaryEventsToolbarController($scope, $state, $element, $mdUtil, $timeout, $mdSidenav, triBreadcrumbsService, uiCalendarConfig) {
        var vm = this
        vm.breadcrumbs = triBreadcrumbsService.breadcrumbs
        vm.changeMonth = changeMonth
        vm.changeView = changeView
        vm.openSideNav = openSideNav
        vm.views = [{
            name: 'Month',
            icon: 'zmdi zmdi-view-module',
            viewName: 'month'
        }, {
            name: 'Week',
            icon: 'zmdi zmdi-view-week',
            viewName: 'agendaWeek'
        }, {
            name: 'Day',
            icon: 'zmdi zmdi-view-day',
            viewName: 'agendaDay'
        }]
        vm.currentView = vm.views[0]
        vm.currentDate = moment()

        //////////////

        function changeMonth(direction) {
            uiCalendarConfig.calendars['triangular-calendar'].fullCalendar(direction)
        }

        function changeView(view) {
            vm.currentView = view
            uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('changeView', view.viewName)
            if (vm.currentDate) uiCalendarConfig.calendars['triangular-calendar'].fullCalendar( 'gotoDate', vm.currentDate )
        }

        function openSideNav(navID) {
            $mdUtil.debounce(function () {
                $mdSidenav(navID).toggle()
            }, 300)()
        }

        function setDay(event, date) {

            vm.currentDate = date

            vm.daySelected = true

            $timeout(function () {
                vm.daySelected = false
            }, 2000)

        }

        $scope.$on('dayClicked', setDay)
    }
})()
