/**
 * Created by daton on 16/10/16.
 */

(function () {
    'use strict'

    angular.module('secretary.constraints')
        .config(ConfigConstraints)

    function ConfigConstraints($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.secretary-constraints', {
                url: '/secretary/constraints',

                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/constraints/constraints.tmpl.html',
                        // set the controller to load for this page
                        controller: 'ConstraintsController',
                        controllerAs: 'vm'
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/constraints/constraints-fabs.tmpl.html',
                        controller: 'ConstraintsFabController',
                        controllerAs: 'vm'
                    }
                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        // var menu = triMenuProvider.getMenu('secretary');
        //
        // if (menu && menu.children instanceof Array) {
        //
        //     menu.children.push({
        //         name: 'Vincoli Calendario',
        //         state: 'triangular.secretary-constraints',
        //         type: 'link'
        //     });
        // }

        triMenuProvider.addMenu({
            id: 'calendar-constraints',
            name: 'Vincoli Calendario',
            icon: 'fa fa-calendar',
            permission: 'SECRETARY',
            type: 'link',
            priority: 2.1,
            state: 'triangular.secretary-constraints'
        })

    }


})()
