/**
 * Created by daton on 15/10/16.
 */

(function () {
    'use strict'

    angular.module('ssml.services')
        .service('$classRoom', ClassRoomFactory)

    function ClassRoomFactory($http) {

        var uri = '/api/classrooms'
        var dfd

        this.collection = []
        this.model = {}

        this.query = function query(resolve) {

            dfd = $http.get(uri)

            if (!resolve) return dfd

            return this.resolve$Q(dfd)
        }

        this.save = function save(model, resolve) {

            dfd = $http.put(uri + '/' + model._id, model)

            if (!resolve) return dfd

            return this.resolve$Q(dfd)
        }

        this.create = function create(model, resolve) {

            dfd = $http.post(uri, model)

            if (!resolve) return dfd

            return this.resolve$Q(dfd)
        }

        this.remove = function remove(model, resolve) {

            dfd = $http.delete(uri + '/' + model._id)

            if (!resolve) return dfd

            return this.resolve$Q(dfd)
        }

        this.resolve$Q = function resolve$Q(dfd) {
            return dfd
                .then(function (res) {

                    if (res && res.data) angular.copy(res.data, this.collection)

                    return res

                }.bind(this), function (err) {

                    return err

                })
        }

    }


})()
