import moment from 'moment'

(function() {
    'use strict'

    angular
        .module('secretary.exam')
        .controller('ExamCalendarToolbarController', ExamCalendarToolbarController)

    /* @ngInject */
    function ExamCalendarToolbarController($scope, $rootScope, $state, $element, $mdUtil, $mdSidenav, triBreadcrumbsService, uiCalendarConfig, $timeout, teachers, $user) {
        var vm = this
        vm.breadcrumbs = triBreadcrumbsService.breadcrumbs
        vm.changeMonth = changeMonth
        vm.changeView = changeView
        vm.openSideNav = openSideNav
        vm.saveCsv = saveCsv
        vm.views = [{
            name: 'Mese',
            icon: 'zmdi zmdi-view-module',
            viewName: 'month'
        },{
            name: 'Settimana',
            icon: 'zmdi zmdi-view-week',
            viewName: 'agendaWeek'
        },{
            name: 'Giorno',
            icon: 'zmdi zmdi-view-day',
            viewName: 'agendaDay'
        }]
        vm.currentView = vm.views[0]
        vm.currentDate = moment()
        vm.teachers = teachers.data
        vm.teacherFilter = ''
        vm.yearFilter = ''
        vm.years = [{
            _id: 1,
            name: 'Primo Anno'
        }, {
            _id: 2,
            name: 'Secondo Anno'
        }, {
            _id: 3,
            name: 'Terzo Anno'
        }]
        vm.emitTeacherFilter = emitTeacherFilter
        vm.emitYearFilter = emitYearFilter
        vm.currentUser = $user.current
        vm.logout = logout
        //////////////

        function logout() {
            $user.logout()
        }

        function changeMonth(direction) {
            uiCalendarConfig.calendars['triangular-calendar'].fullCalendar(direction)
        }

        function changeView(view) {
            vm.currentView = view

            uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('changeView', view.viewName)

            if (vm.currentDate) uiCalendarConfig.calendars['triangular-calendar'].fullCalendar( 'gotoDate', vm.currentDate )
        }

        function openSideNav(navID) {
            $mdUtil.debounce(function(){
                $mdSidenav(navID).toggle()
            }, 300)()
        }

        function setDay(event, date) {

            vm.currentDate = date

            vm.daySelected = true

            $timeout(function () {
                vm.daySelected = false
            }, 2000)

        }

        function saveCsv () {
            $rootScope.$broadcast('saveCsv')
        }

        function emitTeacherFilter() {
            // vm.yearFilter = '';
            $rootScope.$broadcast('filterTeacher', vm.teacherFilter)
        }
        function emitYearFilter() {
            // vm.teacherFilter = '';
            $rootScope.$broadcast('filterYear', vm.yearFilter)
        }

        $scope.$on('dayClicked', setDay)

    }
})()
