import boardConfig from "./board.config"
import TeacherBoardController from "./board.controller"
import TeacherTeachingsBoardController from "./teachings-board.controller"

export default angular
  .module("teacher.board", [])
  .config(boardConfig)
  .controller("TeacherBoardController", TeacherBoardController)
  .controller(
    "TeacherTeachingsBoardController",
    TeacherTeachingsBoardController
  ).name
