(function () {
    'use strict'

    angular.module('student.teaching')
        .controller('StudentTeachingController', StudentTeachingController)

    /* @ngInject */
    function StudentTeachingController($state, $stateParams, $log, $teaching) {

        var vm = this
        vm.back = back
        var query = undefined

        if (!$stateParams._id && !$stateParams.codice) return back() 

        if ($stateParams.codice) {
            query = $teaching.getByCode($stateParams.codice)
        }
        if ($stateParams._id) {
            query = $teaching.getById($stateParams._id)
        }

        if (!query) return back()

        query.then(function (res) {
            vm.teaching = res.data
            vm.teaching.cfu = $stateParams.cfu
            $stateParams.codice = vm.teaching.codice 
            $state.go('triangular.student-teaching', $stateParams, { notify: false }) 
        }, function (xhr) {
            $log.error('xhr => ', xhr)
        })

        vm.program = program
        vm.material = material
        vm.exam = exam

        function exam() {
            $state.go('triangular.student-teaching-exam', $stateParams)
        }

        function material() {
            $state.go('triangular.student-teaching-material', $stateParams)
        }

        function program() {
            $state.go('triangular.student-teaching-program', $stateParams)
        }

        function back() {
            $state.go('triangular.student-study-plan') 
        }

    }

})()