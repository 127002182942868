/**
 * Created by daton on 10/10/16.
 */

(function() {
    "use strict"

    class AddressService {
        constructor($http) {
            this.collection = []
            this.model = {}
            this.$http = $http
        }
        query(resolve) {
            var dfdAddress = this.$http.get("/api/addresses")
            if (!resolve) return dfdAddress
            return dfdAddress.then(
                function(res) {
                    if (res && res.data)
                        angular.copy(res.data, this.collection)
                    return res
                }.bind(this),
                function(err) {
                    return err
                }
            )
        }
        save(address) {
            return this.$http.put("/api/addresses/" + address._id, address)
        }
        create(address) {
            return this.$http.post("/api/addresses", address)
        }
        remove(address) {
            return this.$http.delete("/api/addresses/" + address._id)
        }
    }

    angular.module("ssml.services").service("$address", AddressService)
})()
