(function () {
    'use strict'

    angular
        .module('ssml.services')
        .service('ExamRegistrationService', ExamRegistrationService)


    /* @ngInject */
    function ExamRegistrationService($http) {

        this.list = list
        this.show = show
        this.showByStudent = showByStudent
        this.showByTeaching = showByTeaching
        this.showBySecretary = showBySecretary
        this.cancelRegistration = cancelRegistration
        this.create = create
        this.update = update
        this.remove = remove

        ////////////////

        function list(params) {
            return $http({
                url: '/api/exam-registration',
                params: params,
                method: 'GET'
            })
        }

        function show(id) {
            return $http.get('/api/exam-registration/' + id)
        }

        function showByTeaching(params) {
            return $http({
                url: '/api/exam-registration/teaching',
                method: 'GET',
                params: params
            })
        }

        function showByStudent(params) {
            return $http({
                url: '/api/exam-registration/student',
                method: 'GET',
                params: params
            })
        }

        function showBySecretary(params) {
            return $http({
                url: '/api/exam-registration/secretary',
                method: 'GET',
                params: params
            })
        }

        function cancelRegistration(params) {
            return $http({
                url: '/api/exam-registration/cancel-registration',
                method: 'GET',
                params: params
            })
        }

        function create(esame) {
            return $http.post('/api/exam-registration', esame)
        }

        function update(examRegistration) {
            return $http.put('/api/exam-registration/' + examRegistration._id, examRegistration)
        }

        function remove(id) {
            return $http.delete('/api/exam-registration/' + id)
        }

    }

})()
