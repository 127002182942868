import moment from "moment"
import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.user")
    .controller("UserDialogController", UserDialogController)

  /* @ngInject */
  function UserDialogController(
    $mdDialog,
    $mdToast,
    $log,
    $window,
    dialogData,
    user,
    $user,
    addresses,
    admissions,
    util,
    languages,
    edit
  ) {
    var vm = this
    vm.cancelClick = cancelClick
    vm.deleteClick = deleteClick
    vm.dialogData = dialogData
    vm.loginAs = loginAs
    vm.edit = edit
    vm.user = user
    vm.okClick = okClick
    vm.selectType = getStudents
    vm.createMatricola = createMatricola
    vm.createUsername = createUsername
    vm.createEmailScuola = createEmailScuola
    vm.terzaLinguaChanged = terzaLinguaChanged
    vm.error = ""

    vm.addresses = addresses
    vm.admissions = admissions
    vm.languages = languages
    vm.indirizzo = {}

    vm.selectAdmission = {}

    vm.selectedAdmission = selectedAdmission

    vm.userTypes = [
      {
        name: "Studente"
      },
      {
        name: "Docente"
      },
      {
        name: "Segreteria"
      },
      {
        name: "Amministratore"
      },
      {
        name: "Cultore della materia"
      }
    ]

    var students

    $user.getById(vm.user._id).then(
      function(res) {
        vm.user = res.data
      },
      function(err) {
        $log.error("err", err)
      }
    )

    ////////////////
    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }

    function loginAs() {
      $user.loginAs(vm.user._id).then(function(res) {
        if (res.status === 200) {
          $window.location.href = "/"
        } else {
          vm.error = util.findMessage(res.data)
          util.errorMessage = ""
          toast("Operazione fallita!")
        }
      })
    }

    function okClick() {
      vm.error = ""
      var deferred = edit ? $user.save(vm.user) : $user.create(vm.user)

      deferred.then(
        function(user) {
          toast(edit ? "Utente aggiornato!" : "Utente aggiunto!")
          user.saved = true
          $mdDialog.hide(user)
        },
        function(err) {
          $log.error(err)
          vm.error = util.findMessage(err)
          util.errorMessage = ""
          toast("Operazione fallita!")
        }
      )
    }

    // function findMessage(object) {
    //     var key = 'message';
    //     Object.keys(object).filter(function (k) {
    //
    //         if (object[k] instanceof Object) findMessage(object[k]);
    //
    //         if (k == key) vm.error += '<p>' + object[k] + '</p>';
    //
    //     });
    //     return vm.error;
    // }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function deleteClick() {
      vm.error = ""
      if (confirm("Sei sicuro? Questa azione è irreversibile!")) {
        // delete user
        $user.remove(vm.user).then(
          function() {
            toast("Utente eliminato!")
            vm.user.deleted = true
            $mdDialog.hide(vm.user)
          },
          function(err) {
            vm.error = util.findMessage(err)
            util.errorMessage = ""
            toast("Impossibile eliminare utente")
          }
        )
      }
    }

    function getStudents() {
      switch (vm.user.tipo) {
        case "Studente":
          vm.isStudent = true
          $user
            .query(true, {
              type: "student"
            })
            .then(
              function(res) {
                if (res.data) students = res.data
              },
              function(err) {
                $log.error("log: err => ", err)
              }
            )
          break
      }
    }

    function terzaLinguaChanged() {
      if (!vm.user.terzaLingua) delete vm.user.terzaLingua
    }

    function createMatricola() {
      var numStudents = 0
      if (students && students instanceof Array) {
        numStudents = students.length + 1
        if (numStudents < 999) numStudents = "0" + numStudents
        if (numStudents < 99) numStudents = "0" + numStudents
        if (numStudents < 9) numStudents = "0" + numStudents
      }

      vm.user.matricola =
        _.find(vm.addresses, {
          _id: vm.user.indirizzo
        }).acronimo +
        moment(vm.user.annoImmatricolazione.slice(0, -5), "YYYY").format("YY") +
        numStudents
    }

    function createUsername() {
      vm.user.username =
        vm.user.nome[0].toLowerCase() +
        "." +
        vm.user.cognome
          .toLowerCase()
          .replace(/\s/g, "")
          .replace(/'/g, "")
    }

    function createEmailScuola() {
      vm.user.emailScuola = vm.user.username + "@ssmlmolise.com"
    }

    function selectedAdmission() {
      vm.user = _.find(vm.admissions, {
        _id: vm.selectAdmission
      })

      vm.user.ammissione = _.clone(vm.user)

      delete vm.user._id

      vm.user.secondaLingua = _.find(vm.languages, {
        nome: vm.user.secondaLingua
      })
      vm.user.terzaLingua = _.find(vm.languages, {
        nome: vm.user.terzaLingua
      })

      var acronimoIndirizzo = vm.user.codiceIndirizzoStudi.toUpperCase()

      if (acronimoIndirizzo == "SDF") acronimoIndirizzo = "SDS"

      vm.indirizzo = _.find(vm.addresses, {
        acronimo: acronimoIndirizzo
      })

      if (!angular.isDefined(vm.indirizzo)) vm.isAddressUndefined = true

      vm.user.tipo = "Studente"
      vm.user.aa = vm.user.annoAccademico
      vm.user.cf = vm.user.codiceFiscale
      vm.user.indirizzo = vm.indirizzo._id

      createMatricola()

      createUsername()

      createEmailScuola()

      // console.log('log: vm.user => ', vm.user);
    }
  }
})()
