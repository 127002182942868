(function () {
    'use strict'

    angular.module('teacher.exam')
        .config(moduleConfig)

    /* @ngInject*/
    function moduleConfig($stateProvider) {

        //TODO: define palette 
        // $mdThemingProvider.theme('exam'); 

        $stateProvider
            .state('triangular.teacher-exam-creation', {
                url: '/teacher/exam-creation/:_id',
                templateUrl: 'app/ssml/teacher/exam/exam-creation.tmpl.html',
                controller: 'TeacherExamCreationController',
                controllerAs: 'vm',
                params: {
                    codice: null,
                    _id: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.teacher-exam-summary', {
                url: '/teacher/exams-summary',
                templateUrl: 'app/ssml/teacher/exam/exam-summary.tmpl.html',
                controller: 'TeacherExamSummaryController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.teacher-exam', {
                url: '/teacher/exam/:_id',
                templateUrl: 'app/ssml/teacher/exam/exam.tmpl.html',
                controller: 'TeacherExamController',
                controllerAs: 'vm',
                params: {
                    codice: { value: null },
                    _id: null
                },
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['SECRETARY', 'manageTeaching'],
                        redirect: 'authentication.login'
                    }
                }
            })

    }
})()