/* @ngInject */
export default function runFunction($rootScope, $state, $log, amMoment) {
  amMoment.changeLocale("it")

  // default redirect if access is denied
  function redirectError(
    event,
    toState,
    toParams,
    fromState,
    fromParams,
    error
  ) {
    $log.error(event, error)

    // ErrorService.save({
    //     message: error.message,
    //     url: window.location.href,
    //     error: error
    // })
    //     .then(console.log, console.error); // eslint-disable-line

    $state.go("500")
  }

  // watches
  // window.onerror = function(message, url, lineNumber) {
  //     //save error and send to server for example.
  //
  //     util.toast(message);
  //
  //     ErrorService.save({
  //         message: message,
  //         url: url,
  //         lineNumber: lineNumber
  //     })
  //         .then(console.log, console.error); // eslint-disable-line
  //
  //     return true;
  // };

  // window.onerror = function (msg, url, lineNo, columnNo, error) {
  //     var string = msg.toLowerCase();
  //     var substring = 'script error';
  //     if (string.indexOf(substring) > -1){
  //         alert('Script Error: See Browser Console for Detail');
  //     } else {
  //         var message = [
  //             'Message: ' + msg,
  //             'URL: ' + url,
  //             'Line: ' + lineNo,
  //             'Column: ' + columnNo,
  //             'Error object: ' + JSON.stringify(error)
  //         ].join(' - ');

  //         alert(message);
  //     }

  //     return false;
  // };

  // function stateChangeStart(event,
  //     toState,
  //     toParams,
  //     fromState,
  //     fromParams,) {
  //     // console.log('event =>', event)
  //     console.log('fromState =>', fromState.name, 'toState =>', toState.name)
  // }

  // redirect all errors to permissions to 500
  var errorHandle = $rootScope.$on("$stateChangeError", redirectError)
  // var startHandle = $rootScope.$on("$stateChangeStart", stateChangeStart)

  // remove watch on destroy
  $rootScope.$on("$destroy", function() {
    errorHandle()
    // startHandle()
  })
}
