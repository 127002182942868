(function() {
  "use strict"

  angular.module("ssml.services").service("EventService", EventService)

  /* @ngInject */
  function EventService($http) {
    var model = {}
    var collection = []

    this.model = model
    this.collection = collection

    this.index = index
    this.show = show
    this.create = create
    this.update = update
    this.remove = remove

    ////////////////

    function index(params) {
      return $http({
        url: "/api/events",
        params: params,
        method: "GET"
      })
    }

    function show(id) {
      return $http.get("/api/events/" + id)
    }

    function create(model) {
      return $http.post("/api/events", model)
    }

    function update(model) {
      return $http.put("/api/events/" + model._id, model)
    }

    function remove(id) {
      return $http.delete("/api/events/" + id)
    }
  }
})()
