import _ from "lodash"
/**
 * Created by daton on 10/10/16.
 */
;(function() {
  "use strict"

  angular
    .module("teacher")
    .controller("StudentProgramController", StudentProgramController)

  function StudentProgramController(
    $log,
    $timeout,
    $mdToast,
    $stateParams,
    $teaching,
    $scope,
    $state,
    Upload,
    util
  ) {
    var vm = this
    vm.download = download

    vm.back = back

    vm.teaching = {}
    vm.error = ""

    var q, deferredProgram

    if ($stateParams._id) {
      q = $teaching.getById($stateParams._id)
    }
    if ($stateParams.codice) {
      q = $teaching.getByCode($stateParams.codice)
    }

    if (!q) return $state.go("triangular.student-study-plan")

    q.then(
      function(res) {
        if (res.data.programma.stato === "Pubblico") {
          vm.teaching = res.data
        } else {
          vm.teaching = _.omit(res.data, "programma")
          util.toast(
            "Il programma di questo insegnamento non è ancora stato pubblicato!"
          )
        }
      },
      function(xhr) {
        $log.error("xhr => ", xhr)
        vm.error = {
          message: util.findMessage(xhr)
        }
        util.errorMessage = ""
      }
    )

    function download() {
      window.location.assign(
        "/api/teachings/program/" + vm.teaching.programma.file._id
      )
    }

    function back() {
      window.history.back()
    }
  }
})()
