(function() {
  "use strict"

  angular
    .module("teacher.exam")
    .controller("TeacherExamSummaryController", TeacherExamSummaryController)

  /* @ngInject */
  function TeacherExamSummaryController($log, ExamService) {
    var vm = this

    ////////////////
  }
})()
