export default class TeacherTeachingsBoardController {
  author = "TEACHER"
  teachings = [] // fetch teachings by teacher
  teaching = "tutti"
  message = ""
  posts = []
  user = {}
  tinymceOptions = {
    plugins: "link image code",
    toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code"
  }
  /* @ngInject */
  constructor(BoardService, $teaching, $user, $sce) {
    this.BoardService = BoardService
    this.$teaching = $teaching
    this.$sce = $sce
    this.user = $user.current
    this.fetchTeachings()
  }
  fetchTeachings() {
    this.$teaching
      .query(false, {
        idDocente: this.user._id,
        stato: "Attivo"
      })
      .then(({ data }) => {
        this.teachings = data
        this.fetchData()
      })
      .catch(err => console.error(err))
  }
  fetchData() {
    const params = {}
    if (this.teaching === "tutti") {
      params.teachings = [...this.teachings.map(({ _id }) => _id)]
    } else {
      params.teachings = [this.teaching]
    }
    this.BoardService
      .getByTeachings(params)
      .then(({ data }) => {
        this.posts = data
      })
      .catch(err => {
        console.error(err)
      })
  }
  submit(message) {
    if (!confirm("Confermi di voler pubblicare l'avviso?")) return null
    const payload = {
      messages: [message],
      author: this.author,
      userId: this.user._id,
      username: this.user.username,
      teachings: [this.teaching]
    }
    if (this.teaching === "tutti") {
      payload.teachings = [...this.teachings.map(({ _id }) => _id)]
    }
    this.BoardService
      .create(payload)
      .then(({ data }) => {
        this.message = ""
        this.posts.push(data)
      })
      .catch(err => {
        console.error(err)
      })
  }
  userPosition(post) {
    return post.userId === this.user._id
      ? "user-right current-user"
      : "user-left"
  }
  userColor(post) {
    return post.userId === this.user._id ? "light-green" : "cyan"
  }
  userAvatar(post) {
    return post.userId === this.user._id
      ? "assets/images/avatars/avatar-3.png"
      : "assets/images/avatars/avatar-6.png"
  }
  trustMessage(message) {
    return this.$sce.trustAsHtml(message)
  }
  selectType(teaching) {
    this.teaching = teaching
    this.fetchData()
  }
  getTeachingName(id) {
    const { nome, codice } = this.teachings.find(({ _id }) => _id === id)
    return `${nome} - ${codice}`
  }
}
