/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 21/10/16 11:12.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: mail.controller
 * Created with: WebStorm
 */

(function () {
    'use strict'

    angular.module('mail')
        .controller('MailController', MailController)

    function MailController() {



    }

})()
