import moment from 'moment'

(function () {
    'use strict'

    angular
        .module('secretary.event')
        .controller('SecretaryEventCalendarController', SecretaryEventCalendarController)

    /* @ngInject */
    function SecretaryEventCalendarController($scope, $rootScope, $mdDialog, $mdToast, $filter, $element, triTheming, $cookies, triLayout, uiCalendarConfig, ExamService, $lesson, $log, $teaching, $classRoom) {

        var vm = this

        vm.addEvent = addEvent

        var defaultDate = angular.isDefined($cookies.get('current-day')) ? moment($cookies.get('current-day'), 'YYYY-MM-DD') : moment()
        var defaultView = $cookies.get('calViewType') || 'agendaWeek'

        vm.viewFormats = {
            'month': 'DD MMMM YYYY',
            'agendaWeek': 'w - D MMMM YY',
            'agendaDay': 'Do MMMM YYYY'
        }

        vm.eventSources = [{
            // events: events,
            url: '/api/events',
            data: {}
        }]

        vm.calendarOptions = {
            contentHeight: 'auto',
            selectable: true,
            editable: true,
            header: false,
            lang: 'it',
            timezone: 'local',
            minTime: '07:30:00',
            maxTime: '20:00:00',
            hiddenDays: [0],
            defaultDate: defaultDate,
            defaultView: defaultView,
            timeFormat: 'HH:mm',
            viewRender: viewRender,
            dayClick: dayClick,
            select: select,
            eventClick: eventClick
        }

        function viewRender(view) {
            vm.view = view

            $cookies.put('calViewType', view.type)

            vm.currentDay = view.calendar.getDate()
            $cookies.put('current-day', vm.currentDay.format(''))
            // console.log('current-day', vm.currentDay.format(''));

            vm.currentView = view.name
            // update toolbar with new day for month name
            $rootScope.$broadcast('calendar-changeday', vm.currentDay)
            // update background image for month
            triLayout.layout.contentClass = 'calendar-background-image background-overlay-static overlay-gradient-10 calendar-background-month-' + vm.currentDay.month()
        }

        function dayClick(date, jsEvent, view) { //eslint-disable-line
            vm.currentDay = date
            $cookies.put('current-day', date.format())
            $log.log('current-day', date.format())
            $rootScope.$broadcast('dayClicked', date)
        }

        function select(start, end) {
            vm.currentDayStart = start
            vm.currentDayEnd = end
        }

        function eventClick(calEvent, jsEvent, view) { //eslint-disable-line

            $mdDialog.show({
                controller: 'SecretaryEventDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/ssml/secretary/events/event-dialog.tmpl.html',
                targetEvent: jsEvent,
                clickOutsideToClose: true,
                locals: {
                    dialogData: {
                        title: 'Aggiorna',
                        confirmButtonText: 'Salva'
                    },
                    event: calEvent,
                    edit: true
                }
            })
                .then(onHide)

            function onHide(event) {

                uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('refetchEvents')

            }

        }

        function addEvent(event, $event) {

            var start = vm.currentDay.minute(0)
            var end = moment(vm.currentDay).add(2, 'h')

            if (vm.currentDayStart) start = vm.currentDayStart
            if (vm.currentDayEnd) end = vm.currentDayEnd

            $mdDialog.show({
                controller: 'SecretaryEventDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/ssml/secretary/events/event-dialog.tmpl.html',
                targetEvent: $event,
                focusOnOpen: false,
                locals: {
                    dialogData: {
                        title: 'Aggiungi evento',
                        confirmButtonText: 'Crea'
                    },
                    event: {
                        title: $filter('triTranslate')('Nuovo evento'),
                        allDay: false,
                        start: start,
                        end: end,
                        palette: 'cyan',
                        stick: true
                    },
                    edit: false
                }
            })
                .then(function (event) {

                    uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('refetchEvents')

                })
        }

        // listeners

        $scope.$on('addEvent', addEvent)

    }
})()
