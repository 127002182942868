import _ from "lodash"
/**
 * Created by daton on 16/10/16.
 */
;(function() {
  "use strict"

  angular
    .module("secretary.constraints")
    .controller("ConstraintsController", ConstraintsController)

  angular.module("secretary.constraints").filter("nameTeaching", function() {
    return function(item) {
      return item
        .map(function(i) {
          return i.nome
        })
        .join(", ")
    }
  })

  function ConstraintsController(
    $scope,
    $log,
    $mdDialog,
    $mdToast,
    $teaching,
    $address,
    $teacher,
    $constraint
  ) {
    var vm = this
    vm.add = add

    // TODO VALIDATE FORM

    var exec = true // force promise to resolve internally to factory

    var teachings = $teaching.collection,
      constraints = $constraint.collection

    $teaching.query(exec)
    $constraint.query(exec)

    // var el = angular.element('.triangular.content');
    //
    // console.log('log: el => ', el);

    vm.gridOptions = {
      enableRowSelection: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: false,
      minRowsToShow: 17,
      data: constraints
    }

    vm.gridOptions.columnDefs = [
      { name: "idInsegnamento.nome" },
      {
        name: "constraints",
        cellTemplate:
          '<div class="ui-grid-cell-contents" >{{grid.getCellValue(row, col) | nameTeaching }}</div>'
      }
    ]

    vm.gridOptions.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
      gridApi.selection.on.rowSelectionChanged(null, function(row, event) {
        editConstraint(row.entity, event, true)
      })
    }

    function editConstraint(entity, event, edit) {
      $mdDialog
        .show({
          controller: "ConstraintDialogController",
          controllerAs: "vm",
          templateUrl:
            "app/ssml/secretary/constraints/constraint-dialog.tmpl.html",
          targetEvent: event,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: (edit ? "Modifica" : "Inserisci") + " Vincolo",
              confirmButtonText: edit ? "Salva" : "Crea"
            },
            constraint: _.clone(entity),
            teachings: teachings, // bind model to dialog
            edit: edit
          }
        })
        .then(function(constraint) {
          if (
            angular.isDefined(constraint.deleted) &&
            constraint.deleted === true
          ) {
            _.remove(vm.gridOptions.data, constraint)
          }
          if (
            angular.isDefined(constraint.saved) &&
            constraint.saved === true
          ) {
            $constraint.query(exec)
          }
        })
    }

    function add(event, $event) {
      editConstraint({}, $event)
    }

    $scope.$on("addConstraint", add)

    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }
  }
})()
