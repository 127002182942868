(function() {
    'use strict'

    angular.module('secretary.admission')
        .config(AdmissionConfig)

    /* @ngInject */
    function AdmissionConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.admissions', {
                url: '/secretary/admissions',
                templateUrl: 'app/ssml/secretary/admission/admissions.tmpl.html',
                // set the controller to load for this page
                controller: 'AdmissionsController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        // var menu = triMenuProvider.getMenu('secretary');

        // if (menu && menu.children instanceof Array) {

        //     menu.children.push({
        //         name: 'Domande ammissione',
        //         state: 'triangular.admissions',
        //         type: 'link'
        //     });
        // }

        triMenuProvider.addMenu({
            id: 'secretary-admissions',
            name: 'Domande ammissione',
            icon: 'zmdi zmdi-file-text',
            permission: 'SECRETARY',
            type: 'link',
            priority: 1.1,
            state: 'triangular.admissions'
        })

    }
})()