import layout from "./layouts/authentication.tmpl.html"
import login from './login/login.tmpl.html'
import LoginController from "./login/login.controller"

/* @ngInject */
export default function moduleConfig($stateProvider, triMenuProvider) {
    $stateProvider
        .state("authentication", {
            abstract: true,
            views: {
                root: {
                    template: layout
                }
            },
            data: {
                permissions: {
                    only: ["viewAuthentication"]
                }
            }
        })
        .state("authentication.login", {
            url: "/login",
            template: login,
            controller: LoginController,
            controllerAs: "vm"
        })
    // .state("authentication.reset-password", {
    //     url: "/reset-password/:token",
    //     templateUrl: "app/authentication/reset/reset-password.tmpl.html",
    //     controller: "ResetPasswordController",
    //     controllerAs: "vm",
    //     params: {
    //         token: null
    //     }
    // })
    // .state("authentication.forgot", {
    //     url: "/forgot",
    //     templateUrl: "app/authentication/forgot/forgot.tmpl.html",
    //     controller: "ForgotController",
    //     controllerAs: "vm"
    // });
    // .state('triangular.profile', {
    //     url: '/profile',
    //     templateUrl: 'app/authentication/profile/profile.tmpl.html',
    //     controller: 'ProfileController',
    //     controllerAs: 'vm'
    // });

    // triMenuProvider.addMenu({
    //     name: 'Authentication',
    //     icon: 'zmdi zmdi-account',
    //     type: 'dropdown',
    //     priority: 4.1,
    //     permission: 'viewAuthentication',
    //     children: [{
    //         name: 'Login',
    //         state: 'authentication.login',
    //         type: 'link'
    //     },{
    //         name: 'Sign Up',
    //         state: 'authentication.signup',
    //         type: 'link'
    //     },{
    //         name: 'Forgot Password',
    //         state: 'authentication.forgot',
    //         type: 'link'
    //     },{
    //         name: 'Lock Page',
    //         state: 'authentication.lock',
    //         type: 'link'
    //     },{
    //         name: 'Profile',
    //         state: 'triangular.profile',
    //         type: 'link'
    //     }]
    // });
}
