import "./teacher.module"

import "./calendar/calendar.module"
import "./calendar/calendar.config"
import "./calendar/calendar.controller"
import "./calendar/event-dialog.controller"
import "./calendar/layouts/toolbar/toolbar.controller"

import "./exam/exam.module"
import "./exam/exam.config"
import "./exam/exam.controller"
import "./exam/exam-creation.controller"
import "./exam/exam-dialog.controller"
import "./exam/exam-summary.controller"

import "./material/material.module"
import "./material/material.config"
import "./material/material.controller"
import "./material/material-dialog.controller"

import "./program/program.module"
import "./program/program.config"
import "./program/program.controller"

import "./teaching/teaching.module"
import "./teaching/teaching.config"
import "./teaching/teaching.controller"
import "./teaching/teachings.controller"

import "./board/board.module"
