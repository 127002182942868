/* @ngInject */
export default function translateConfig(triSettingsProvider, triRouteProvider) {
  var now = new Date()
  // set app name & logo (used in loader, sidemenu, footer, login pages, etc)
  triSettingsProvider.setName("ISCollege")
  triSettingsProvider.setCopyright(
    "&copy;" + now.getFullYear() + " iscollege.it"
  )
  triSettingsProvider.setLogo("assets/images/logo.png")
  // set current version of app (shown in footer)
  triSettingsProvider.setVersion("2.0.1")
  // set the document title that appears on the browser tab
  triRouteProvider.setTitle("SSML Platform")
  triRouteProvider.setSeparator("|")
}
