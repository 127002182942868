// Import TinyMCE
import tinymce from "tinymce/tinymce"

// A theme is also required
import "tinymce/themes/silver/theme"

// Any plugins you want to use has to be imported
import "tinymce/plugins/paste"
import "tinymce/plugins/link"
import "tinymce/plugins/image"
import "tinymce/plugins/code"
import "tinymce/plugins/advlist"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/charmap"
import "tinymce/plugins/print"
import "tinymce/plugins/preview"
import "tinymce/plugins/wordcount"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/table"
import "tinymce/plugins/colorpicker"
import "tinymce/plugins/textcolor"

// SKINS
import "tinymce/skins/ui/oxide/skin.min.css"
import "tinymce/skins/ui/oxide/content.min.css"
import "tinymce/skins/content/default/content.css"

// Initialize the app
// tinymce.init({
//   selector: "#tiny",
//   plugins: ["paste", "link", "image"]
// })

export default tinymce
