import moment from 'moment'
import _ from 'lodash'

(function () {
    'use strict'

    angular
        .module('secretary.teaching')
        .controller('TeachingDialogController', TeachingDialogController)

    /* @ngInject */
    function TeachingDialogController($mdDialog, dialogData, $lesson, $log, $mdToast, languages, teaching, $teaching, ExamService, $window, util, addresses, teachers, edit) {

        var vm = this

        // var lessons = [];
        // var exams = [];

        vm.teaching = teaching
        vm.teachings = []
        vm.teachers = teachers
        vm.addresses = addresses
        vm.languages = languages

        vm.dialogData = dialogData
        vm.edit = edit
        vm.error = ''

        vm.cancelClick = cancelClick
        vm.deleteClick = deleteClick
        vm.okClick = okClick
        vm.getHoursAssignedToTeaching = getHoursAssignedToTeaching
        vm.downloadProgram = downloadProgram

        vm.tinymceOptions = {
            plugins: 'link image code',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
        }

        vm.gridOptions = {
            enableRowSelection: false,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: false,
            showGridFooter: true,
            enableColumnResize: true,
            // multiSelect: false,
            // minRowsToShow: 17,
            data: $lesson.collection,
            exporterFieldCallback: function (grid, row, col, value) {
                if (col.name === 'start') {
                    value = moment(value).format('L')
                }
                return value
            },
            exporterCsvFilename: 'lezioni ' + vm.teaching.nome + '.csv'
        }

        vm.gridOptions.columnDefs = [
            { name: 'idInsegnamento.nome', displayName: 'Insegnamento', width: '40%' },
            { name: 'idInsegnamento.nomeDocente', displayName: 'Docente', width: '*' },
            { name: 'start', displayName: 'Giorno', width: 120, type: 'date', cellFilter: 'date:\'dd-MM-yyyy\'', sort: { direction: 'asc', priority: 0 } },
            { name: 'inizio', width: 90 },
            { name: 'fine', width: 90 },
            { name: 'ore', width: 60 }
        ]

        vm.gridOptionsExams = {
            enableRowSelection: false,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: true,
            showGridFooter: true,
            enableColumnResize: true,
            // multiSelect: false,
            // minRowsToShow: 17,
            data: ExamService.collection,
            exporterFieldCallback: function (grid, row, col, value) {
                if (col.name === 'start') {
                    value = moment(value).format('L')
                }
                return value
            },
            exporterCsvFilename: ('esami ' + vm.teaching.nome + '.csv').replace(' ', '_')
        }

        vm.gridOptionsExams.columnDefs = [
            {
                name: 'insegnamento.nome',
                displayName: 'Insegnamento'
            },
            {
                name: 'insegnamento.nomeDocente',
                displayName: 'Docente'
            },
            {
                name: 'stato'
            },
            {
                name: 'tipo'
            },
            {
                name: 'numero',
                displayName: 'Apello'
            },
            { name: 'start', displayName: 'Giorno', width: 120, type: 'date', cellFilter: 'date:\'dd-MM-yyyy\'', sort: { direction: 'asc', priority: 0 } },
            {
                name: 'inizio',
                width: 90
            }
        ]

        $teaching.getById(teaching._id)
            .then(function success(res) {
                // $log.log('vm.teaching =>', vm.teaching);
                vm.teaching = res.data


                if (teaching._id) {

                    $lesson.query({ teachingId: vm.teaching._id }, true)

                    ExamService.showByTeaching(vm.teaching._id)
                        .then(function (res) {
                            vm.gridOptionsExams.data = res.data
                            $log.log('esami >', res.data)
                        })

                }
            }, function error(res) {
                $log.error(res)
            })

        $teaching.query(false, {
            tipologia: 'Sintetico',
            stato: 'Attivo'
        })
            .then(function (res) {
                vm.teachings = res.data
                // vm.teachings = _.filter(res.data, { tipologia: 'Sintetico', stato: 'Attivo' });
            }, function (xhr) {
                $log.error(xhr)
                vm.error = util.findMessage(xhr)
                util.errorMessage = ''
                toast('Lista insegnamenti non disponibile!')
            })

        ////////////////////////

        function getHoursAssignedToTeaching() {

            $teaching.getHoursAssignedToTeaching({
                teachingId: vm.teaching._id
            })
                .then(function (res) {

                    $log.log('res.id =>', res.data[0])
                    $log.log('teaching.id =>', vm.teaching._id)

                    if (res.data[0].oreTotaliAssegnate) {

                        var oreTotaliAssegnate = res.data[0].oreTotaliAssegnate

                        if (confirm('Nel calendario sono state assegnate ' + oreTotaliAssegnate + '\n Vuoi aggiornare le ore assegnate e da ultimare?')) {

                            $teaching.updateHours({
                                _id: vm.teaching._id,
                                oreDaUltimare: vm.teaching.oreLezione - oreTotaliAssegnate,
                                oreAssegnate: oreTotaliAssegnate
                            })
                                .then(function (res) {
                                    alert('Ore aggiornate correttamente!')
                                    vm.teaching = res.data
                                    vm.teaching.saved = true
                                    $mdDialog.hide(vm.teaching)
                                }, function () {
                                    alert('Problema di comunicazione... Contattare l\'amministratore')
                                })

                        }

                    } else {
                        alert('Problema di comunicazione... Contattare l\'amministratore')
                    }

                }, function (res) {

                    if (res.status === 404) {

                        if (confirm('Nessuna lezione trovata per questo insegnamento!\n Vuoi resettare le ore assegnate e da ultimare?')) {
                            $teaching.updateHours({
                                _id: vm.teaching._id,
                                oreDaUltimare: vm.teaching.oreLezione,
                                oreAssegnate: 0
                            })
                                .then(function (res) {
                                    alert('Ore aggiornate correttamente!')
                                    vm.teaching = res.data
                                    vm.teaching.saved = true
                                    $mdDialog.hide(vm.teaching)
                                }, function () {
                                    alert('Problema di comunicazione... Contattare l\'amministratore')
                                })
                        }
                    }

                })

        }

        function okClick() {

            vm.error = ''

            if (
                _.isEqual(vm.teaching.lingua, 'Seleziona lingua') ||
                _.isEmpty(vm.teaching.lingua)
            ) vm.teaching.lingua = {}

            if (vm.teaching.idDocente) {
                vm.teaching.nomeDocente = vm.teachers
                    .filter(function (t) {
                        return t._id === vm.teaching.idDocente
                    })
                    .map(function (t) {
                        return t.nomeCompleto
                    })
            } else {
                vm.teaching.nomeDocente = ''
            }
            $log.log('vm.teaching => ', vm.teaching)
            // save teaching
            var deferred = edit ? $teaching.save(vm.teaching) : $teaching.create(vm.teaching)

            deferred
                .then(function (teaching) {
                    teaching.saved = true
                    $mdDialog.hide(teaching)
                    toast('Insegnamento aggiornato!')
                }, function (err) {
                    $log.error(err)
                    vm.error = util.findMessage(err)
                    util.errorMessage = ''
                    toast('Impossibile aggiornare insegnamento!')
                })

        }

        function cancelClick() {
            $mdDialog.cancel()
        }

        function deleteClick($event) {

            if (confirm('Sei sicuro? Questa azione è irreversibile!')) {
                vm.error = ''
                // delete teaching
                $teaching.remove(vm.teaching)
                    .then(function () {
                        vm.teaching.deleted = true

                        $mdDialog.hide(vm.teaching)

                        toast('Insegnamento Eliminato')
                    }, function (err) {
                        $log.error('err =>', err)
                        vm.error = util.findMessage(err)
                        util.errorMessage = ''
                        toast('Impossibile Eliminare Insegnamento')
                    })

            }

        }

        function downloadProgram() {
            if (vm.teaching.programma.tipo === 'Manuale') {

                // pdfMake.createPdf({ content: 'This is an sample PDF printed with pdfMake' }).open();

                $window.open('/api/teachings/program-manual/' + vm.teaching._id + '?v=' + Date.now(), '_blank').focus()

            } else {

                if (!vm.teaching.programma._id) return toast('Impossibile trovare file')
                $window.open('/api/teachings/program/' + vm.teaching.programma.file._id + '?v=' + Date.now(), '_blank').focus()

            }
        }

        function toast(toastMessage) {
            // pop a toast
            $mdToast.show(
                $mdToast.simple()
                    .content(toastMessage)
                    .position('bottom right')
                    .hideDelay(2000)
            )
        }

    }
})()
