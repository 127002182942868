/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 11/10/16 16:36.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: teaching.config
 * Created with: WebStorm
 */

 import teachings from './teachings.tmpl.html'
 import teachingFab from './teaching-fabs.tmpl.html'
 import teachingsCtrl from './teachings.controller'

(function () {
    'use strict'

    angular.module('secretary.teaching')
        .config(TeachingsConfig)

    function TeachingsConfig($stateProvider, triMenuProvider) {

        // $translatePartialLoaderProvider.addPart('app/secretary');

        $stateProvider
            .state('triangular.secretary-teachings', {
                url: '/secretary/teachings',

                views: {
                    '@triangular': {
                        template: teachings,
                        // set the controller to load for this page
                        controller: teachingsCtrl,
                        controllerAs: 'vm'
                    },
                    'belowContent@triangular': {
                        template: teachingFab,
                        controller: 'TeachingFabController',
                        controllerAs: 'vm'
                    }
                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.secretary-teaching', {
                url: '/secretary/teachings/:_id',
                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/teaching/teaching.tmpl.html',
                        // set the controller to load for this page
                        controller: 'SecretaryTeachingController',
                        controllerAs: 'vm'
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/teaching/teaching-save-fab.tmpl.html',
                        controller: 'TeachingSaveFabController',
                        controllerAs: 'vm'
                    }
                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        // var menu = triMenuProvider.getMenu('secretary');
        //
        // if (menu && menu.children instanceof Array) {
        //
        //     menu.children.push({
        //         name: 'Insegnamenti',
        //         state: 'triangular.secretary-teachings',
        //         type: 'link'
        //     });
        // }

        triMenuProvider.addMenu({
            id: 'insegnamenti',
            name: 'Insegnamenti',
            icon: 'fa fa-graduation-cap',
            permission: 'SECRETARY',
            state: 'triangular.secretary-teachings',
            type: 'link',
            priority: 1.1
        })

    }

})()
