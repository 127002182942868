import moment from "moment"
import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.session")
    .controller("SessionDialogController", SessionDialogController)

  /* @ngInject */
  function SessionDialogController(
    $mdDialog,
    $log,
    $mdToast,
    util,
    SessionFactory,
    dialogData,
    entity,
    edit
  ) {
    var vm = this
    vm.cancelClick = cancelClick
    vm.deleteClick = deleteClick
    vm.dialogData = dialogData
    vm.edit = edit
    vm.entity = entity
    vm.okClick = okClick
    vm.error = ""

    // create start and end date of lesson
    if (entity.inizio && typeof entity.inizio == "string") {
      var inizio = moment(entity.inizio)
      vm.start = inizio.toDate()
      vm.startTime = convertMomentToTime(inizio)
    }
    if (entity.fine && typeof entity.fine == "string") {
      var fine = moment(entity.fine)
      vm.end = fine.toDate()
      vm.endTime = convertMomentToTime(fine)
    }
    ////////////////

    function okClick() {
      vm.error = ""

      vm.entity.edit = edit

      vm.entity.inizio = updateEventDateTime(vm.start, vm.startTime)

      vm.entity.fine = updateEventDateTime(vm.end, vm.endTime)

      SessionFactory[edit ? "update" : "create"](vm.entity).then(function then(
        xhr
      ) {
        if (xhr.status.toString()[0] == 2) {
          toast("Sessione salvata!")
          $mdDialog.hide(xhr.data)
        } else {
          $log.error(xhr.data)
          vm.error = util.findMessage(xhr.data)
          util.errorMessage = ""
          toast("Impossibile aggiornare sessione!")
        }
      })
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function deleteClick() {
      vm.error = ""

      vm.entity.deleteMe = true

      if (
        !confirm(
          "Sei sicuro di voler eliminare questa sessione? L'azione è irreversibile!"
        )
      )
        return

      SessionFactory.destroy(vm.entity).then(function then(xhr) {
        if (xhr.status === 204) {
          toast("Sessione eliminata!")
          $mdDialog.hide(vm.entity)
        } else {
          $log.error(xhr)
          vm.error = util.findMessage(xhr.data)
          util.errorMessage = ""
          toast("Impossibile eliminare Sessione!")
        }
      })
    }

    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }

    function convertMomentToTime(moment) {
      return {
        hour: moment.hour(),
        minute: moment.minute()
      }
    }

    function updateEventDateTime(date, time) {
      var newDate = moment(date)
      newDate.hour(time.hour)
      newDate.minute(time.minute)
      return newDate
    }

    function createDateSelectOptions() {
      // create options for time select boxes (this will be removed in favor of mdDatetime picker when it becomes available)
      vm.dateSelectOptions = {
        hours: [],
        minutes: []
      }
      // hours
      for (var hour = 0; hour <= 23; hour++) {
        vm.dateSelectOptions.hours.push(hour)
      }
      // minutes
      for (var minute = 0; minute <= 59; minute++) {
        if (minute % 5 == 0) vm.dateSelectOptions.minutes.push(minute)
      }
    }

    // init
    createDateSelectOptions()
  }
})()
