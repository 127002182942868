(function () {
    'use strict'

    angular
        .module('secretary.exam')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.secretary-exam-calendar', {
                url: '/secretary-exam-calendar',

                // set the url of this page
                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/exams/exams.tmpl.html',
                        controller: 'SecretaryExamCalendarController',
                        controllerAs: 'vm'
                    },
                    'toolbar@triangular': {
                        templateUrl: 'app/ssml/secretary/exams/layouts/toolbar/toolbar.tmpl.html',
                        controller: 'ExamCalendarToolbarController',
                        controllerAs: 'vm',
                        resolve: {
                            teachers: ['$teacher', function ($teacher) {

                                return $teacher.query(true)
                                    .then(function (res) {
                                        return res
                                    })

                            }]
                        }
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/calendar/calendar-fabs.tmpl.html',
                        controller: 'CalendarFabController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        contentClass: 'triangular-non-scrolling layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })
            .state('triangular.secretary-exams-list', {
                url: '/secretary/exams-list',
                templateUrl: 'app/ssml/secretary/exams/exams-list.tmpl.html',
                controller: 'SecretaryExamListCalendarController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'triangular-non-scrolling layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            name: 'Esami',
            icon: 'fa fa-road',
            type: 'dropdown',
            children: [{
                name: 'Calendario',
                icon: 'zmdi zmdi-calendar-alt',
                state: 'triangular.secretary-exam-calendar',
                type: 'link'
            }, {
                name: 'Lista',
                icon: 'fa fa-list',
                state: 'triangular.secretary-exams-list',
                type: 'link'
            }],
            priority: 2.4,
            permission: 'SECRETARY'
        })
    }
})()
