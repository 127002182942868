(function() {
  "use strict"

  angular.module("teacher", [
    "teacher.calendar",
    "teacher.teaching",
    "teacher.program",
    "teacher.material",
    "teacher.exam",
    "teacher.board"
  ])
})()
