(function () {
    'use strict'

    angular
        .module('secretary.exam')
        .controller('SecretaryExamListCalendarController', SecretaryExamListCalendarController)

    /* @ngInject */
    function SecretaryExamListCalendarController(ExamService, $log, $state) {

        var vm = this

        vm.gridOptions = {
            onRegisterApi: onRegisterApi,
            enableRowSelection: true,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            minRowsToShow: 18,
            enableFiltering: true,
            showGridFooter: true,
            multiSelect: false
        }

        vm.gridOptions.columnDefs = [
            { name: 'insegnamento.nome', displayName: 'Insegnamento', width: '40%' },
            { name: 'sessione.nome', displayName: 'Sessione' },
            { name: 'numero', displayName: 'Appello' },
            { name: 'stato' },
            { name: 'start', displayName: 'Inizio' }
        ]

        function onRegisterApi(gridApi) {
            //set gridApi on scope
            vm.gridApi = gridApi
            gridApi.selection.on.rowSelectionChanged(null, function (row, event) {
                $state.go('triangular.teacher-exam', { _id: row.entity._id })
            })
        }

        ExamService.index()
            .then(function (res) {
                $log.log(res.data)
                vm.gridOptions.data = res.data
            }, function (err) {
                $log.error('err =>', err)
            })

    }
})()
