import moment from "moment"
import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("teacher.calendar")
    .controller("TeacherEventDialogController", TeacherEventDialogController)

  /* @ngInject */
  function TeacherEventDialogController(
    $scope,
    $log,
    util,
    $mdDialog,
    $mdColors,
    $user,
    $teacher,
    ExamRegistrationService,
    ExamService,
    $state,
    $mdToast,
    dialogData,
    event,
    edit
  ) {
    var vm = this

    vm.user = $user.current
    vm.event = event

    vm.edit = edit

    vm.dialogData = dialogData
    vm.dialogData.confirmButtonText = "chiudi"
    vm.registrationExpired = false
    vm.confirmCommissione = false
    vm.examRegistrations = {}

    var rowTmpl =
      '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'

    vm.gridOptions = {
      rowHeight: 35,
      showGridFooter: true,
      multiSelect: false,
      rowTemplate: rowTmpl,
      data: []
    }

    vm.gridOptions.columnDefs = [
      {
        name: "studente.nomeCompleto",
        displayName: "Studente"
      },
      {
        name: "studente.matricola",
        displayName: "Matricola"
      }
    ]

    // create start and end date of lesson
    vm.start = event.start.toDate()
    vm.startTime = convertMomentToTime(event.start)

    if (event.end !== null) {
      vm.end = event.end.toDate()
      vm.endTime = convertMomentToTime(event.end)
    }

    ////////////////

    vm.cancelClick = cancelClick
    vm.okClick = okClick

    ////////////////

    function init() {
      if (!vm.event.commissione || !vm.event.commissione.length) {
        vm.event.commissione = []
      }

      $teacher
        .getById(vm.event.insegnamento.idDocente)
        .then(function(res) {
          _.remove(vm.event.insegnamento.commissione, {
            _id: vm.event.insegnamento.idDocente
          })
          vm.event.insegnamento.commissione.push(res.data)
          _.remove(vm.event.commissione, {
            _id: vm.event.insegnamento.idDocente
          })
          vm.event.commissione.push(res.data)
          return $teacher.getById("56d483b4d9a8ad9a328f0e47")
        })
        .then(function(res) {
          _.remove(vm.event.insegnamento.commissione, {
            _id: "56d483b4d9a8ad9a328f0e47"
          })
          vm.event.insegnamento.commissione.push(res.data)
          _.remove(vm.event.commissione, { _id: "56d483b4d9a8ad9a328f0e47" })
          vm.event.commissione.push(res.data)
        })
        .catch(function(err) {
          throw err
        })

      // vm.event.commissione.push()
      // console.log('vm.event.insegnamento =>', vm.event.insegnamento);
    }

    // BUTTONS HANDLERS START

    function okClick() {
      $mdDialog.hide()
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    // BUTTONS HANDLERS END

    // DATE HANDLERS START

    function convertMomentToTime(moment) {
      return {
        hour: moment.hour(),
        minute: moment.minute()
      }
    }

    function createDateSelectOptions() {
      // create options for time select boxes (this will be removed in favor of mdDatetime picker when it becomes available)
      vm.dateSelectOptions = {
        hours: [],
        minutes: []
      }
      // hours
      for (var hour = 0; hour <= 23; hour++) {
        vm.dateSelectOptions.hours.push(hour)
      }
      // minutes
      for (var minute = 0; minute <= 59; minute++) {
        vm.dateSelectOptions.minutes.push(minute)
      }
    }

    function goToExam() {
      $state.go("triangular.teacher-exam", vm.event)
      okClick()
    }

    function confirmCommissione() {
      vm.confirmCommissione = true
      vm.dialogData.confirmButtonText = "Continua"
      vm.okClick = startExam
    }

    function startExam() {
      if (vm.event.commissione && vm.event.commissione.length < 2) {
        var message =
          "La commissione deve essere composta da almeno due docenti"
        util.toast(message)
        throw new Error(message)
      }

      ExamService.start(vm.event).then(
        function(res) {
          if (res.status === 200) {
            goToExam()
          }
        },
        function(xhr) {
          util.toast(xhr.data.message || "Impossibile avviare esame.")
          $log.error(xhr)
        }
      )
    }

    // DATE HANDLERS END

    // init
    createDateSelectOptions()

    if (vm.event.tipo === "Esame") {
      if (moment(vm.event.chiusuraIscrizioni).isBefore(moment())) {
        vm.registrationExpired = true
      }

      var examIsToday = moment(vm.event.start).isSame(new Date(), "day") // production
      // var examIsToday = moment(vm.event.start).isSame(moment(vm.event.start), 'day'); // development

      // $log.log('examIsToday =>', examIsToday);

      if (examIsToday) {
        vm.dialogData.confirmButtonText = "Avvia esame"
        vm.okClick = confirmCommissione
      }

      if (vm.event.stato === "In corso") {
        vm.dialogData.confirmButtonText = "Continua esame"
        vm.okClick = goToExam
      }

      ExamRegistrationService.showByTeaching({
        esame: vm.event._id
      }).then(function(reg) {
        // console.log('reg.data =>', reg.data);
        vm.gridOptions.data = reg.data
      })
    } else {
      // TODO: gestire lezione per docente
    }

    init()
  }
})()
