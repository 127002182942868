(function() {
  "use strict"

  angular.module("secretary.address").config(moduleConfig)

  /* @ngInject */
  function moduleConfig(
    $stateProvider,
    triMenuProvider,
    $translatePartialLoaderProvider
  ) {
    // $translatePartialLoaderProvider.addPart('app/secretary');

    // TODO: add insert address
    $stateProvider.state("triangular.secretary-addresses", {
      url: "/secretary/addresses",
      templateUrl: "app/ssml/secretary/address/addresses.tmpl.html",
      // set the controller to load for this page
      controller: "AddressesController",
      controllerAs: "vm",
      // layout-column class added to make footer move to
      // bottom of the page on short pages
      data: {
        layout: {
          contentClass: "layout-column"
        },
        permissions: {
          only: ["SECRETARY"],
          redirect: "authentication.login"
        }
      }
    })

    triMenuProvider.addMenu({
      id: "secretary-addresses",
      name: "Indirizzi",
      icon: "fa fa-asterisk",
      permission: "SECRETARY",
      type: "link",
      state: "triangular.secretary-addresses",
      priority: 1.1
    })
  }
})()
