(function () {
    'use strict'

    angular.module('ssml', [
        'ssml.services',
        'secretary',
        'student',
        'teacher',
        'common'
    ])
})()