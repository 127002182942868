import "./student.module"

import "./calendar/calendar.module"
import "./calendar/calendar.config"
import "./calendar/calendar.controller"
import "./calendar/event-dialog.controller"
import "./calendar/layouts/toolbar/toolbar.controller"

import "./study-plan/study-plan.module"
import "./study-plan/study-plan.config"
import "./study-plan/study-plan.controller"

import "./teaching/teaching.module"
import "./teaching/teaching.config"
import "./teaching/program.controller"
import "./teaching/material.controller"
import "./teaching/teaching.controller"

import "./board/board.module"
