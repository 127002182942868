(function() {
    'use strict'

    angular
        .module('secretary.room')
        .controller('RoomFabController', RoomFabController)

    /* @ngInject */
    function RoomFabController($rootScope) {
        var vm = this
        vm.addRoom = addRoom

        ////////////////

        function addRoom($event) {
            $rootScope.$broadcast('addRoom', $event)
        }
    }
})()
