(function() {
  "use strict"

  angular
    .module("teacher")
    .controller("TeacherMaterialController", TeacherMaterialController)

  /* @nginject */
  function TeacherMaterialController(
    $mdDialog,
    $scope,
    $teaching,
    $stateParams,
    $state,
    $document,
    triBreadcrumbsService,
    $window,
    util
  ) {
    var vm = this
    vm.materials = []
    vm.teaching = {}

    $scope.$on("add", (event, $event) => {
      addMaterial($event)
    })

    triBreadcrumbsService.reset()

    $teaching.getById($stateParams._id).then(
      function(res) {
        vm.teaching = res.data

        vm.materials = res.data.materiale

        triBreadcrumbsService.addCrumb({
          name: "Gestione Materiale"
        })

        triBreadcrumbsService.addCrumb({
          name: vm.teaching.nome
        })
      },
      function(xhr) {
        console.error("xhr => ", xhr)
      }
    )

    vm.addMaterial = addMaterial
    vm.download = download
    vm.back = back
    vm.deleteMaterial = deleteMaterial

    function download(materiale) {
      $window.location.assign("/api/teachings/material/" + materiale._id)
    }

    function back() {
      $state.go("triangular.teacher-teaching", vm.teaching)
    }

    function addMaterial(ev) {
      $mdDialog
        .show({
          controller: "MaterialDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/teacher/material/add-material.tmpl.html",
          parent: angular.element($document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          fullscreen: true, // Only for -xs, -sm breakpoints.
          locals: {
            teaching: vm.teaching
          }
        })
        .then(function(materials) {
          vm.materials = materials
        })
        .catch(() => util.toast("Azione annullata"))
    }

    function deleteMaterial(material) {
      if (confirm("Sei sicuro di voler cancellare questo materiale?")) {
        $teaching.destroyMaterial(material).then(function(res) {
          if (res.status === 200) {
            util.toast("Cancellazione completata!")
            vm.teaching = res.data
            vm.materials = res.data.materiale
          } else {
            util.toast("Cancellazione fallita!")
          }
        })
      }
    }
  }
})()
