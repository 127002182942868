import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.session")
    .controller("SecretarySessionsController", SecretarySessionsController)

  /* @ngInject */
  function SecretarySessionsController(
    SessionFactory,
    $log,
    $mdDialog,
    $mdToast,
    $state,
    $scope
  ) {
    var vm = this

    SessionFactory.index().then(function(xhr) {
      if (xhr.status === 200) {
        vm.gridOptions.data = xhr.data
      } else {
        $log.error("xhr", xhr)
        if (xhr.status === 401) $state.go("authentication.login")
      }
    })

    vm.gridOptions = {
      enableRowSelection: true,
      enableGridMenu: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      minRowsToShow: 18,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: false
    }

    vm.gridOptions.columnDefs = [
      { name: "numero" },
      { name: "nome", width: "*" },
      {
        name: "inizio",
        width: 120,
        type: "date",
        cellFilter: "date:'dd-MM-yyyy'",
        sort: { direction: "asc", priority: 0 }
      },
      {
        name: "fine",
        width: 120,
        type: "date",
        cellFilter: "date:'dd-MM-yyyy'"
      },
      { name: "stato" }
    ]

    vm.gridOptions.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
      gridApi.selection.on.rowSelectionChanged(null, function(row, event) {
        edit(row.entity, event)
      })
    }

    function edit(entity, event) {
      $mdDialog
        .show({
          controller: "SessionDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/secretary/session/session-dialog.tmpl.html",
          targetEvent: event,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: "Modifica Sessione",
              confirmButtonText: "Salva"
            },
            entity: entity,
            edit: !_.isEmpty(entity)
          }
        })
        .then(function(session) {
          if (
            angular.isDefined(session.deleteMe) &&
            session.deleteMe === true
          ) {
            // deleted session
            _.remove(vm.gridOptions.data, session)
          } else {
            // updated session
            var index = _.findIndex(vm.gridOptions.data, entity)
            _.remove(vm.gridOptions.data, entity)
            index
              ? vm.gridOptions.data.splice(index, 0, session)
              : vm.gridOptions.data.unshift(session)
          }
        })
    }

    function add(event, $event) {
      edit({}, $event)
    }

    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }

    ///////////////////////

    $scope.$on("addSession", add)
  }
})()
