/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 21/10/16 10:33.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: calendar.module
 * Created with: WebStorm
 */

(function () {
    'use strict'

    angular.module('student.calendar', [])

})()
