export default class StudentBoardController {
  boardType = "student"
  posts = []
  user = {}
  /* @ngInject */
  constructor(BoardService, $user, $cookies, $sce) {
    this.BoardService = BoardService
    this.$cookies = $cookies
    this.$sce = $sce
    this.user = $user.current
    this.fetchData()
  }
  fetchData() {
    this.BoardService
      .getByStudents()
      .then(({ data }) => {
        this.posts = data
      })
      .catch(err => {
        console.error(err)
      })
  }
  userPosition(post) {
    return post.userId === this.user._id
      ? "user-right current-user"
      : "user-left"
  }
  userColor(post) {
    return post.userId === this.user._id ? "light-green" : "cyan"
  }
  userAvatar(post) {
    return post.userId === this.user._id
      ? "assets/images/avatars/avatar-3.png"
      : "assets/images/avatars/avatar-6.png"
  }
  trustMessage(message) {
    return this.$sce.trustAsHtml(message)
  }
}
