import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.address")
    .controller("AddressesController", AddressesController)

  /* @ngInject */
  function AddressesController($address, $log, $state, $mdDialog, $mdToast) {
    var vm = this

    // TODO ADD NEW ADDRESS

    //vm.user = UserService.getCurrentUser();

    //$log.log('user => ', vm.user);

    vm.gridOptions = {
      enableRowSelection: true,
      enableGridMenu: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      minRowsToShow: 18,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: false
    }

    vm.gridOptions.columnDefs = [
      { name: "nome", width: "40%" },
      { name: "acronimo" },
      { name: "aa" },
      { name: "stato" },
      { name: "numeroMinimo" }
    ]

    vm.gridOptions.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
      gridApi.selection.on.rowSelectionChanged(null, function(row, event) {
        //$state.go('triangular.edit-teaching', row.entity);
        //console.log('log: e => ', event);

        $mdDialog
          .show({
            controller: "AddressDialogController",
            controllerAs: "vm",
            templateUrl: "app/ssml/secretary/address/address-dialog.tmpl.html",
            targetEvent: event,
            focusOnOpen: false,
            locals: {
              dialogData: {
                title: "Modifica Indirizzo",
                confirmButtonText: "Salva"
              },
              address: row.entity,
              edit: true
            }
          })
          .then(function(address) {
            if (
              angular.isDefined(address.deleteMe) &&
              address.deleteMe === true
            ) {
              // delete address
              $address.remove(row.entity).then(
                function() {
                  _.remove(vm.gridOptions.data, row.entity)
                  toast("Indirizzo Eliminato")
                },
                function() {
                  toast("Impossibile Eliminare Indirizzo")
                }
              )
            } else {
              // update address
              var deferred

              address.stato = address.stato == true ? "Attivo" : "Non Attivo"

              deferred = address.edit
                ? $address.save(address)
                : $address.create(address)

              deferred.then(
                function(address) {
                  toast("Indirizzo aggiornato!")
                },
                function(err) {
                  $log.error("err =>", err)
                  toast("Impossibile aggiornare indirizzo!")
                }
              )
            }
          })
      })
    }

    $address.query().then(
      function({ data }) {
        vm.gridOptions.data = data
      },
      function(xhr) {
        $log.error("xhr", xhr)
        if (xhr.status === 401) $state.go("authentication.login")
      }
    )

    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }
  }
})()
