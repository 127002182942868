/**
 * Created by daton on 29/10/16.
 */

(function() {
  "use strict"

  angular.module("ssml.services").service("$language", LanguageService)

  function LanguageService($http) {
    var uri = "/api/languages"

    this.model = {}
    this.collection = []

    this.query = function query(exec) {
      var dfd = $http.get(uri)

      if (!exec) return dfd

      return resolve$Q(dfd, this.collection)
    }

    this.create = function create(model) {
      return $http.post(uri, model)
    }

    this.getById = function getById(id) {
      return $http.get(uri + id)
    }

    this.save = function save(model) {
      return $http.put(uri + model._id, model)
    }

    this.remove = function remove(model) {
      return $http.delete(uri + model._id)
    }

    function resolve$Q(dfd, entity) {
      return dfd.then(
        function success(res) {
          if (res && res.data) angular.copy(res.data, entity)

          return res
        }.bind(this),
        function error(err) {
          return err
        }
      )
    }
  }
})()
