(function() {
    'use strict'

    angular
        .module('secretary.event')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.secretary-event-calendar', {
                url: '/secretary-event-calendar',

                // set the url of this page
                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/events/events.tmpl.html',
                        controller: 'SecretaryEventCalendarController',
                        controllerAs: 'vm'
                    },
                    'toolbar@triangular': {
                        templateUrl: 'app/ssml/secretary/events/layouts/toolbar/toolbar.tmpl.html',
                        controller: 'SecretaryEventsToolbarController',
                        controllerAs: 'vm'
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/calendar/calendar-fabs.tmpl.html',
                        controller: 'CalendarFabController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    layout: {
                        contentClass: 'triangular-non-scrolling layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            name: 'Eventi',
            icon: 'zmdi zmdi-calendar-alt',
            state: 'triangular.secretary-event-calendar',
            type: 'link',
            priority: 2.4,
            permission: 'SECRETARY'
        })
    }
})()
