import './triangular.module'
import './triangular.run'
import './settings.provider'
import './config.route'

import './components/index'
import './directives/index'
import './layouts/index'
import './router/index'
import './profiler/index'
import './themes/index'
