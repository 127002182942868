(function() {
    'use strict'

    angular
        .module('app.permission')
        .run(permissionRun)

    /* @ngInject */
    function permissionRun($rootScope, $cookies, $state, PermPermissionStore, PermRoleStore, $user) {
        // normally this would be done at the login page but to show quick
        // demo we grab username from cookie and login the user

        $user.check()

        const rolesMap = {
            'ADMIN':     ['viewAuthentication', 'viewConfigMail', 'manageFullCalendar', 'viewTeacherCalendar', 'viewStudentCalendar', 'manageTeaching', 'manageAddress', 'manageConstraints', 'manageUsers'],
            'SECRETARY': ['viewAuthentication', 'viewConfigMail', 'manageFullCalendar', /*'viewTeacherCalendar', 'viewStudentCalendar', 'manageTeaching',*/ 'manageAddress', 'manageConstraints', 'manageUsers'],
            'TEACHER':   ['viewAuthentication', 'viewConfigMail', /*'viewStudentCalendar', 'viewTeacherCalendar',*/ 'manageTeaching'],
            'STUDENT':   ['viewAuthentication', 'viewConfigMail', 'viewStudentCalendar'],
            'USER':      ['viewAuthentication', 'viewConfigMail']
            // 'ANONYMOUS': ['viewAuthentication']
        }

        // create permissions and add check function verify all permissions
        var permissions = ['viewAuthentication', 'viewConfigMail', 'manageFullCalendar', 'viewTeacherCalendar', 'viewStudentCalendar', 'manageTeaching', 'manageAddress', 'manageConstraints', 'manageUsers']
        PermPermissionStore.defineManyPermissions(permissions, function (permissionName) {
            // console.log('permissionName =>', permissionName);
            return $user.hasPermission(permissionName, rolesMap)
        })

        // create roles for app
        PermRoleStore.defineManyRoles(rolesMap)

        ///////////////////////

        // default redirect if access is denied
        function accessDenied() {
            console.log('Access denied go to 401 page')
            $state.go('401')
        }

        // watches

        // redirect all denied permissions to 401
        var deniedHandle = $rootScope.$on('$stateChangePermissionDenied', accessDenied)

        // remove watch on destroy
        $rootScope.$on('$destroy', function() {
            deniedHandle()
        })
    }
})()
