(function() {
  "use strict"

  angular
    .module("secretary.admission")
    .controller("AdmissionDialogController", AdmissionDialogController)

  /* @ngInject */
  function AdmissionDialogController($mdDialog, dialogData, user) {
    var vm = this
    vm.cancelClick = cancelClick
    vm.dialogData = dialogData
    vm.user = user
    vm.error = ""

    ////////////////

    function cancelClick() {
      $mdDialog.cancel()
    }
  }
})()
