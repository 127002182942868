(function () {
    'use strict'

    angular
        .module('secretary.user')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.users', {
                url: '/secretary/user',

                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/user/users.tmpl.html',
                        // set the controller to load for this page
                        controller: 'UsersController',
                        controllerAs: 'vm'
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/user/user-fabs.tmpl.html',
                        controller: 'UserFabController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        var menu = triMenuProvider.getMenu('secretary')

        if (menu && menu.children instanceof Array) {

            menu.children.push({
                name: 'Utenti',
                state: 'triangular.users',
                type: 'link'
            })
        }

        triMenuProvider.addMenu({
            id: 'secretary-users',
            name: 'Utenti',
            icon: 'fa fa-users',
            permission: 'SECRETARY',
            type: 'link',
            priority: 1.1,
            state: 'triangular.users'
        })

    }
})()
