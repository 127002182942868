import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.constraints")
    .controller("ConstraintDialogController", ConstraintDialogController)

  /* @ngInject */
  function ConstraintDialogController(
    $mdDialog,
    $log,
    $mdToast,
    dialogData,
    util,
    teachings,
    $constraint,
    constraint,
    edit
  ) {
    var vm = this
    vm.cancelClick = cancelClick
    vm.deleteClick = deleteClick
    vm.dialogData = dialogData
    vm.edit = edit
    vm.okClick = okClick
    vm.error = ""

    vm.constraint = constraint
    vm.teachings = teachings
    // vm.teachToConstraint = Object.create(teachings);

    ////////////////

    //setInterval(function () {
    //    console.log('log: constraint => ', vm.constraint);
    //}, 1000);

    function okClick() {
      // update constraint
      var deferred = edit
        ? $constraint.save(constraint)
        : $constraint.create(constraint)

      deferred.then(
        function(constraint) {
          if (constraint.edit) {
            _.remove(vm.gridOptions.data, constraint)
            toast("Vincolo aggiornato!")
          } else {
            toast("Vincolo creato!")
          }

          vm.constraint.saved = true

          $mdDialog.hide(vm.constraint)
        },
        function(err) {
          vm.error = util.findMessage(err)
          util.errorMessage = ""
          $log.error("err =>", err)
          toast("Impossibile salvare insegnamento!")
        }
      )
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function deleteClick(entity) {
      if (confirm("Vuoi eliminare questo vincolo? Azione irreversibile!"))
        // delete constraint
        $constraint.remove(entity).then(
          function() {
            _.remove(vm.gridOptions.data, entity)
            toast("Vincolo Eliminato")
            vm.constraint.deleted = true

            $mdDialog.hide(vm.constraint)
          },
          function(xhr) {
            vm.error = util.findMessage(xhr)
            util.errorMessage = ""
            $log.error("err =>", xhr)
            toast("Impossibile Eliminare Vincolo")
          }
        )
    }

    function toast(toastMessage) {
      // pop a toast
      $mdToast.show(
        $mdToast
          .simple()
          .content(toastMessage)
          .position("bottom right")
          .hideDelay(2000)
      )
    }
  }
})()
