import moment from "moment"
import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("student.calendar")
    .controller("StudentEventDialogController", StudentEventDialogController)

  /* @ngInject */
  function StudentEventDialogController(
    $scope,
    $log,
    util,
    $mdDialog,
    $mdColors,
    $user,
    ExamRegistrationService,
    $mdToast,
    dialogData,
    event,
    edit
  ) {
    var vm = this

    vm.user = $user.current

    vm.event = event

    vm.edit = edit
    vm.dialogData = dialogData
    vm.registrationExpired = false
    vm.examRegistration = {}

    // vm.allDayChanged = allDayChanged;

    // create start and end date of lesson
    vm.start = event.start.toDate()
    vm.startTime = convertMomentToTime(event.start)

    if (event.end !== null) {
      vm.end = event.end.toDate()
      vm.endTime = convertMomentToTime(event.end)
    }

    ////////////////

    vm.cancelClick = cancelClick
    vm.okClick = okClick
    vm.cancelRegistration = cancelRegistration

    ////////////////

    // BUTTONS HANDLERS START

    function okClick() {
      if (vm.event.tipo === "Esame") {
        if (vm.registrationExpired) {
          return $mdDialog.cancel()
        }

        if (vm.examRegistration._id) {
          return $mdDialog.cancel()
        }

        ExamRegistrationService.create({
          esame: vm.event._id,
          studente: vm.user._id,
          insegnamento: vm.event.insegnamento._id,
          sessione: vm.event.sessione
        }).then(
          function(reg) {
            util.toast("Registrazione effettuata con successo!")
            $mdDialog.hide(reg.data)
          },
          function(err) {
            util.toast("Impossibile effettuare Registrazione!")
            $log.error(err)
          }
        )
      } else {
        $mdDialog.cancel()
      }
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function cancelRegistration() {
      if (vm.registrationExpired) return null
      ExamRegistrationService.cancelRegistration({
        esame: vm.event._id
      })
        .then(function(res) {
          util.toast("Registrazione annullata!")
          $mdDialog.hide(res.data)
        })
        .catch(function(err) {
          util.toast("Impossibile annullare registrazione!")
          $log.error(err)
        })
    }

    // BUTTONS HANDLERS END

    // DATE HANDLERS START

    function convertMomentToTime(moment) {
      return {
        hour: moment.hour(),
        minute: moment.minute()
      }
    }

    // DATE HANDLERS END

    // init

    // $log.log('vm.event =>', vm.event);
    if (vm.event.tipo === "Esame") {
      ExamRegistrationService.showByStudent({
        studente: vm.user._id,
        esame: vm.event._id
      })
        .then(function(reg) {
          $log.log("res registration by student =>", reg)

          vm.examRegistration = reg.data

          if (moment(vm.event.chiusuraIscrizioni).isAfter(moment())) {
            vm.dialogData.confirmButtonText = "iscriviti"
          } else {
            vm.dialogData.confirmButtonText = "chiudi"
            vm.registrationExpired = true
          }

          if (vm.examRegistration._id) {
            vm.dialogData.confirmButtonText = "chiudi"
          }
        })
        .catch(function(res) {
          $log.error("res registration by student =>", res)
          if (res.status === 404) {
            if (moment(vm.event.chiusuraIscrizioni).isAfter(moment())) {
              vm.dialogData.confirmButtonText = "iscriviti"
            } else {
              vm.dialogData.confirmButtonText = "chiudi"
              vm.registrationExpired = true
            }
          }
        })
    } else {
      // TODO: gestire lezione per studente
    }
  }
})()
