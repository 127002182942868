import moment from "moment"
;(function() {
  "use strict"

  angular
    .module("secretary.calendar")
    .controller("SecretaryExamDialogController", SecretaryExamDialogController)

  /* @ngInject */
  function SecretaryExamDialogController(
    $scope,
    $log,
    util,
    $mdDialog,
    $mdColors,
    $filter,
    ExamService,
    exam,
    $mdToast,
    triTheming,
    dialogData,
    $teaching,
    classRooms,
    ExamRegistrationService,
    edit
  ) {
    var vm = this
    vm.cancelClick = cancelClick
    // vm.colors = [];
    // TODO: changeTeaching for add new exam
    // vm.changeTeaching = changeTeaching;
    // TODO: changeClassRoom for add new exam
    // vm.changeClassRoom = changeClassRoom;
    vm.deleteClick = deleteClick
    vm.dialogData = dialogData
    vm.edit = edit

    vm.firstYearColor = $mdColors.getThemeColor("red-500-0.9")
    vm.secondYearColor = $mdColors.getThemeColor("green-500-0.9")
    vm.thirdYearColor = $mdColors.getThemeColor("blue-500-0.9")

    vm.exam = exam
    vm.teachings = $teaching.collection

    vm.classRooms = classRooms

    // var rowTmpl = '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>';

    vm.gridOptions = {
      enableGridMenu: true,
      rowHeight: 35,
      showGridFooter: true,
      multiSelect: false,
      // rowTemplate: rowTmpl,
      data: [],
      exporterFieldCallback: function(grid, row, col, value) {
        if (col.displayName === "Data") {
          value = moment(value).format("L")
        }
        if (col.displayName === "Ora") {
          value = moment(value).format("HH:mm")
        }
        return value
      },
      exporterCsvFilename:
        "iscrizioni-esami_" + vm.exam.insegnamento.nome + ".csv"
    }

    vm.gridOptions.columnDefs = [
      {
        displayName: "Insegnamento",
        field: "insegnamento.nome"
      },
      {
        displayName: "Docente",
        name: "insegnamento.nomeDocente"
      },
      {
        displayName: "Data",
        field: "esame.start",
        width: 120,
        type: "date",
        cellFilter: "date:'dd-MM-yyyy'",
        sort: {
          direction: "asc",
          priority: 0
        }
      },
      {
        displayName: "Ora",
        field: "esame.start",
        width: 80,
        type: "date",
        cellFilter: "date:'HH:mm'"
      },
      {
        name: "studente.nomeCompleto",
        displayName: "Studente"
      },
      {
        name: "firma",
        visible: false,
        width: 120
      }
    ]

    $teaching
      .query() // TODO: query if edit oreDaUltimare !== 0 && $exists idDocente, else $exists idDocente
      .then(function(res) {
        vm.teachings = res.data.filter(function(teaching) {
          if (!edit) {
            return (
              teaching.oreDaUltimare !== 0 &&
              angular.isDefined(teaching.idDocente)
            )
          }

          return angular.isDefined(teaching.idDocente)
        })
      })

    ExamRegistrationService.showByTeaching({
      esame: vm.exam._id
    }).then(function(reg) {
      vm.gridOptions.data = reg.data
    })

    vm.okClick = okClick

    // create start and end date of exam
    vm.start = exam.start.toDate()
    vm.startTime = convertMomentToTime(exam.start)

    if (exam.end !== null) {
      vm.endTime = convertMomentToTime(exam.end)
    }

    ////////////////

    function okClick() {
      vm.exam.start = updateEventDateTime(vm.start, vm.startTime)
      if (vm.exam.end !== null) {
        vm.exam.end = updateEventDateTime(vm.start, vm.endTime)
      }

      vm.exam.inizio = moment(vm.exam.start).format("HH:mm")
      vm.exam.fine = moment(vm.exam.end).format("HH:mm")

      vm.exam.data = moment(vm.exam.start).format("DD/MM/YYYY")
      vm.exam.ore =
        moment(vm.exam.end).diff(moment(vm.exam.start), "minutes") / 60

      save()
    }

    function save() {
      var api = "update"

      if (vm.exam.stato === "In attesa di approvazione") api = "approve"
      $log.log("vm.exam =>", vm.exam)
      ExamService[api](vm.exam).then(function(res) {
        $log.log("res =>", res.data)

        if (res.status.toString()[0] == 2) {
          util.toast("Salvataggio completato...", "success")

          vm.exam.saved = true

          $mdDialog.hide(vm.exam)
        } else {
          util.toast(util.findMessage(res), "error")

          util.errorMessage = ""
        }
      })
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function deleteClick() {
      if (confirm("Vuoi eliminare l'esame? Questa azione è irreversibile!"))
        ExamService.remove(vm.exam._id).then(function(res) {
          if (res.status.toString()[0] == 2) {
            vm.exam.deleted = true

            util.toast("Esame Cancellato...", "success")

            $mdDialog.hide(vm.exam)
          } else {
            $log.error(res)

            util.toast(util.findMessage(res), "error")

            util.errorMessage = ""
          }
        })
    }

    function convertMomentToTime(moment) {
      return {
        hour: moment.hour(),
        minute: moment.minute()
      }
    }

    function updateEventDateTime(date, time) {
      var newDate = moment(date)
      newDate.hour(time.hour)
      newDate.minute(time.minute)
      return newDate
    }

    function createDateSelectOptions() {
      // create options for time select boxes (this will be removed in favor of mdDatetime picker when it becomes available)
      vm.dateSelectOptions = {
        hours: [],
        minutes: []
      }
      // hours
      for (var hour = 0; hour <= 23; hour++) {
        vm.dateSelectOptions.hours.push(hour)
      }
      // minutes
      for (var minute = 0; minute <= 59; minute++) {
        vm.dateSelectOptions.minutes.push(minute)
      }
    }

    // init
    createDateSelectOptions()
  }
})()
