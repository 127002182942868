import moment from 'moment'

(function () {
    'use strict'

    angular
        .module('secretary.exam')
        .controller('SecretaryExamCalendarController', SecretaryExamCalendarController)

    /* @ngInject */
    function SecretaryExamCalendarController($scope, $rootScope, $document, $mdDialog, $mdToast, $filter, $element, triTheming, $cookies, triLayout, uiCalendarConfig, ExamService, $lesson, $log, $teaching, $classRoom) {

        var vm = this

        // stato ['In attesa di approvazione', 'Approvato', 'In corso', 'Chiuso' ]

        var classRooms = $classRoom.collection
        var teachings = $teaching.collection

        var defaultDate = angular.isDefined($cookies.get('current-day')) ? moment($cookies.get('current-day'), 'YYYY-MM-DD') : moment()
        var defaultView = $cookies.get('calViewType') || 'agendaWeek'

        vm.viewFormats = {
            'month': 'DD MMMM YYYY',
            'agendaWeek': 'w - D MMMM YY',
            'agendaDay': 'Do MMMM YYYY'
        }

        vm.eventSources = [{
            // events: events,
            url: '/api/exams/secretary',
            data: {}
        }]

        vm.calendarOptions = {
            contentHeight: 'auto',
            selectable: true,
            editable: true,
            header: false,
            lang: 'it',
            timezone: 'local',
            minTime: '07:30:00',
            maxTime: '20:00:00',
            hiddenDays: [0],
            defaultDate: defaultDate,
            defaultView: defaultView,
            timeFormat: 'HH:mm',
            viewRender: viewRender,
            dayClick: dayClick,
            select: select,
            eventClick: eventClick,
            eventRender: eventRender
        }

        vm.addEvent = addEvent

        function eventRender(event, element) {

            element.find('.fc-title').append(
                '<br/>' + 'Appello ' + event.numero + ' (' + event.stato.toUpperCase() + ')' +
                '<br/>' + event.insegnamento.nome +
                '<br/>' + 'Docente: ' + event.insegnamento.nomeDocente +
                (event.aula && event.aula.nome ? '<br/>' + 'Aula: ' + event.aula.nome : '')
            )
        }

        function viewRender(view) {
            vm.view = view

            $cookies.put('calViewType', view.type)

            vm.currentDay = view.calendar.getDate()
            $cookies.put('current-day', vm.currentDay.format(''))

            vm.currentView = view.name
            // update toolbar with new day for month name
            $rootScope.$broadcast('calendar-changeday', vm.currentDay)
            // update background image for month
            triLayout.layout.contentClass = 'calendar-background-image background-overlay-static overlay-gradient-10 calendar-background-month-' + vm.currentDay.month()
        }

        function dayClick(date, jsEvent, view) { //eslint-disable-line
            vm.currentDay = date
            $cookies.put('current-day', date.format())
            $rootScope.$broadcast('dayClicked', date)
        }

        function select(start, end) {
            vm.currentDayStart = start
            vm.currentDayEnd = end
        }

        function eventClick(calEvent, jsEvent, view) { //eslint-disable-line

            $mdDialog.show({
                controller: 'SecretaryExamDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/ssml/secretary/exams/exam-dialog.tmpl.html',
                targetEvent: jsEvent,
                clickOutsideToClose: true,
                locals: {
                    dialogData: {
                        title: (calEvent.stato == 'In attesa di approvazione' ? 'Approva' : 'Aggiorna'),
                        confirmButtonText: calEvent.stato == 'In attesa di approvazione' ? 'Approva' : 'Salva'
                    },
                    exam: calEvent,
                    classRooms: classRooms,
                    edit: true
                }
            })
                .then(onHide)

            function onHide(event) {

                if (angular.isDefined(event.deleted) && event.deleted === true) {

                    // remove the event from the calendar
                    uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('removeEvents', event._id)

                }
                if (angular.isDefined(event.saved) && event.saved === true) {

                    uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('refetchEvents')

                }

            }

        }

        function addEvent(event, $event) {

            var start = vm.currentDay.minute(0)
            var end = moment(vm.currentDay).add(2, 'h')

            if (vm.currentDayStart) start = vm.currentDayStart
            if (vm.currentDayEnd) end = vm.currentDayEnd

            $mdDialog.show({
                controller: 'ExamDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/ssml/secretary/exams/exam-dialog.tmpl.html',
                targetEvent: $event,
                focusOnOpen: false,
                locals: {
                    dialogData: {
                        title: 'Aggiungi esame',
                        confirmButtonText: 'Crea'
                    },
                    exam: {
                        title: $filter('triTranslate')('Nuovo esame'),
                        allDay: false,
                        start: start,
                        end: end,
                        palette: 'cyan',
                        stick: true
                    },
                    // exams: clientExams(),
                    teachings: teachings,
                    classRooms: classRooms,
                    edit: false
                }
            })
                .then(function (exam) {

                    if (angular.isDefined(exam.saved) && exam.saved == true) {

                        uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('refetchEvents')

                    }
                    if (angular.isDefined(exam.deleted) && exam.deleted === true) {

                        // remove the exam from the calendar
                        uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('removeEvents', exam._id)

                    }

                })
        }

        function filterTeacher(event, teacherId) {

            if (!teacherId && vm.eventSources[0].data.teacherId) {

                delete vm.eventSources[0].data.teacherId

            } else {

                vm.eventSources[0].data.teacherId = teacherId

            }

            uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('refetchEvents')
        }

        function filterYear(event, year) {

            if (!year && vm.eventSources[0].data.year) {

                delete vm.eventSources[0].data.year

            } else {

                vm.eventSources[0].data.year = year

            }

            // if (vm.eventSources[0].data.teacherId) delete vm.eventSources[0].data.teacherId;

            uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('refetchEvents')

        }

        function saveCsv() {
            // console.log('event', event);
            ExamService.saveCsv(vm.eventSources[0].data)
                .then(function (res) {

                    var csv = res.data.csv

                    if (csv == null) return

                    var filename = 'calendario_esami.csv'

                    if (!csv.match(/^data:text\/csv/i)) {
                        csv = 'data:text/csv;charset=utf-8,' + csv
                    }

                    var data = encodeURI(csv)

                    // console.log('data =>', data);
                    // var link = document.createElement('a');

                    var link = angular.element('<a/>')

                    // link.setAttribute('href', data);
                    // link.setAttribute('download', filename);

                    link.attr({
                        href: data,
                        target: '_blank',
                        download: filename
                    })

                    $document.find('body').eq(0).append(link)
                    link[0].click()
                    $document.find(link).eq(0).remove()

                }, function (xhr) {
                    $log.error(xhr)
                })
        }

        $classRoom.query(true)

        // listeners

        $scope.$on('addEvent', addEvent)
        $scope.$on('filterTeacher', filterTeacher)
        $scope.$on('filterYear', filterYear)
        $scope.$on('saveCsv', saveCsv)

    }
})()
