/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 21/10/16 10:32.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: student.module
 * Created with: WebStorm
 */

(function() {
  "use strict"

  angular.module("student", [
    "student.calendar",
    "student.study-plan",
    "student.teaching",
    "student.board"
    // 'student.mail'
  ])
})()
