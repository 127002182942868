export default function httpInterceptor(
    $q,
    $window,
    $injector,
    $rootScope,
    $log
) {
    return {
        request: function(config) {
            return config || $q.when(config)
        },
        response: function(response) {
            return response || $q.when(response)
        },
        responseError: function(response) {
            if (response.status === 401) {
                //here I preserve login page
                // someService
                //    .setRestrictedPageBeforeLogin(
                //             extractPreservedInfoAboutPage(response)
                //     )
                // $rootScope.$broadcast('error')

                if (
                    response.config.url.replace($window.location.origin, "") !==
                    "/currentuser"
                ) {
                    // TODO remove user from cookie and memory
                    // $state.go('authentication.login');
                    console.log("redirecting to login")
                    $window.location.href =
                        /* $window.location.origin + */ "/#!/login"
                    // var $state = $injector.get('$state');
                    // $state.go('authentication.login');
                }
            }
            return $q.reject(response)
        }
    }
}
