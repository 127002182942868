(function () {
    'use strict'

    angular
        .module('secretary.session')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.secretary-sessions', {
                url: '/secretary/sessions',

                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/session/sessions.tmpl.html',
                        // set the controller to load for this page

                        controller: 'SecretarySessionsController',
                        controllerAs: 'vm'
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/session/session-fabs.tmpl.html',
                        controller: 'SessionFabController',
                        controllerAs: 'vm'
                    }
                },

                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            name: 'Sessioni',
            icon: 'zmdi zmdi-seat',
            type: 'link',
            permission: 'SECRETARY',
            state: 'triangular.secretary-sessions',
            priority: 1.1
        })
    }
})()