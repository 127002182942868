(function() {
  "use strict"

  angular.module("ssml.services").service("admissionService", admissionService)

  /* @ngInject */
  function admissionService($http) {
    var collection = []
    var model = {}

    this.collection = collection
    this.model = model

    this.list = list

    ////////////////

    function list() {
      return $http.get("/api/v1/admissions").then(function(res) {
        // console.log('list admissions =>', res.data)
        angular.copy(res.data, collection)
        return res
      })
    }
  }
})()
