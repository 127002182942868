
import './components.module'
import "./breadcrumbs/breadcrumbs.service"

import "./loader/loader-service"
import "./loader/loader.directive"
import "./notifications-panel/notifications-panel.controller"
import "./table/table-cell-image.filter"
import "./table/table-start-from.filter"
import "./table/table.directive"
import "./toolbars/toolbar.controller"
import "./widget/widget.directive"
import "./wizard/wizard-form.directive"
import "./wizard/wizard.directive"
