/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 21/10/16 11:13.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: mail.config
 * Created with: WebStorm
 */

(function () {
    'use strict'

    angular.module('mail')
        .config(Config)

    function Config($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.mail', {

                url: '/config/mail',
                templateUrl: 'app/ssml/common/mail/mail.tmpl.html',
                controller: 'MailController',
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['viewConfigMail'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            name: 'Configura e-mail',
            // set an icon for this menu
            icon: 'zmdi zmdi-email',
            type: 'link',
            permissions: 'viewConfigMail',
            priority: 1.1,
            state: 'triangular.mail'
        })

    }

})()
