/* @ngInject */
export default function boardConfig($stateProvider, triMenuProvider) {
  $stateProvider.state("triangular.student-board", {
    url: "/student/board",
    templateUrl: "app/ssml/student/board/board.tmpl.html",
    // set the controller to load for this page
    controller: "StudentBoardController",
    controllerAs: "vm",
    // layout-column class added to make footer move to
    // bottom of the page on short pages
    data: {
      layout: {
        contentClass: "layout-column"
      },
      permissions: {
        only: ["STUDENT"],
        redirect: "authentication.login"
      }
    }
  })

  $stateProvider.state("triangular.student-teachings-board", {
    url: "/student/teaching-board",
    templateUrl: "app/ssml/student/board/teachings-board.tmpl.html",
    // set the controller to load for this page
    controller: "StudentTeachingsBoardController",
    controllerAs: "vm",
    // layout-column class added to make footer move to
    // bottom of the page on short pages
    data: {
      layout: {
        contentClass: "layout-column"
      },
      permissions: {
        only: ["STUDENT"],
        redirect: "authentication.login"
      }
    }
  })

  triMenuProvider.addMenu({
    name: "Bacheca Avvisi",
    icon: "fas fa-chalkboard",
    // type: "dropdown",
    permission: "STUDENT",
    type: "link",
    state: "triangular.student-board",
    priority: 1.1
    // children: [
    //   {
    //     name: "Bacheca",
    //   }
    // ]
  })

  triMenuProvider.addMenu({
    name: "Bacheca Insegnamenti",
    icon: "fas fa-chalkboard",
    // type: "dropdown",
    permission: "STUDENT",
    type: "link",
    state: "triangular.student-teachings-board",
    priority: 1.2
    // children: [
    //   {
    //     name: "Bacheca",
    //   }
    // ]
  })
}
