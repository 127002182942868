/**
 * Created by daton on 19/10/16.
 */

(function () {
    'use strict'

    angular.module('secretary.session', [])

})()
