(function() {
    'use strict'

    angular
        .module('secretary.room')
        .config(moduleConfig)

    /* @ngInject */
    function moduleConfig($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.rooms', {
                url: '/secretary/rooms',

                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/secretary/room/rooms.tmpl.html',
                        // set the controller to load for this page
                        controller: 'RoomsController',
                        controllerAs: 'vm',
                    },
                    'belowContent@triangular': {
                        templateUrl: 'app/ssml/secretary/room/room-fabs.tmpl.html',
                        controller: 'RoomFabController',
                        controllerAs: 'vm'
                    }
                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false
                    },
                    permissions: {
                        only: ['SECRETARY'],
                        redirect: 'authentication.login'
                    }
                }
            })

        var menu = triMenuProvider.getMenu('secretary')

        if (menu && menu.children instanceof Array) {

            menu.children.push({
                name: 'Aule',
                state: 'triangular.rooms',
                type: 'link'
            })
        }

        triMenuProvider.addMenu({
            id: 'secretary-rooms',
            name: 'Aule',
            icon: 'zmdi zmdi-map',
            permission: 'SECRETARY',
            type: 'link',
            priority: 1.1,
            state: 'triangular.rooms'
        })

    }
})()
