(function() {
    'use strict'

    angular
        .module('secretary.teaching')
        .controller('TeachingSaveFabController', TeachingSaveFabController)

    /* @ngInject */
    function TeachingSaveFabController($rootScope) {
        var vm = this
        vm.saveTeaching = saveTeaching

        ////////////////

        function saveTeaching($event) {
            $rootScope.$broadcast('saveTeaching', $event)
        }
    }
})()
