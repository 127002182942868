/**
 * Created by daton on 19/10/16.
 */

(function() {
    'use strict'

    angular.module('ssml.services')
        .service('SessionFactory', SessionFactory)

    function SessionFactory($http) {

        var collection = []
        var model = []
        var url = '/api/sessioni'

        this.collection = collection
        this.model = model
        this.index = index
        this.show = show
        this.create = create
        this.update = update
        this.destroy = destroy
        

        function index() {
            return resolve$Q($http({
                url: url,
                method: 'GET'
            }), collection)
        }

        function show(id) {
            return resolve$Q($http({
                url: url + '/' + id,
                method: 'GET'
            }), model)
        }

        function create(Model) {
            return resolve$Q($http({
                url: url,
                method: 'POST',
                data: Model
            }), model)
        }

        function update(Model) {
            return resolve$Q($http({
                url: url + '/' + Model._id,
                method: 'PUT',
                data: Model
            }), model)
        }

        function destroy(Model) {

            return resolve$Q($http({
                url: url + '/' + Model._id,
                method: 'DELETE',
                data: Model
            }), model)

        }

        function resolve$Q(deferred, entity) {

            return deferred
                .then(function(res) {
                    angular.copy(res.data, entity)
                    return res
                }, function(res) {
                    return res
                })

        }

    }

})()