/* @ngInject */
export default function ErrorPageController($state, $user) {
    var vm = this

    vm.goHome = goHome

    var to

    switch ($user.current.tipo) {
        case "Segreteria":
            to = "triangular.secretary-calendar"
            break

        case "Studente":
            to = "triangular.student-calendar"
            break

        case "Docente":
            to = "triangular.teacher-calendar"
            break

        case "Amministratore":
            to = "triangular.secretary-calendar"
            break

        default:
            to = "authentication.login"
            break
    }

    $state.go(to)

    /////////

    function goHome() {
    //    $state.go(to)
    }
}
