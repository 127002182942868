class LessonService {
  endpoint = "/api/lessons"
  collection = []
  model = {}
  constructor($http) {
    this.$http = $http
  }
  query(params, resolve) {
    const dfd = this.$http({
      url: this.endpoint,
      method: "GET",
      params: params
    })
    if (!resolve) return dfd
    return this.resolve$QCollection(dfd)
  }
  save(model, resolve) {
    delete model.source
    const dfd = this.$http.put(this.endpoint + "/" + model._id, model)
    if (!resolve) return dfd
    return this.resolve$Q(dfd)
  }
  create(model, resolve) {
    const dfd = this.$http.post(this.endpoint, model)
    if (!resolve) return dfd
    return this.resolve$Q(dfd)
  }
  remove(model, resolve) {
    const dfd = this.$http.delete(this.endpoint + "/" + model._id)
    if (!resolve) return dfd
    return this.resolve$Q(dfd)
  }
  resolve$Q(dfd) {
    return dfd.then(
      res => {
        if (res && res.data) angular.copy(res.data, this.model)
        return res
      },
      err => {
        console.error(err)
        return err
      }
    )
  }
  resolve$QCollection(dfd) {
    return dfd.then(
      res => {
        if (res && res.data) angular.copy(res.data, this.collection)
        return res
      },
      err => {
        console.error(err)
        return err
      }
    )
  }
}

angular.module("ssml.services").service("$lesson", LessonService)
