import _ from "lodash"

import moment from "moment"
;(function() {
  "use strict"

  angular
    .module("secretary.calendar")
    .controller("CalendarController", CalendarController)

  /* @ngInject */
  function CalendarController(
    $scope,
    $rootScope,
    $mdDialog,
    $cookies,
    triLayout,
    uiCalendarConfig,
    $teaching,
    $classRoom,
    $constraint
  ) {
    var vm = this

    vm.lessons = []

    var teachings = $teaching.collection
    var classRooms = $classRoom.collection
    var constraints = $constraint.collection

    var defaultDate = $cookies.get("current-day")
      ? moment($cookies.get("current-day"), "YYYY-MM-DD")
      : moment()
    var defaultView = $cookies.get("calViewType") || "agendaWeek"

    vm.viewFormats = {
      month: "MMMM YYYY",
      agendaWeek: "w - D MMMM YY",
      agendaDay: "Do MMMM YYYY"
    }

    vm.eventSources = [
      {
        // events: fetchLessons
        url: "/api/lessons",
        data: {}
      }
    ]

    vm.calendarOptions = {
      contentHeight: "auto",
      selectable: true,
      editable: true,
      header: false,
      lang: "it",
      timezone: "local",
      minTime: "07:30:00",
      maxTime: "20:00:00",
      hiddenDays: [0],
      defaultDate: defaultDate,
      defaultView: defaultView,
      timeFormat: "HH:mm",
      viewRender: viewRender,
      dayClick: dayClick,
      select: select,
      // eventDrop: eventDrop,
      eventRender: eventRender,
      eventClick: eventClick
    }

    ///////////////

    vm.addEvent = addEvent

    /////////////// functions

    function eventRender(event, element) {
      element
        .find(".fc-title")
        .append(
          "<br/>" +
            event.idInsegnamento.nome +
            "<br/>" +
            event.idInsegnamento.nomeDocente +
            (event.idAula ? "<br/>" + event.idAula.nome : "")
        )
    }

    function viewRender(view) {
      vm.view = view
      $cookies.put("calViewType", view.type)

      vm.currentDay = view.calendar.getDate()

      $cookies.put("current-day", vm.currentDay.format())

      vm.currentView = view.name
      // update toolbar with new day for month name
      $rootScope.$broadcast("calendar-changeday", vm.currentDay)
      // update background image for month
      triLayout.layout.contentClass =
        "calendar-background-image background-overlay-static overlay-gradient-10 calendar-background-month-" +
        vm.currentDay.month()
    }

    function dayClick(date, jsEvent, view) {
      //eslint-disable-line
      vm.currentDay = view.calendar.getDate()
      $cookies.put("current-day", vm.currentDay.format())
      $rootScope.$broadcast("dayClicked", date)
    }

    function select(start, end) {
      vm.currentDayStart = start
      vm.currentDayEnd = end
    }

    // function eventDrop(event, delta, revertFunc) {
    //     var message = 'Sei sicuro di voler spostare questa lezione?';
    //     showConfirmationMessage(checkOverlap(event) || message, revertFunc, event);
    //     revertFunc();
    // },

    function eventClick(calEvent, jsEvent, view) {
      //eslint-disable-line

      $mdDialog
        .show({
          controller: "SecretaryLessonDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/secretary/calendar/event-dialog.tmpl.html",
          targetEvent: jsEvent,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: "Modifica Lezione",
              confirmButtonText: "Salva"
            },
            lesson: calEvent,
            lessons: clientLessons(),
            teachings: teachings,
            classRooms: classRooms,
            constraints: constraints,
            checkOverlap: checkOverlap,
            edit: true
          }
        })
        .then(function(event) {
          if (angular.isDefined(event.deleted) && event.deleted === true) {
            // remove the event from the calendar
            uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
              "removeEvents",
              event._id
            )
          }
          if (angular.isDefined(event.saved) && event.saved === true) {
            // _.assign(event, res.data);
            uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
              "updateEvent",
              event
            )
            // fetchLessons();
          }
        })
    }

    function checkOverlap(event) {
      var teachingId = event.idInsegnamento._id
      var teacherId = event.idInsegnamento.idDocente

      var lessonsToCheck = _.remove(Object.create(clientLessons()), function(
        lesson
      ) {
        return lesson._id !== event._id
      })

      var message
      _.each(lessonsToCheck, function(lesson) {
        if (
          lesson.idInsegnamento &&
          lesson.idInsegnamento.idDocente == teacherId &&
          moment(lesson.end).isAfter(event.start) &&
          moment(lesson.start).isBefore(event.end) &&
          lesson._id !== event.idInsegnamento._id
        ) {
          message =
            "<strong>" +
            event.idInsegnamento.nome +
            "</strong> " +
            " <span>ha lo stesso docente di: </span>\n" +
            "<strong>" +
            lesson.idInsegnamento.nome +
            "</strong>"

          return
        }

        if (
          moment(lesson.end).isAfter(event.start) &&
          moment(lesson.start).isBefore(event.end)
        ) {
          _.each(constraints, function(constraint) {
            if (
              constraint.idInsegnamento &&
              constraint.idInsegnamento._id === teachingId
            ) {
              _.each(constraint.constraints, function(c) {
                if (lesson.idInsegnamento._id === c._id) {
                  message =
                    "<strong>" +
                    event.idInsegnamento.nome +
                    "</strong> " +
                    "<span>non può svolgersi in contemporanea con: </span>\n" +
                    "<strong>" +
                    lesson.idInsegnamento.nome +
                    "</strong>"

                  return

                  // this.trigger('overlapped', lesson, this.model.toJSON());
                }
              })
            }
          })
        }
      })
      return message
    }

    function addEvent(event, $event) {
      var start = vm.currentDay.minute(0)
      var end = moment(vm.currentDay).add(2, "h")

      if (vm.currentDayStart) start = vm.currentDayStart
      if (vm.currentDayEnd) end = vm.currentDayEnd

      $mdDialog
        .show({
          controller: "SecretaryLessonDialogController",
          controllerAs: "vm",
          templateUrl: "app/ssml/secretary/calendar/event-dialog.tmpl.html",
          targetEvent: $event,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: "Aggiungi lezione",
              confirmButtonText: "Crea"
            },
            lesson: {
              // title: $filter('triTranslate')('Nuova Lezione'),
              allDay: false,
              start: start,
              end: end,
              palette: "cyan",
              stick: true
            },
            lessons: clientLessons(),
            teachings: teachings,
            classRooms: classRooms,
            constraints: constraints,
            checkOverlap: checkOverlap,
            edit: false
          }
        })
        .then(function(lesson) {
          if (angular.isDefined(lesson.saved) && lesson.saved == true) {
            refetchLessons()
            // vm.eventSources[0].events.push(res.data);
          }
          if (angular.isDefined(lesson.deleted) && lesson.deleted === true) {
            // remove the lesson from the calendar
            uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
              "removeEvents",
              lesson._id
            )
          }
        })
    }

    function refetchLessons(/* event, $event, params */) {
      uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
        "refetchEvents"
      )
    }

    function clientLessons() {
      return uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
        "clientEvents"
      )
    }

    function filterTeacher(event, teacherId) {
      if (!teacherId && vm.eventSources[0].data.teacherId) {
        delete vm.eventSources[0].data.teacherId
      } else {
        vm.eventSources[0].data.teacherId = teacherId
      }

      // if (vm.eventSources[0].data.year) delete vm.eventSources[0].data.year;

      uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
        "refetchEvents"
      )
    }

    function filterYear(event, year) {
      if (!year && vm.eventSources[0].data.year) {
        delete vm.eventSources[0].data.year
      } else {
        vm.eventSources[0].data.year = year
      }

      // if (vm.eventSources[0].data.teacherId) delete vm.eventSources[0].data.teacherId;

      uiCalendarConfig.calendars["triangular-calendar"].fullCalendar(
        "refetchEvents"
      )
    }

    /////////////// init

    $teaching.query(true, {
      stato: "Attivo"
    })
    $classRoom.query(true)
    $constraint.query(true)

    ///////////////

    ///////////////listeners

    $scope.$on("addEvent", addEvent)
    $scope.$on("filterTeacher", filterTeacher)
    $scope.$on("filterYear", filterYear)
    $scope.$on("fetchLessons", refetchLessons)
  }
})()

// function showConfirmationMessage(message, revertFunc, event) {
//
//     var countDown;
//
//     event.editable = false;
//
//     if (revertFunc)
//         countDown = setTimeout(function () {
//             event.editable = true;
//             revertFunc()
//         }, 10 * 1000);
//
//     $mdToast.show({
//         controller: function ($scope) {
//             $scope.time = 'assets/images/countdown.gif';
//             $scope.message = message;
//             $scope.continue = function () {
//                 $scope.time = '';
//                 $mdDialog.hide(event);
//                 if (revertFunc && event) {
//                     event.editable = true;
//                     clearTimeout(countDown);
//                     $mdToast.hide();
//                     $lesson.save(event)
//                         .then(function (res) {
//
//                             var status = res.status.toString()[0],
//                                 message;
//
//                             if (status == 2) {
//                                 // _.assign(event, res.data);
//                                 uiCalendarConfig.calendars['triangular-calendar'].fullCalendar('updateEvent', event);
//                                 message = 'Evento Aggiornato';
//                                 // fetchLessons();
//                             } else {
//                                 // pop a toast
//                                 message = 'Errore salvataggio';
//                             }
//
//                             $mdToast.show(
//                                 $mdToast.simple()
//                                     .content($filter('triTranslate')(message))
//                                     .position('bottom right')
//                                     .hideDelay(3000)
//                             );
//                         });
//                 }
//             };
//             $scope.close = function () {
//                 event.editable = true;
//                 revertFunc();
//                 $mdToast.cancel();
//             };
//         },
//         hideDelay: 10 * 1000,
//         position: 'bottom right',
//         templateUrl: 'app/calendar/constraints-template.tmpl.html'
//     }).then(function (response) {
//         clearTimeout(countDown);
//         event.editable = true;
//         if (response == 'ok') {
//             $mdDialog.hide(vm.lesson);
//         }
//     });
// }
