import moment from "moment"
import _ from "lodash"
/**
 * Created by daton on 10/10/16.
 */
;(function() {
  "use strict"

  angular
    .module("teacher.exam")
    .controller("TeacherExamCreationController", ExamController)

  /* @ngInject */
  function ExamController(
    $log,
    $timeout,
    $mdToast,
    $stateParams,
    $teaching,
    $scope,
    $mdColors,
    $state,
    util,
    SessionFactory,
    triBreadcrumbsService,
    ExamService
  ) {
    var vm = this
    var query

    triBreadcrumbsService.reset()

    vm.firstYearColor = $mdColors.getThemeColor("red-500-0.9")
    vm.secondYearColor = $mdColors.getThemeColor("green-500-0.9")
    vm.thirdYearColor = $mdColors.getThemeColor("blue-500-0.9")

    vm.teaching = {}
    vm.sessions = []
    vm.exams = []

    vm.save = save
    vm.back = back
    vm.goToExam = goToExam

    if (!$stateParams._id) {
      return $state.go("triangular.teacher-teachings")
    }

    $teaching
      .getById($stateParams._id)
      .then(function(res) {
        vm.teaching = res.data

        triBreadcrumbsService.addCrumb({
          name: "Gestione Esami"
        })

        triBreadcrumbsService.addCrumb({
          name: vm.teaching.nome
        })

        return SessionFactory.index()
      })
      .then(function(res) {
        $log.log("sessioni =>", res.data)

        vm.sessions = res.data

        return ExamService.showByTeachingRaw(vm.teaching._id)
      })
      .then(function(res) {
        vm.exams = res.data

        initializeSession(vm.sessions)

        vm.sessions.map(function(session) {
          var primoAppello = _.find(vm.exams, {
            sessione: session._id,
            insegnamento: vm.teaching._id,
            numero: 1
          })

          var secondoAppello = _.find(vm.exams, {
            sessione: session._id,
            insegnamento: vm.teaching._id,
            numero: 2
          })

          if (angular.isDefined(primoAppello)) session.appelli[0] = primoAppello
          if (angular.isDefined(secondoAppello))
            session.appelli[1] = secondoAppello

          return session
        })
      })
      .catch(function(xhr) {
        $log.error(xhr)

        // if exams doesn't exists create appelli
        if (xhr.status === 404) {
          initializeSession(vm.sessions)
        }
      })

    function initializeSession(sessions) {
      sessions.map(function(session) {
        session.appelli = [
          {
            numero: 1,
            sessione: session._id,
            insegnamento: vm.teaching._id,
            dateProposte: [
              {
                tipo: "proposta",
                data: new Date(session.inizio),
                inizioOre: 9,
                inizioMinuti: 0,
                minDate: new Date(session.inizio),
                maxDate: new Date(session.fine)
              },
              {
                tipo: "riserva",
                data: new Date(session.inizio),
                inizioOre: 9,
                inizioMinuti: 0,
                minDate: new Date(session.inizio),
                maxDate: new Date(session.fine)
              }
            ]
          },
          {
            numero: 2,
            sessione: session._id,
            insegnamento: vm.teaching._id,
            dateProposte: [
              {
                tipo: "proposta",
                data: new Date(session.inizio),
                inizioOre: 9,
                inizioMinuti: 0,
                minDate: new Date(session.inizio),
                maxDate: new Date(session.fine)
              },
              {
                tipo: "riserva",
                data: new Date(session.inizio),
                inizioOre: 9,
                inizioMinuti: 0,
                minDate: new Date(session.inizio),
                maxDate: new Date(session.fine)
              }
            ]
          }
        ]

        return session
      })
    }

    function back() {
      $state.go("triangular.teacher-teaching", vm.teaching)
    }

    function goToExam(exam) {
      $state.go("triangular.teacher-exam", exam)
    }

    function save(appello) {
      // dateProposte: [{
      //     tipo String
      //     start Date
      //     end Date
      // }

      // SESSIONE SCHEMA
      //     numero:
      //     nome:
      //     inizio:
      //     fine:
      //     stato:
      //     appelli: []

      var sessione = _.find(vm.sessions, { _id: appello.sessione })

      var dateProposte = []

      appello.dateProposte.forEach(function(date) {
        if (!date.data) {
          alert("Inserisci data")
          throw Error("Inserisci data")
        }
        if (!date.inizioOre) {
          alert("Inserisci ora")
          throw Error("Inserisci ora")
        }
        if (!date.inizioMinuti && date.inizioMinuti !== 0) {
          alert("Inserisci minuti")
          throw Error("Inserisci minuti")
        }

        var start = updateEventDateTime(date.data, {
          hour: date.inizioOre,
          minute: date.inizioMinuti
        })

        // controllo compreso in sessione inizio e fine
        if (sessione.inizio && start.isBefore(moment(sessione.inizio))) {
          alert(
            "La data deve essere successiva al " +
              moment(sessione.inizio).format("DD/MM/YY")
          )
          throw new Error("isBefore session start")
        }

        if (sessione.fine && start.isAfter(moment(sessione.fine))) {
          alert(
            "La data deve essere precendete al " +
              moment(sessione.fine).format("DD/MM/YY")
          )
          throw new Error("isAfter session end")
        }

        // controlli vincoli 15 giorni
        // sessione.appelli.forEach(function (a) {

        //     if (a.numero === appello.numero) return;

        //     // confronto con appello approvato
        //     if (a.start) {

        //         if (start.isValid() && a.start.isValid()) {

        //             if (haveNotPassedFifteenDays(start, a.start)) {
        //                 alert('Devono trascorrere 15 giorni tra le date del primo e del secondo appello! ' + start.format('DD/MM/YY') + ' ' + moment(a.start).format('DD/MM/YY'));
        //                 throw Error('haveNotPassedFifteenDays ' + start.format() + ' ' + moment(a.start).format());
        //             }

        //         } else {
        //             $log.error('start', start, ', a.start', a.start);
        //         }

        //     }

        //     // confronto con date da approvare
        //     if (a.dateProposte && a.dateProposte.length > 0) {

        //         a.dateProposte.forEach(function (date) {

        //             if (start.isValid() && date.start) {

        //                 if (haveNotPassedFifteenDays(start, moment(date.start))) {
        //                     alert('Devono trascorrere 15 giorni tra le date del primo e del secondo appello! ' + start.format('DD/MM/YY') + ' ' + moment(date.start).format('DD/MM/YY'));
        //                     throw Error('haveNotPassedFifteenDays ' + start.format() + ' ' + moment(date.start).format());
        //                 }

        //             } else {
        //                 $log.error('start', start, ', date.start', date.start);
        //             }

        //         });
        //     }

        // });

        dateProposte.push({
          start: start,
          end: moment(start).add(2, "hours"),
          tipo: date.tipo
        })
      })

      appello.dateProposte = dateProposte

      switch (vm.teaching.annoCorso) {
        case 1:
          appello.className = "lesson-first-year"
          appello.color = vm.firstYearColor
          appello.textColor = "white"
          break
        case 2:
          appello.className = "lesson-second-year"
          appello.color = vm.secondYearColor
          appello.textColor = "white"
          break
        case 3:
          appello.className = "lesson-third-year"
          appello.color = vm.thirdYearColor
          appello.textColor = "white"
          break
      }

      // $log.log('chiamata exam create');

      ExamService.create(appello).then(
        function(res) {
          if (res.status === 201) {
            util.toast("Esame salvato!")
            // TODO: aggiornare gli appelli in memoria
            $state.reload()
          }
        },
        function(res) {
          $log.error(res)
          util.toast("Problema nel salvataggio!")
        }
      )
    }

    function haveNotPassedFifteenDays(current, check) {
      if (current.isAfter(check)) {
        return current.isBefore(check.add(15, "days"))
      } else {
        return current.isAfter(check.subtract(15, "days"))
      }
    }

    function updateEventDateTime(date, time) {
      var newDate = moment(date)
      newDate.hour(time.hour)
      newDate.minute(time.minute)
      return newDate
    }

    function createDateSelectOptions() {
      // create options for time select boxes (this will be removed in favor of mdDatetime picker when it becomes available)
      vm.dateSelectOptions = {
        hours: [],
        minutes: []
      }
      // hours
      for (var hour = 8; hour <= 20; hour++) {
        vm.dateSelectOptions.hours.push(hour)
      }
      // minutes
      for (var minute = 0; minute <= 59; minute++) {
        if (minute % 5 == 0) vm.dateSelectOptions.minutes.push(minute)
      }
    }

    // init

    createDateSelectOptions()

    ///////////////////////////
  }
})()
