(function () {
    'use strict'

    angular.module('student.study-plan')
        .config(StudyPlan)

    function StudyPlan($stateProvider, triMenuProvider) {

        $stateProvider
            .state('triangular.student-study-plan', {
                url: '/student/study-plan',
                views: {
                    '@triangular': {
                        templateUrl: 'app/ssml/student/study-plan/study-plan.tmpl.html',
                        // set the controller to load for this page
                        controller: 'StudentStudyPlanController',
                        controllerAs: 'vm'
                    }
                },
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column'
                    },
                    permissions: {
                        only: ['STUDENT'],
                        redirect: 'authentication.login'
                    }
                }
            })

        triMenuProvider.addMenu({
            id: 'student-study-plans',
            name: 'Piano di studi',
            icon: 'fa fa-book',
            permission: 'STUDENT',
            type: 'link',
            state: 'triangular.student-study-plan',
            priority: 1.1
        })

    }

})()
