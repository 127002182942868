import moment from "moment"
import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.calendar")
    .controller(
      "SecretaryLessonDialogController",
      SecretaryLessonDialogController
    )

  /* @ngInject */
  function SecretaryLessonDialogController(
    $scope,
    $log,
    util,
    $mdDialog,
    $mdColors,
    $lesson,
    $mdToast,
    dialogData,
    lesson,
    lessons,
    $teaching,
    classRooms,
    constraints,
    checkOverlap,
    edit
  ) {
    var vm = this

    vm.teachings = $teaching.collection
    vm.classRooms = classRooms
    vm.lesson = lesson

    vm.dialogData = dialogData
    vm.edit = edit

    // vm.allDayChanged = allDayChanged;

    vm.firstYearColor = $mdColors.getThemeColor("red-500-0.9")
    vm.secondYearColor = $mdColors.getThemeColor("green-500-0.9")
    vm.thirdYearColor = $mdColors.getThemeColor("blue-500-0.9")

    // create start and end date of lesson
    vm.start = lesson.start.toDate()
    vm.startTime = convertMomentToTime(lesson.start)

    if (lesson.end !== null) {
      // vm.end = lesson.end.toDate();
      vm.endTime = convertMomentToTime(lesson.end)
    }

    // TODO: remove this fix
    // if (edit && angular.isString(vm.lesson.idAula._id)) {
    //     vm.lesson.idAula = vm.lesson.idAula._id;
    // }

    ////////////////

    vm.changeTeaching = changeTeaching
    // vm.changeClassRoom = changeClassRoom;
    vm.cancelClick = cancelClick
    vm.deleteClick = deleteClick
    vm.okClick = okClick

    ////////////////

    // BUTTONS HANDLERS START

    function okClick() {
      vm.lesson.start = updateEventDateTime(vm.start, vm.startTime)
      if (vm.lesson.end !== null) {
        vm.lesson.end = updateEventDateTime(vm.start, vm.endTime)
      }

      vm.lesson.inizio = moment(vm.lesson.start).format("HH:mm")
      vm.lesson.fine = moment(vm.lesson.end).format("HH:mm")

      vm.lesson.data = moment(vm.lesson.start).format("DD/MM/YYYY")
      vm.lesson.ore =
        moment(vm.lesson.end).diff(moment(vm.lesson.start), "minutes") / 60

      // var teaching = _.find(teachings, {'_id': vm.lesson.idInsegnamento._id});

      var message = checkOverlap(vm.lesson)

      if (message) return showConstraintsMessage(message)

      saveLesson()
    }

    function cancelClick() {
      $mdDialog.cancel()
    }

    function deleteClick() {
      if (confirm("Vuoi eliminare la lezione? Questa azione è irreversibile!"))
        $lesson.remove(vm.lesson, true).then(function(res) {
          if (res.status.toString()[0] == 2) {
            vm.lesson.deleted = true

            util.toast(
              "Lezione Cancellata... ore da ultimare: " +
                res.data.oreDaUltimare,
              "success"
            )

            $mdDialog.hide(vm.lesson)
          } else {
            $log.error(res)

            util.toast(util.findMessage(res), "error")

            util.errorMessage = ""
          }
        })
    }

    function saveLesson() {
      // update event
      console.log("lesson =>", vm.lesson)
      $lesson[edit ? "save" : "create"](vm.lesson).then(
        function(res) {
          if (res.status.toString()[0] == 2) {
            util.toast(
              "Salvataggio completato... Ore da ultimare: " +
                res.data[1].oreDaUltimare,
              "success"
            )

            vm.lesson.saved = true

            $mdDialog.hide(vm.lesson)
          }
        },
        err => {
          util.toast(util.findMessage(err), "error")

          util.errorMessage = ""
        }
      )
    }

    // BUTTONS HANDLERS END

    // LESSONS HANDLERS START

    // function teachingFilter(teachings) { // TODO: do this in the server side

    //     vm.teachings = teachings.filter(function (teaching) {
    //         // return angular.isDefined(teaching.idDocente);stato: 'Attivo'

    //         if (teaching.oreDaUltimare <= 0) $log.log('non filtrati');

    //         return true;
    //     });

    // }

    function showConstraintsMessage(message) {
      // TODO: use vm = this and controllerAs
      $mdToast
        .show({
          controller: function($scope) {
            $scope.message = message
            $scope.image = "assets/images/countdown-10.gif"
            $scope.image =
              $scope.image.replace(/\?.*$/, "") + "?x=" + Date.now()
            $log.debug($scope.image)
            $scope.continue = function() {
              $mdDialog.hide(vm.lesson)
            }
            $scope.close = function() {
              $mdToast.hide()
            }
          },
          hideDelay: 10 * 1000,
          position: "bottom right",
          templateUrl:
            "app/ssml/secretary/calendar/constraints-template.tmpl.html"
        })
        .then(function(response) {
          if (response == "ok") {
            saveLesson()
          }
        })
    }

    function changeTeaching() {
      var id = vm.lesson.idInsegnamento._id

      if (id) {
        var teaching = _.find(vm.teachings, {
          _id: id
        })

        // var classRoom = _.find(classRooms, { '_id': vm.lesson.idAula || 'force-null' });

        if (teaching) {
          switch (teaching.annoCorso) {
            case 1:
              vm.lesson.className = "lesson-first-year"
              vm.lesson.color = vm.firstYearColor
              vm.lesson.textColor = "white"
              break
            case 2:
              vm.lesson.className = "lesson-second-year"
              vm.lesson.color = vm.secondYearColor
              vm.lesson.textColor = "white"
              break
            case 3:
              vm.lesson.className = "lesson-third-year"
              vm.lesson.color = vm.thirdYearColor
              vm.lesson.textColor = "white"
              break
          }

          // vm.lesson.docente = teaching.nomeDocente;

          // (classRoom) ?
          //     vm.lesson.title = teaching.nome + ' \n ' + vm.lesson.docente + ' - ' + classRoom.nome :
          //     vm.lesson.title = teaching.nome + ' \n ' + vm.lesson.docente + ' - Seleziona Aula';
        }

        var message = checkOverlap(vm.lesson)

        if (message) showConstraintsMessage(message)
      }
    }

    // TODO: remove disabled code because title now refresh every time
    // function changeClassRoom() {
    //     var id = vm.lesson.idAula;

    //     if (id) {

    //         var teaching;

    //         if (vm.lesson.idInsegnamento && vm.lesson.idInsegnamento._id)
    //             teaching = _.find(vm.teachings, { '_id': vm.lesson.idInsegnamento._id });

    //         var classRoom = _.find(classRooms, { '_id': id });

    //         if (classRoom) {

    //             (teaching) ?
    //                 vm.lesson.title = teaching.nome + ' \n ' + teaching.nomeDocente + ' - ' + classRoom.nome :
    //                 vm.lesson.title = 'Seleziona Insegnamento - ' + classRoom.nome;

    //         }

    //     }
    // }

    // BUTTONS HANDLERS END

    // DATE HANDLERS START

    // function allDayChanged() {
    //     // if all day turned on and lesson already saved we need to create a new date
    //     if (vm.lesson.allDay === false && vm.lesson.end === null) {
    //         vm.lesson.end = moment(vm.lesson.start);
    //         vm.lesson.end.endOf('day');
    //         vm.end = vm.lesson.end.toDate();
    //         vm.endTime = convertMomentToTime(vm.lesson.end);
    //     }
    // }

    function convertMomentToTime(moment) {
      return {
        hour: moment.hour(),
        minute: moment.minute()
      }
    }

    function updateEventDateTime(date, time) {
      var newDate = moment(date)
      newDate.hour(time.hour)
      newDate.minute(time.minute)
      return newDate
    }

    function createDateSelectOptions() {
      // create options for time select boxes (this will be removed in favor of mdDatetime picker when it becomes available)
      vm.dateSelectOptions = {
        hours: [],
        minutes: []
      }
      // hours
      for (var hour = 0; hour <= 23; hour++) {
        vm.dateSelectOptions.hours.push(hour)
      }
      // minutes
      for (var minute = 0; minute <= 59; minute++) {
        vm.dateSelectOptions.minutes.push(minute)
      }
    }

    // DATE HANDLERS END

    // init
    createDateSelectOptions()

    $teaching
      .query(true, {
        "oreDaUltimare.$gt": 0,
        stato: "Attivo"
      })
      .then(function(res) {
        // teachingFilter(res.data);
      })
  }
})()

// function checkOverlap(teachingId, teacherId) {
//
//     var lessonsToCheck = lessons;
//
//     if (vm.lesson._id) lessonsToCheck = _.remove(Object.create(lessons), function (lesson) {
//         return lesson._id !== vm.lesson._id;
//     });
//
//     var message;
//     _.each(lessonsToCheck, function (lesson) {
//
//         if (lesson.idInsegnamento && lesson.idInsegnamento.idDocente == teacherId
//             && moment(lesson.end).isAfter(vm.lesson.start)
//             && moment(lesson.start).isBefore(vm.lesson.end)
//             && lesson._id !== vm.lesson.idInsegnamento._id) {
//
//             message = '<strong>' + vm.lesson.title + '</strong> ' +
//                 ' <span>ha lo stesso docente di: </span>\n' +
//                 '<strong>' + lesson.title + '</strong>';
//
//             return;
//         }
//
//         if (moment(lesson.end).isAfter(vm.lesson.start)
//             && moment(lesson.start).isBefore(vm.lesson.end)) {
//
//             _.each(constraints, function (constraint) {
//
//                 if (constraint.idInsegnamento && constraint.idInsegnamento._id === teachingId) {
//
//                     _.each(constraint.constraints, function (c) {
//
//                         if (lesson.idInsegnamento._id === c._id) {
//
//                             message = '<strong>' + vm.lesson.title + '</strong> ' +
//                                 '<span>non può svolgersi in contemporanea con: </span>\n' +
//                                 '<strong>' + lesson.title + '</strong>';
//
//                             return;
//
//                             // this.trigger('overlapped', lesson, this.model.toJSON());
//                         }
//                     });
//                 }
//             });
//         }
//
//     });
//
//     return message;
// }
