/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 11/10/16 16:34.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: teachings.controller
 * Created with: WebStorm
 */

// (function () {
//     'use strict';

//     angular.module('secretary.teaching')
//         .controller('TeachingsController', TeachingsController);

  export default  function TeachingsController($scope, $log, $mdDialog, $mdToast, $teaching, $timeout, $address, $teacher, $state, $language, store, util) {
        console.log('hello')

        var vm = this
        vm.query = {
            stato: 'Attivo'
        }

        vm.state = store.get('teaching-grid-state')

        vm.saveState = saveState
        vm.restoreState = resetState

        var exec = true // force promise to resolve internally to factory

        var teachings = $teaching.collection
        var teaching = $teaching.model
        //     addresses = $address.collection,
        //     teachers = $teacher.collection,
        //     languages = $language.collection,

        vm.gridOptions = {
            enableRowSelection: true,
            enableGridMenu: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: true,
            enableSorting: true,
            showGridFooter: true,
            multiSelect: false,
            minRowsToShow: 17,
            rowTemplate: '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>',
            data: teachings
        }

        vm.gridOptions.columnDefs = [
            { name: 'nome', width: '35%' },
            { name: 'annoCorso', width: 30 },
            { name: 'cfu', width: 50 },
            { name: 'nomeDocente', displayName: 'Docente', width: 210 },
            { name: 'programma.stato' },
            { name: 'aa', displayName: 'Anno Accademico', width: 90 },
            { name: 'periodo', width: 150 },
            { name: 'oreDaUltimare', width: 90 },
            { name: 'ssd', displayName: 'ssd', width: 90 },
            {
                name: 'codice',
                width: 150
                // cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                //     if (row.entity.saved == true) {
                //         return 'edited';
                //     }
                //     return '';
                // }
            }
        ]

        /**
         *
         *
         * @param {any} gridApi
         */
        vm.gridOptions.onRegisterApi = onRegisterApi

        /////////////////////

        vm.add = add
        vm.statusQueryChanged = statusQueryChanged
        vm.aaQueryChanged = aaQueryChanged

        /////////////////////

        $teaching.query(exec, vm.query)

        function onRegisterApi(gridApi) {
            //set gridApi on scope
            vm.gridApi = gridApi

            gridApi.core.on.renderingComplete($scope, function () {
                $timeout(restoreState, 0)
            })

            gridApi.selection.on.rowSelectionChanged(null, function (row, event) {

                editTeaching(row.entity)

            })
        }

        function editTeaching(teaching) {

            $log.log({ _id: teaching._id })

            $state.go('triangular.secretary-teaching', { _id: teaching._id })

            // console.log('log: teching => ', entity);

            // $mdDialog.show({
            //     controller: 'TeachingDialogController',
            //     controllerAs: 'vm',
            //     templateUrl: 'app/ssml/secretary/teaching/teaching-dialog.tmpl.html',
            //     targetEvent: event,
            //     focusOnOpen: false,
            //     locals: {
            //         dialogData: {
            //             title: (isEdit ? 'Modifica' : 'Inserisci') + ' Insegnamento',
            //             confirmButtonText: (isEdit ? 'Salva' : 'Crea')
            //         },
            //         languages: languages,
            //         teaching: _.clone(entity), // bind model to dialog
            //         addresses: addresses,
            //         teachers: teachers,
            //         edit: isEdit
            //     }
            // })
            //     .then(function (teaching) {

            //         if (angular.isDefined(teaching.deleted) && teaching.deleted === true) {
            //             _.remove(vm.gridOptions.data, teaching);
            //         }

            //         if (angular.isDefined(teaching.saved) && teaching.saved === true) {

            //             var index = _.findIndex(vm.gridOptions.data, entity);
            //             _.remove(vm.gridOptions.data, entity);
            //             index ? vm.gridOptions.data.splice(index, 0, teaching) : vm.gridOptions.data.unshift(teaching);

            //         }

            //     });
        }

        function saveState() {
            vm.state = vm.gridApi.saveState.save()
            store.set('teaching-grid-state', vm.state)
            util.toast('Brava Rosaria! Hai salvato la configurazione della tabella!')
        }

        function restoreState() {
            if (!vm.state) return util.toast('Nessuna configurazione salvata! Creane una Rosaria!')
            vm.gridApi.saveState.restore(vm, vm.state)
            util.toast('Rosaria ti ho ripristinato la configurazione!')
        }

        function resetState() {
            store.remove('teaching-grid-state')
            vm.gridApi.saveState.restore(vm, {})
            $state.reload()
        }

        function add(event, $event) {
            editTeaching(teaching, $event)
        }

        function statusQueryChanged() {
            $teaching.query(true, vm.query)
                .then(function success(res) {
                    if (res.status === 404) angular.copy([], vm.gridOptions.data)
                })
        }

        function aaQueryChanged() {
            $teaching.query(true, vm.query)
                .then(function success(res) {
                    if (res.status === 404) angular.copy([], vm.gridOptions.data)
                })
        }

        $scope.$on('addTeaching', add)

    }

// })();
