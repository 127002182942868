(function() {
  "use strict"

  angular.module("teacher.material").config(moduleConfig)

  function moduleConfig($stateProvider) {
    $stateProvider.state("triangular.teacher-material", {
      url: "/teacher/material/:_id",
      views: {
        "@triangular": {
          templateUrl: "app/ssml/teacher/material/material.tmpl.html",
          controller: "TeacherMaterialController",
          controllerAs: "vm"
        },
        "belowContent@triangular": {
          templateUrl: "app/ssml/common/fabs/fab.tmpl.html",
          controller: "FabController",
          controllerAs: "vm"
        }
      },
      params: {
        _id: { value: null },
        codice: { value: null }
      },
      data: {
        layout: {
          contentClass: "layout-column"
        },
        permissions: {
          only: ["manageTeaching"],
          redirect: "authentication.login"
        }
      }
    })
  }
})()
