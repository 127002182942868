(function() {
    'use strict'

    angular
        .module('secretary.study-plan')
        .controller('StudyPlanFabController', StudyPlanFabController)

    /* @ngInject */
    function StudyPlanFabController($rootScope) {
        var vm = this
        vm.addStudyPlan = addStudyPlan

        ////////////////

        function addStudyPlan($event) {
            $rootScope.$broadcast('addStudyPlan', $event)
        }
    }
})()
