(function () {
    'use strict'

    angular
        .module('secretary.room')
        .controller('RoomDialogController', RoomDialogController)

    /* @ngInject */
    function RoomDialogController($mdDialog, $mdToast, $log, $window, dialogData, RoomService, util, room, edit) {

        var vm = this

        vm.room = room

        vm.error = ''
        vm.dialogData = dialogData
        vm.edit = edit

        vm.okClick = okClick
        vm.cancelClick = cancelClick
        vm.deleteClick = deleteClick

        ////////////////

        function okClick() {
            vm.error = ''
            var deferred = edit ? RoomService.update(vm.room) : RoomService.create(vm.room)

            deferred
                .then(function (room) {
                    util.toast(edit ? 'Aula aggiornata!' : 'Aula creata!')
                    room.saved = true
                    $mdDialog.hide(room)
                },function (err) {
                    $log.error(err)
                    vm.error = util.findMessage(err)
                    util.errorMessage = ''
                    util.toast('Operazione fallita!')
                })

        }

        function cancelClick() {
            $mdDialog.cancel()
        }

        function deleteClick() {
            vm.error = ''
            if (confirm('Sei sicuro? Questa azione è irreversibile!')) {
                // delete room
                RoomService.remove(vm.room._id)
                    .then(function () {

                        util.toast('Aula eliminata!')
                        vm.room.deleted = true
                        $mdDialog.hide(vm.room)

                    }, function (err) {
                        vm.error = util.findMessage(err)
                        util.errorMessage = ''
                        util.toast('Impossibile eliminare Aula')
                    })
            }
        }

    }
})()
