/**
 * Created by daton on 12/11/16.
 */

(function () {
    'use strict'

    angular.module('ssml.services')
        .factory('storage', StorageService)

    /** @ngInject */
    function StorageService() {

        return {

            getObj: function getObj(key) {
                var item = localStorage.getItem(key)
                return item && angular.fromJson(item)
            },

            setObj: function setObj(key, value) {
                localStorage.setItem(key, angular.toJson(value))
            }

        }
    }

})()
