(function() {
    'use strict'

    angular
        .module('secretary.constraints')
        .controller('SessionFabController', SessionFabController)

    /* @ngInject */
    function SessionFabController($rootScope) {
        var vm = this
        vm.addSession = addSession

        ////////////////

        function addSession($event) {
            $rootScope.$broadcast('addSession', $event)
        }
    }
})()