(function() {
  "use strict"

  angular
    .module("teacher")
    .controller("MaterialDialogController", MaterialDialogController)

  /* @ngInject */
  function MaterialDialogController(
    $mdDialog,
    $timeout,
    $mdToast,
    Upload,
    teaching
  ) {
    var vm = this
    vm.status = "idle" // idle | uploading | complete
    vm.upload = upload
    vm.material = {
      titolo: "titolo",
      descrizione: "descrizione"
    }

    vm.hide = hide
    vm.cancel = cancel

    var fileList
    ////////////////

    function upload($files, titolo, descrizione) {
      if ($files !== null && $files.length > 0) {
        fileList = $files

        var up = Upload.upload({
          url: "/api/teachings/material/" + teaching.codice,
          data: {
            file: $files[0],
            titolo,
            descrizione
          }
        })

        up
          .then(function(res) {
            uploadComplete(res.data.materiale)
          })
          .catch(err => {
            console.error(err)
            uploadReset()
            $mdToast.show({
              template:
                "<md-toast><span flex>Errore caricamento file!</span></md-toast>",
              position: "bottom right",
              hideDelay: 5000
            })
          })

        vm.status = "uploading"
      }
    }

    function uploadComplete(materials) {
      vm.status = "complete"
      var message = "Thanks for "
      for (var file in fileList) {
        message += fileList[file].name + " "
      }

      $mdToast.show({
        template: "<md-toast><span flex>" + message + "</span></md-toast>",
        position: "bottom right",
        hideDelay: 5000
      })

      $timeout(uploadReset, 1000)
      $timeout(function() {
        $mdDialog.hide(materials)
      }, 1000)
    }

    function uploadReset() {
      vm.status = "idle"
    }

    function hide() {
      $mdDialog.hide()
    }

    function cancel() {
      $mdDialog.cancel()
    }
  }
})()
