/**
 * Project: ssml-platform
 * Created by Tony D'Angelo aka daton
 * on 11/10/16 16:10.
 * E-mail: tony@pack.ly
 * Web: http://www.jointloop.ovh
 *
 * File: teachings.module
 * Created with: WebStorm
 */

(function () {
    'use strict'

    angular.module('secretary.teaching', [
        'angular-storage'
    ])

})()
