(function() {
    'use strict'

    angular
        .module('secretary.study-plan')
        .factory('$studyPlan', $studyPlan)

    $studyPlan.inject = ['$http']

    function $studyPlan($http) {

        var url = '/api/piano-di-studi'

        var service = {
            query: query,
            show: show,
            showByUser: showByUser,
            create: create,
            update: update,
            destroy: destroy
        }

        return service

        ////////////////
        function query(params) {
            return $http({
                url: url,
                method: 'GET',
                params: params
            })
        }

        function show(id) {
            return $http({
                url: url + '/' + id,
                method: 'GET'
            })
        }

        function showByUser(id) {
            return $http({
                url: url + '/student/' + id,
                method: 'GET'
            })
        }

        function create(model) {
            return $http({
                url: url,
                method: 'POST',
                data: model
            })
        }

        function update(model) {
            return $http({
                url: url + '/' + model._id,
                method: 'PUT',
                data: model
            })
        }

        function destroy(model) {
            return $http({
                url: url + '/' + model._id,
                method: 'DELETE'
            })
        }
    }
})()