(function () {
    'use strict'

    angular
        .module('ssml.services')
        .factory('ErrorService', ErrorService)

    /* @ngInject */
    function ErrorService($http) {

        var factory = {
            save: save
        }

        ////////////////

        function save(data) {
            return $http({
                method: 'POST',
                url: '/api/abuses',
                data: data
            })
        }

        return factory

    }

})()
