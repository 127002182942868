(function() {
    'use strict'

    angular
        .module('secretary.address')
        .controller('AddressDialogController', AddressDialogController)

    /* @ngInject */
    function AddressDialogController($mdDialog, dialogData, address, edit) {

        var vm = this
        vm.cancelClick = cancelClick
        vm.deleteClick = deleteClick
        vm.dialogData = dialogData
        vm.edit = edit
        vm.address = address
        vm.okClick = okClick

        vm.address.stato = (vm.address.stato == 'Attivo') || false
        ////////////////

        function okClick() {

            vm.address.edit = edit
            $mdDialog.hide(vm.address)

        }

        function cancelClick() {
            $mdDialog.cancel()
        }

        function deleteClick() {
            vm.address.deleteMe = true
            $mdDialog.hide(vm.address)
        }

    }
})()
