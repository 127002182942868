(function () {
    'use strict'

    angular
        .module('teacher.teaching')
        .controller('TeacherTeachingsController', TeacherController)

    /* @ngInject */
    function TeacherController($teaching, $user, $log, $state) {
        var vm = this

        vm.user = $user.current

        // $user.getCurrent();

        vm.gridOptions = {
            enableRowSelection: true,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: true,
            showGridFooter: true,
            multiSelect: false,
            minRowsToShow: 17
        }

        vm.gridOptions.columnDefs = [
            { name: 'nome', width: '35%' },
            { name: 'codice', width: 210, sort: { direction: 'desc', priority: 0 } },
            { name: 'programma.stato', width: '*' },
            { name: 'ssd', width: 120 },
            { name: 'cfu', width: 90 },
            { name: 'aa', width: 90 },
            { name: 'oreLezione', width: 90 },
            { name: 'aa' }
        ]

        vm.gridOptions.onRegisterApi = function (gridApi) {
            //set gridApi on scope
            vm.gridApi = gridApi
            gridApi.selection.on.rowSelectionChanged(null, function (row) {
                $state.go('triangular.teacher-teaching', row.entity)
            })
        }

        $teaching.query(true, {
            idDocente: vm.user._id,
            stato: 'Attivo'
        })
            .then(function (response) {

                var status = response.status.toString()[0]

                if (status == 2) {

                    vm.gridOptions.data = response.data

                    $log.log(response.data)

                }

                if (status == 4 || status == 5) {

                    $log.error('xhr =>', response)
                    if (response.status === 401) $state.go('authentication.login')

                }

            })

    }
})()
