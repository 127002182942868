import _ from "lodash"

/**
 * Created by daton on 12/11/16.
 */
;(function() {
  "use strict"

  angular.module("ssml.services").service("util", UtilService)

  function UtilService($mdToast, $log) {
    var $ = this

    function findMessage(object, key) {
      key = key || "message"

      _.keys(object).filter(function(k) {
        if ("object" === typeof object[k]) findMessage(object[k])

        if (k == key) $.errorMessage += "<p>" + object[k] + "</p>"
      })

      return $.errorMessage
    }

    function serialize(obj, prefix) {
      var str = [],
        p
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p]
          str.push(
            v !== null && typeof v === "object"
              ? serialize(v, k)
              : encodeURIComponent(k) + "=" + encodeURIComponent(v)
          )
        }
      }
      return str.join("&")
    }

    function toast(message, type) {
      switch (type) {
        case "info":
          $mdToast.show({
            template: "<md-toast><span flex>" + message + "</span></md-toast>",
            position: "bottom right",
            hideDelay: 5000
          })
          break

        case "warn":
          break
        case "success":
          var toast = $mdToast
            .simple()
            .textContent(message)
            .action("OK")
            .highlightAction(true)
            .highlightClass("md-accent") // Accent is used by default, this just demonstrates the usage.
            .hideDelay(30000)
            .position("bottom right")

          $mdToast.show(toast)
          break
        case "error":
          $mdToast.show(
            $mdToast
              .simple()
              .textContent(message)
              .action("OK")
              .highlightAction(true)
              .highlightClass("md-accent") // Accent is used by default, this just demonstrates the usage.
              .position("bottom right")
          )
          // .then(function(response) {
          //     if (response == 'ok') {
          //         alert('You clicked the \'UNDO\' action.');
          //     }
          // });
          break

        default:
          $mdToast.show({
            template: "<md-toast><span flex>" + message + "</span></md-toast>",
            position: "bottom right",
            hideDelay: 5000
          })
          break
      }
    }

    this.toast = toast
    this.errorMessage = ""
    this.findMessage = findMessage
    this.serialize = serialize
  }
})()
