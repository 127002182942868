import _ from "lodash"
;(function() {
  "use strict"

  angular
    .module("secretary.study-plan")
    .controller("StudyPlansController", StudyPlansController)

  /* @ngInject */
  function StudyPlansController(
    $log,
    $state,
    $teaching,
    $user,
    $scope,
    $studyPlan,
    $mdDialog,
    util
  ) {
    var vm = this

    $studyPlan.query().then(
      function(response) {
        vm.gridOptions.data = response.data
        $log.log("studyPlans =>", response.data)
      },
      function(xhr) {
        $log.error("xhr", xhr)
      }
    )

    var rowTmpl =
      '<div ng-class="{\'edited\': row.entity.saved == true }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>'

    vm.gridOptions = {
      enableRowSelection: true,
      enableGridMenu: true,
      selectionRowHeaderWidth: 35,
      rowHeight: 35,
      minRowsToShow: 21,
      enableFiltering: true,
      showGridFooter: true,
      multiSelect: false,
      rowTemplate: rowTmpl,
      data: []
    }

    vm.gridOptions.columnDefs = [
      {
        name: "studentId.nomeCompleto",
        displayName: "Nome Studente"
      },
      {
        name: "studentId.annoCorso",
        displayName: "AnnoCorso"
      },
      {
        name: "studentId.secondaLingua.nome",
        displayName: "2 Lin."
      },
      {
        name: "studentId.terzaLingua.nome",
        displayName: "3 Lin."
      },
      {
        name: "studentId.annoCorso",
        displayName: "AnnoCorso"
      }
    ]

    vm.gridOptions.onRegisterApi = function(gridApi) {
      //set gridApi on scope
      vm.gridApi = gridApi
      gridApi.selection.on.rowSelectionChanged(null, function(row, event) {
        openDialog(event, row.entity, true)
      })
    }

    function openDialog(event, model, edit) {
      $mdDialog
        .show({
          controller: "StudyPlanDialogController",
          controllerAs: "vm",
          templateUrl:
            "app/ssml/secretary/study-plan/study-plan-dialog.tmpl.html",
          targetEvent: event,
          focusOnOpen: false,
          locals: {
            dialogData: {
              title: "Modifica",
              confirmButtonText: "Salva"
            },
            studyPlan: model,
            edit: edit
          }
        })
        .then(function(studyPlan) {
          if (angular.isDefined(studyPlan.saved) && studyPlan.saved === true) {
            var index = _.findIndex(vm.gridOptions.data, studyPlan)
            _.remove(vm.gridOptions.data, studyPlan)
            index
              ? vm.gridOptions.data.splice(index, 0, studyPlan)
              : vm.gridOptions.data.unshift(studyPlan)
            util.toast("Piano di studi salvato!")
          }

          if (
            angular.isDefined(studyPlan.deleted) &&
            studyPlan.deleted === true
          ) {
            _.remove(vm.gridOptions.data, studyPlan)
            util.toast("Piano di studi Eliminato")
          }
        })
    }

    function add($event) {
      openDialog($event, {}, false)
    }

    $scope.$on("addStudyPlan", add)
  }
})()
