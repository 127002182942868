(function () {
    'use strict'

    angular
        .module('student.study-plan')
        .controller('StudentStudyPlanController', StudentStudyPlanController)

    /* @ngInject */
    function StudentStudyPlanController($log, $state, $teaching, $user, $scope, $studyPlan, $mdDialog, util, uiGridConstants) {
        var vm = this
        vm.studyPlan = {}

        vm.gridOptions = {
            enableRowSelection: false,
            selectionRowHeaderWidth: 35,
            rowHeight: 35,
            enableFiltering: true,
            showGridFooter: true,
            multiSelect: false,
            minRowsToShow: 17,
            data: []
        }

        vm.gridOptions.columnDefs = [{
            name: 'nome', 
            displayName: 'Insegnamento'
        },
        {
            name: 'cfu',
            displayName: 'CFU',
            width: 90
        },
        {
            name: 'annoCorso',
            width: 150,
            sort: {
                direction: uiGridConstants.ASC,
                priority: 0
            }
        }
        ]

        vm.gridOptions.onRegisterApi = function (gridApi) {
            //set gridApi on scope
            vm.gridApi = gridApi
            gridApi.selection.on.rowSelectionChanged(null, function (row, event) {

                $log.log(row)

                $state.go('triangular.student-teaching', {
                    _id: row.entity._id,
                    codice: row.entity.codice,
                    cfu: row.entity.cfu
                })

            })
        }

        $studyPlan.showByUser($user.current._id)
            .then(function (response) {
                if (response.data) {
                    angular.copy(response.data, vm.studyPlan)
                    vm.gridOptions.data = response.data.teachings
                } else {
                    util.toast('Piano di studi non trovato!')
                }
            }, function (xhr) {
                $log.error('showPlan', xhr)
            })
    }
})()
